import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from "react-router-dom";
import Wrapper from "../../routes/Wrapper";

const BlogDetail = () => {
  const location = useLocation();
  const { post } = location?.state;
  // console.log("location is....", post);
  const blogPosts = [
    {
      id: 1,
      name: "pioneering",
      title:
        "Pioneering a New Era in Finance with Bitcoin-Backed Lines of Credit",
      content: `
      <p>Introducing BitLoc, the groundbreaking financial technology company that is redefining the way you interact with your Bitcoin. BitLoc is at the forefront of innovation, offering a revolutionary Bitcoin-backed line of credit that allows you to access immediate capital without parting ways with your valuable cryptocurrency. As one of the pioneers in this space, BitLoc is setting a new standard for financial freedom and flexibility.</p>
      <h1>Unlock the Power of Your Bitcoin:</h1>
      <p>BitLoc empowers you to leverage the potential of your Bitcoin holdings by offering a Bitcoin-backed line of credit. This means you can access funds without the need to sell your Bitcoin, providing you with the financial flexibility you need while keeping your crypto assets intact.</p>
      <h1>A Trailblazer in Bitcoin-Backed Lending</h1>
      <p>BitLoc stands out as one of the first of its kind, spearheading the concept of Bitcoin-backed lines of credit. This innovative approach allows users to tap into the value of their Bitcoin holdings without the traditional constraints of selling. BitLoc is breaking new ground in the financial space, offering a unique solution that bridges the gap between the cryptocurrency market and traditional lending.</p>
      <h1>Canadian-Based Innovation</h1>
      <p>Operating under the Federal Laws of Canada, BitLoc ensures the highest standards of consumer protection and data privacy. As a Canadian-based company, we are proud to bring this cutting-edge financial solution to users within a regulatory framework that prioritizes security and transparency.</p>
      <h1>Preserving Bitcoin Integrity</h1>
      <p>BitLoc understands the importance of preserving the integrity of your Bitcoin assets. Unlike traditional lenders, we do not rehypothecate user funds. Your Bitcoin remains securely held, serving as collateral for your line of credit. This commitment to transparency and security sets BitLoc apart as a trusted partner in the Bitcoin-backed lending space.</p>
      <h1>Almost the First of Its Kind</h1>
      <p>
      BitLoc proudly stands as one of the pioneering platforms offering Bitcoin-backed lines of credit. This innovative financial solution is reshaping the way individuals interact with their cryptocurrency assets. By almost being the first of its kind, BitLoc is leading the charge in providing users with a secure, transparent, and efficient way to access capital while holding onto their valuable Bitcoin.
      </p>
      <h1>
      Join the Financial Revolution
      </h1>
      <p>
      As BitLoc continues to pave the way for Bitcoin-backed lending, we invite you to join the financial revolution. Experience the power of your Bitcoin like never before – unlock a world of possibilities with BitLoc, where your cryptocurrency works for you, and your financial goals are within reach. Embrace the future of finance with BitLoc, the trailblazer in Bitcoin-backed lines of credit.
      </p>

      `,
      img: "/images/blog/blog1.png",
    },
    {
      id: 2,
      name: "bitcoin-halving",
      title:
        "Bitcoin Halving: A Catalyst for Financial Transformation in the Blockchain Era",
      content: "Lorem ipsum dolor sit amet consectetur.",
      img: "/images/blog/blog2.webp",
    },
    {
      id: 3,
      name: "shaping-the-future",
      title:
        "Shaping the Future: Cryptocurrency Lending and Our Pioneering Role in Enabling It",
      content: "Lorem ipsum dolor sit amet consectetur.",
      img: "/images/blog/blog3.jpeg",
    },
  ];
  return (
    <Wrapper>
      <div className="blog-detail-page flex min-h-[730px] flex-col">
        <div className="page-hdr flex items-center bg-[#1D2A1D]">
          <div className="wrap wrapWidth">
            <h1 className="title max-w-[65%]">{post?.title || ""}</h1>
          </div>
        </div>
        <div className="wrap wrapWidth flex flex-col">
          <div className="page-description">
            {ReactHtmlParser(post?.content)}
            {/* <h1 className=''>Lorem ipsum dolor sit amet consectetur</h1>
                        <p>Lorem ipsum dolor sit amet consectetur. Donec elementum integer turpis urna iaculis dolor interdum facilisi lacus. Massa viverra pulvinar id viverra platea proin mattis proin nunc. Dis tincidunt neque quis massa vulputate consectetur ornare volutpat. Purus neque eu habitant neque potenti sollicitudin. Velit feugiat nec tincidunt sit interdum sit. Rutrum id risus amet velit consectetur mi consectetur. Lorem ipsum dolor sit amet consectetur. Donec elementum integer turpis urna iaculis dolor interdum facilisi lacus. Massa viverra pulvinar id viverra platea proin mattis proin nunc. Dis tincidunt neque quis massa vulputate consectetur ornare volutpat. Purus neque eu habitant neque potenti sollicitudin. Velit feugiat nec tincidunt sit interdum sit. Rutrum id risus amet velit consectetur mi consectetur.</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Donec elementum integer turpis urna iaculis dolor interdum facilisi lacus. Massa viverra pulvinar id viverra platea proin mattis proin nunc. Dis tincidunt neque quis massa vulputate consectetur ornare volutpat. Purus neque eu habitant neque potenti sollicitudin. Velit feugiat nec tincidunt sit interdum sit. Rutrum id risus amet velit consectetur mi consectetur. Lorem ipsum dolor sit amet consectetur. Donec elementum integer turpis urna iaculis dolor interdum facilisi lacus. Massa viverra pulvinar id viverra platea proin mattis proin nunc. Dis tincidunt neque quis massa vulputate consectetur ornare volutpat. Purus neque eu habitant neque potenti sollicitudin. Velit feugiat nec tincidunt sit interdum sit. Rutrum id risus amet velit consectetur mi consectetur.</p> */}
          </div>
          <div className="related-articles-block flex flex-col">
            <h1 className="rab-title">Related Articles</h1>
            <div className="related-articles-wrap">
              {blogPosts.map((item, index) => (
                <Link
                  key={index}
                  to={`/blogs-detail/${item.name}`}
                  state={{ post: item }}
                >
                  <div
                    className={`blog-post-card relative`}
                    style={{ backgroundImage: `url(${item.img})` }}
                  >
                    <div className="card-overlay absolute p-5 h-full">
                      <h1 className="title text-white text-xl h-full flex items-end">
                        {item.title}
                      </h1>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default BlogDetail;
