import React, {useEffect, useState} from "react";
import AdminWrapper from "../../routes/AdminWrapper";
import {useSelector} from "react-redux";
import makeRequest from "../../helpers/axiosRequest";
import {apiUrl, customAlert} from "../../helpers/MyHelpers";
import QRCode from "qrcode";
const GoogleAuth2FaPage = () => {
    const {user} = useSelector(state => state.authReducer)
    const [secret, setSecret] = useState();
    const [qrCodeImage, setQrCodeImage] = useState();
    let authenticator = false;
    if(user?.auth2FaEnabled === true){
        authenticator = true
    }
    let enable2Fa = async () => {
        makeRequest('GET', `${apiUrl}users/get_2fa_secret/${user?._id}`)
            .then(response => {
                if (response?.data?.status == 'Success') {
                    setSecret(response.data.secret)
                } else if (response?.error) {
                    customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                } else {
                    customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                }
            })
    }
    let verifyCode = async (e) => {
        e.preventDefault()
        let data = new FormData(e.target);
        data.append('id',user?._id)
        await makeRequest('post', `${apiUrl}users/verify_2fa_code`, data)
            .then(response => {
                if (response?.data?.status == 'Success') {
                    window.location.reload();
                } else if (response?.error) {
                    customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                } else {
                    customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again"})
                }
            })
    }
    let disable2fa = async (e) => {
        e.preventDefault()
        let data = new FormData(e.target);
        data.append('id',user?._id)
        await makeRequest('post', `${apiUrl}users/disable_2fa`, data)
            .then(response => {
                if (response?.data?.status == 'Success') {
                    window.location.reload();
                } else if (response?.error) {
                    customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                } else {
                    customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again"})
                }
            })
    }
    useEffect(() => {
        if(secret){
            QRCode.toDataURL(secret.otpauth_url, (err,data)=>{
                setQrCodeImage(data)
            })
        }
    }, [secret]);
    let getButton = () => {
        if(authenticator) {
            return (
                <form onSubmit={disable2fa}>
                    <h2>2 Factor Authentication Enabled</h2>
                    <label className="block font-normal mb-2">
                        Enter Code to disable
                    </label>
                    <input name={'code'} placeholder={'Code'} className="border rounded w-full py-2 px-3 leading-tight" type={"number"}/>
                    <input value={"Disable"} className="button btn-rounded my-2" type={"submit"}/>
                </form>
            )
        } else {
            return (
                <button type={"button"} onClick={enable2Fa} className="button btn-rounded float-left">Enable</button>
            )
        }
    }
    let showQrCode = () => {
        if(qrCodeImage){
            return (
                <form onSubmit={verifyCode}>
                    <img src={qrCodeImage} alt="QR-Code"/>
                    <label className="block font-normal mb-2">
                        Enter Code to Verify
                    </label>
                    <input name={'code'} placeholder={'Code'} className="border rounded w-full py-2 px-3 leading-tight" type={"number"}/>
                    <input value={"Verify"} className="button btn-rounded my-2" type={"submit"}/>
                </form>
            )
        }
    }
    return (
        <AdminWrapper>
            <div className="dashboard-page flex bg-white">
                <div className="page-block flex flex-col w-full">
                    <div className={'flex justify-between py-2 align-middle m_flex_direction m_align_center'}>
                        <div className="page-title text-black">Two Factor Authentication</div>
                    </div>
                    <div className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-10">
                        <div className={'flex py-2'}>{getButton()}</div>
                        <div className={'flex-row'}>
                            {showQrCode()}
                        </div>
                    </div>
                </div>
            </div>
        </AdminWrapper>
    );
};

export default GoogleAuth2FaPage;
