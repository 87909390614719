import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { useNavigate, Link } from "react-router-dom";
import AdminSidebar from "../components/AdminSidebar";

const Wrapper = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
      <div className={`admin-dashboard-page flex flex-col`}>
        <div className="pages-block flex flex-col relative h-full">
          <Header />
          <Sidebar />
          <AdminSidebar />
          <section className="terms-pages flex flex-col marginLeft300">
            {children}
          </section>
          <Footer />
        </div>
      </div>
  );
};
export default Wrapper;
