import React, {useEffect, useState} from "react";
import AdminWrapper from "../../../routes/SuperAdminWrapper";
import {useLocation} from "react-router-dom";
import {apiUrl, customAlert, imagesUrl} from "../../../helpers/MyHelpers";
import makeRequest from "../../../helpers/axiosRequest";

const UserView = () => {
    const [activeTab, setActiveTab] = useState("Personal Information");
    const { state } = useLocation();
    const uploads_path = imagesUrl;
    let govt_images = [];//
    let id_conf = [];
    if(state?.government_issued_identification != undefined){
        govt_images = state.government_issued_identification.split(',');
    }
    if(state?.identity_files != undefined){
        id_conf = state.identity_files.split(',');
    }
    let viewFull = (src) => {
        window.open(src, '_blank')
    }
    const udpatePersonalInfo = async (e) => {
        e.preventDefault();
        let data = new FormData(e.target);
        try {
            const response = await makeRequest('post', `${apiUrl}admin/update_personal_info/${state._id}`, data)
                .then(response => {
                    if (response?.data?.status === 'Success') {
                        customAlert({
                            "icon": "success",
                            "text": "Updated"
                        })
                    } else if (response?.error) {
                        customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                    } else {
                        customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                    }
                })
        } catch (err) {
            customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
        }
    }
    let getActiveTabContent = () => {
        if(activeTab =='Personal Information') {
            return(
                <div className="mt-5">
                    <form className="p-info-admin" onSubmit={(e)=> {udpatePersonalInfo(e)}}>
                        <div className="mb-4">
                            <p className="font-normal block mb-2">First Name:
                                <input type="text" required name="first_name" className="ml-2 font-normal" defaultValue={state?.first_name} />
                            </p>
                        </div>
                        <hr className="mb-4"/>
                        <div className="mb-4">
                            <p className="font-normal block mb-2">Last Name:
                                <input type="text" required name="last_name" className="ml-2 font-normal" defaultValue={state?.last_name} />
                            </p>
                        </div>
                        <hr className="mb-4"/>
                        <div className="mb-4">
                            <p className="block font-normal mb-2">Date of Birth:
                                <input type="date" required name="dob" className="ml-2 font-normal" defaultValue={state?.dob} />
                            </p>
                        </div>
                        <hr className="mb-4"/>
                        <div className="mb-4">
                            <p className="font-normal block mb-2">Email:
                                <input type="email" required name="email" className="ml-2 font-normal" defaultValue={state?.email} />
                            </p>
                        </div>
                        <hr className="mb-4"/>
                        <div className="mb-4">
                            <p className="font-normal block mb-2">Phone Number:
                                <input type="text" required name="phone_number" className="ml-2 font-normal" defaultValue={state?.phone_number} />
                            </p>
                        </div>
                        <hr className="mb-4"/>
                        <div className="mb-4">
                            <p className="font-normal block mb-2">Occupation:
                                <input type="text" required name="occupation" className="ml-2 font-normal" defaultValue={state?.occupation} />
                            </p>
                        </div>
                        <hr className="mb-4"/>
                        <div className="mb-4">
                            <p className="font-normal block mb-2">Full Address:
                                <input type="text" required name="address_1" className="ml-2 font-normal" defaultValue={state?.address_1} />
                                <input type="text" required name="address_4" className="ml-2 font-normal" defaultValue={state?.address_4} />
                                <input type="text" required name="address_2" className="ml-2 font-normal" defaultValue={state?.address_2} />
                                <input type="text" required name="address_3" className="ml-2 font-normal" defaultValue={state?.address_3} />
                            </p>
                        </div>
                        <hr className="mb-4"/>
                        <div className="mb-4">
                            <p className="font-normal block mb-2">State:
                                <input type="text" required name="state" className="ml-2 font-normal" defaultValue={state?.state} />
                            </p>
                        </div>
                        <hr className="mb-4"/>
                        <div className="mb-4">
                            <p className="font-normal block mb-2">Country:
                                <input type="text" required name="country" className="ml-2 font-normal" defaultValue={state?.country} />
                            </p>
                        </div>
                        <hr className="mb-4"/>
                        <div className="mb-4">
                            <p className="font-normal block mb-2">Citizenship:
                                <input type="text" required name="citizenship" className="ml-2 font-normal" defaultValue={state?.citizenship} />
                            </p>
                        </div>
                        <hr className="mb-4"/>
                        <div className="mb-4">
                            <p className="font-normal block mb-2">Govt ID Type: <span className="ml-2 font-normal">{state?.govt_identity_type}</span></p>
                            <div className="">
                                {
                                    govt_images.map((item,key)=> {
                                        return (
                                            <img key={key} onClick={(e)=>{viewFull(e.target.src)}} className="govt-id-images" src={uploads_path+item} alt="gid_file"/>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="">
                            <button type="submit" className="button btn-rounded float-right mt-4">Save</button>
                        </div>
                    </form>
                </div>
            )
        } else if(activeTab =='Banking Information') {
            return (
                state?.banks.map((item,key)=> {
                    return(
                        <div className="mt-5" key={key}>
                            <div className="mb-4">
                                <p className="font-normal block mb-2">Account Name: <span
                                    className="font-normal">{item.account_name}</span></p>
                            </div>
                            <hr className="mb-4"/>
                            <div className="mb-4">
                                <p className="font-normal block mb-2">Account Number: <span
                                    className="font-normal">{item.account_number}</span></p>
                            </div>
                            <hr className="mb-4"/>
                            <div className="mb-4">
                                <p className="block font-normal mb-2">Branch Number: <span
                                    className="font-normal">{item.branch_number}</span></p>
                            </div>
                            <hr className="mb-4"/>
                            <div className="mb-4">
                                <p className="font-normal block mb-2">Main Account Currency: <span
                                    className="font-normal">{item.main_account_currency}</span></p>
                            </div>
                            <hr className="mb-4"/>
                            <div className="mb-4">
                                <p className="font-normal block mb-2">Bank Institution: <span
                                    className="font-normal">{item.bank_institution}</span></p>
                            </div>
                            <hr className="mb-4"/>
                            <div className="mb-4">
                                <p className="font-normal block mb-2">Branch Address: <span
                                    className="font-normal">{item.branch_address}</span></p>
                            </div>
                            <hr/>
                            <hr/>
                            <hr className="mb-4"/>
                        </div>
                    )
                })
            )
        } else if(activeTab =='Wallet Information') {
            return (
                <div className="mt-5">
                    {
                        state?.wallet_groups.map((item,key)=> {
                            return (
                                <div key={key}>
                                    <div className="mb-4">
                                        <p className="font-normal block mb-2">Group Name: <span className="font-normal">{item.group_name}</span></p>
                                    </div>
                                    <hr className="mb-4"/>
                                    {
                                        item.wallets.map((subItem,i)=> {
                                            return (
                                                <div key={i}>
                                                    <div className="mb-4">
                                                        <p className="font-normal block mb-2">Wallet Address: <span className="font-normal">{subItem.address}</span></p>
                                                    </div>
                                                    <hr className="mb-4"/>
                                                    <div className="mb-4">
                                                        <p className="font-normal block mb-2">Percentage: <span className="font-normal">{subItem.percentage}</span></p>
                                                    </div>
                                                    <hr className="mb-4"/>
                                                </div>
                                            )
                                        })
                                    }
                                    <hr/>
                                    <hr/>
                                    <hr className="mb-4"/>
                                </div>
                            )
                        })
                    }
                </div>
            )

        } else if(activeTab =='Identity Video') {
            return (
                <div className="mt-5">
                    <div className="mb-4">
                        <p className="font-normal block mb-2">Identity Video</p>
                    </div>
                    {
                        id_conf.map((item,key)=> {
                            return (
                                <a key={key} className="watch-btn float-left" target="_blank" href={uploads_path+item}>Watch</a>
                            )
                        })
                    }
                </div>
            )

        } else if(activeTab =='Loans Applied') {
            return (
                <div className="mt-5">
                    {
                        state.loans.map((item,key)=> {
                            return (
                                <>
                                    <div className="mb-4">
                                        <p className="font-normal block mb-2">Borrow Amount: <span className="font-normal">${Number(item?.borrow_amount).toLocaleString()}</span></p>
                                    </div>
                                    <hr className="mb-4"/>
                                    <div className="mb-4">
                                        <p className="font-normal block mb-2">Term Length: <span className="font-normal">{item?.term_length} Months</span></p>
                                    </div>
                                    <hr className="mb-4"/>
                                    <div className="mb-4">
                                        <p className="block font-normal mb-2">Loan Type: <span className="font-normal">{item?.loan_type}</span></p>
                                    </div>
                                    <hr className="mb-4"/>
                                    <div className="mb-4">
                                        <p className="font-normal block mb-2">Category: <span className="font-normal">{item?.category}</span></p>
                                    </div>
                                    <hr className="mb-4"/>
                                    <div className="mb-4">
                                        <p className="font-normal block mb-2">Use Of Funds: <span className="font-normal">{item?.use_of_funds}</span></p>
                                    </div>
                                    <hr className="mb-4"/>
                                    <div className="mb-4">
                                        <p className="font-normal block mb-2">Status: <span className="font-normal">{item.approved ? 'Approved' : "Pending Approval"}</span></p>
                                    </div>
                                    <hr/>
                                    <hr/>
                                    <hr className="mb-4"/>
                                </>
                            )
                        })
                    }
                </div>
            )
        }
    }
    const handleApprove = async (value) => {
        try {
            let data = new FormData();
            data.append('id', value)
            data.append('first_name', state?.first_name)
            data.append('email', state?.email)
            const response = await makeRequest('post', `${apiUrl}admin/approve_user/${value}`, data)
                .then(response => {
                    if (response?.data?.status === 'Success') {
                        customAlert({
                            "icon": "success",
                            "title": "Success",
                            "text": "User Approved"
                        })
                    } else if (response?.error) {
                        customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                    } else {
                        customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                    }
                })
        } catch (err) {
            customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
        }
    }
    return (
        <AdminWrapper>
            <div className="dashboard-page flex bg-white">
                <div className="page-block flex flex-col w-full">
                    <div className="page-title text-black">User Details</div>
                    <div
                        className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-5">
                        <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 justify-left">
                            <li className="m-1">
                                <button onClick={(e)=>{setActiveTab(e.target.innerHTML)}} aria-current="page"
                                        className={"inline-block p-4 rounded-t-lg tab_color"+ (activeTab=='Personal Information' ? ' tab_active_color' : '')}>Personal Information</button>
                            </li>
                            <li className="m-1">
                                <button onClick={(e)=>{setActiveTab(e.target.innerHTML)}}
                                        className={"inline-block p-4 rounded-t-lg tab_color"+ (activeTab=='Banking Information' ? ' tab_active_color' : '')}>Banking Information</button>
                            </li>
                            <li className="m-1">
                                <button onClick={(e)=>{setActiveTab(e.target.innerHTML)}}
                                        className={"inline-block p-4 rounded-t-lg tab_color"+ (activeTab=='Wallet Information' ? ' tab_active_color' : '')}>Wallet Information</button>
                            </li>
                            <li className="m-1">
                                <button onClick={(e)=>{setActiveTab(e.target.innerHTML)}}
                                        className={"inline-block p-4 rounded-t-lg tab_color"+ (activeTab=='Identity Video' ? ' tab_active_color' : '')}>Identity Video</button>
                            </li>
                            <li className="m-1">
                                <button onClick={(e)=>{setActiveTab(e.target.innerHTML)}}
                                        className={"inline-block p-4 rounded-t-lg tab_color"+ (activeTab=='Loans Applied' ? ' tab_active_color' : '')}>Loans Applied</button>
                            </li>
                        </ul>
                        {
                            state.approved ? null : <button onClick={(e)=>{handleApprove(e.target.value)}} type="button" value={state._id} style={{marginTop:"-50px"}} className="button btn-rounded float-right ml-2">Approve</button>
                        }
                        <div>{getActiveTabContent()}</div>
                    </div>
                </div>
            </div>
        </AdminWrapper>
    );
};

export default UserView;
