import React from "react";
import Wrapper from "../../routes/Wrapper";

const Security = () => {
  return (
    <Wrapper>
      <div className="security-page bg-white h-screen flex flex-col">
        <div className="page-hero-sec flex ">
          <div className="wrap wrapWidth flex flex-col">
            <h1 className="sec-title">Security</h1>
            <p className="sec-desc">
              In the world of crypto asset lending, not all providers are equal.
              At BitLoc, we prioritize security and protection above all else.
              We handle your private data and assets with the utmost care they
              deserve.
            </p>
          </div>
        </div>
        <div className="our-rates-section flex w-full">
          <div className="wrap wrapWidth flex flex-col">
            <h1 className="sec-title my-4">
              At BitLoc, we prioritize the privacy and security of your
              information and assets.
            </h1>

            <div className="points-block flex flex-col">
              <div className="point-item flex items-center justify-between">
                <div className="left-side flex items-left">
                  <h1 className="title">Proof of Reserves:</h1>
                </div>
                <div className="right-side flex items-center justify-end">
                  <p className="desc">
                    BitLoc is committed to transparency and verifiability. We
                    provide a clear and open view of our operations, and we are
                    proud to be the first digital asset lending company in the
                    crypto industry to complete a Proof-of-Reserves. This means
                    that each client can use a unique hashed ID to confirm that
                    their balance is accounted for, ensuring transparency and
                    trust.
                  </p>
                </div>
              </div>
              <div className="point-item flex items-center justify-between">
                <div className="left-side flex items-center">
                  <h1 className="title">Node Runner:</h1>
                </div>
                <div className="right-side flex items-center justify-end">
                  <p className="desc">
                    Bitloc operates a dedicated Bitcoin Node to ensure the
                    highest standards of privacy and security for its customers.
                    Running a Bitcoin Node offers several crucial advantages,
                    including self-reliance, privacy, security, and network
                    strengthening. By maintaining its own node, Bitloc can
                    independently verify transactions, avoid sharing IP
                    addresses, and contribute to the resilience of the Bitcoin
                    network. With Bitloc’s dedicated node, customers can trust
                    in a secure and dependable platform that safeguards their
                    interests against external interference, fostering a greater
                    sense of privacy, security, and control in their bitcoin
                    transactions.
                  </p>
                </div>
              </div>
              <div className="point-item flex items-center justify-between">
                <div className="left-side flex items-center">
                  <h1 className="title">Independently Owned:</h1>
                </div>
                <div className="right-side flex items-center justify-end">
                  <p className="desc">
                    Bitloc takes pride in being independently owned. Bitloc’s
                    independence means we're not influenced by external
                    organizations. This autonomy prioritizes our customers'
                    interests, promotes transparency, and allows us to be
                    flexible and responsive to their needs. It builds trust,
                    ensuring we focus solely on our customers' security and
                    financial well-being, without external pressures or
                    conflicts.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bitcoin-lock-section">
          <div className="bitcoin-blk wrapWidth flex">
            <div className="right flex">
              <div className="points-block flex flex-col">
                <div className="point-item flex items-center justify-between">
                  <div className="left-side flex items-center">
                    <h1 className="title">Verifiability is Key:</h1>
                  </div>
                  <div className="right-side flex items-center justify-end">
                    <p className="desc text-end">
                      Bitloc employs a robust security protocol to protect both
                      our customers and our platform from fraud. This includes
                      two-factor authentication (2FA) for account access, SMS
                      verification, and video confirmation of customer
                      identification. These measures ensure a multi-layered
                      defense system, enhancing the security of customer
                      accounts and transactions, and maintaining the integrity
                      of our services.
                    </p>
                  </div>
                </div>
                <div className="point-item flex items-center justify-between">
                  <div className="left-side flex items-center">
                    <h1 className="title">Handpicked Hardware Wallet:</h1>
                  </div>
                  <div className="right-side flex items-center justify-end">
                    <p className="desc text-end">
                      At Bitloc, security is paramount. We employ
                      industry-leading hardware wallets to safeguard our
                      customers' Bitcoin holdings. Our approach includes
                      air-gapped signing, ensuring that sensitive transactions
                      are kept offline and away from potential online threats.
                      Moreover, our private keys are generated using proper
                      entropy, enhancing their randomness and robustness.
                      Additionally, we utilize hardware with verifiable source
                      code, guaranteeing transparency and trustworthiness. Your
                      peace of mind and the security of your assets are our top
                      priorities.
                    </p>
                  </div>
                </div>
                <div className="point-item flex items-center justify-between">
                  <div className="left-side flex items-center">
                    <h1 className="title">Multisig:</h1>
                  </div>
                  <div className="right-side flex items-center justify-end">
                    <p className="desc text-end">
                      Bitloc prioritizes the security of customer funds by
                      implementing multisignature (multisig) wallets. This
                      technology requires multiple unique signatures to
                      authorize transactions, adding an extra layer of
                      protection against unauthorized access or breaches. With
                      multisig wallets, Bitloc ensures that customer assets
                      remain safeguarded and secure, instilling trust and
                      confidence in our financial services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Security;
