import React from "react";
import Wrapper2 from "../../routes/Wrapper2";
const RiskDisclosure = () => {
  return (
    <Wrapper2>
      <div className="b2x-terms-page flex bg-white">
        <div className="page-block flex flex-col">
          <div className="page-title text-black">
          Risk Disclosure
            </div>
          <div className="content-list flex flex-col">
            <h5 className="content-title">
            This Risk Disclosure Statement is important to understand. Please read it carefully.
            </h5>
<p className="content-desc">
This Risk Disclosure Statement aims to provide a broad overview and may not cover all
potential risks or considerations, particularly those specific to your circumstances, related to
setting up a BitLoc Account with BitLoc Inc., entering into a contractual agreement with BitLoc
for the utilization of its products and services. Due to the varied risks involved, it is
recommended to engage in such transactions only if you have a comprehensive
understanding of the contractual commitments with BitLoc and the risks associated with the
ownership, possession, and trading of Bitcoin.
</p>


What Constitutes Digital Assets?

<p className="content-desc">
Digital assets, exemplified by Bitcoin, serve as digital representations of value functioning as
a medium of exchange, unit of account, or store of value. However, Bitcoin does not hold
legal tender status in Canada. Bitcoin is occasionally traded for currencies but lack backing or
endorsement from any government or central bank. Their value is shaped by market
dynamics of supply and demand, and they typically exhibit higher volatility compared to fiat
currencies. The value of Bitcoin may hinge on the ongoing willingness of market participants
to swap fiat currency for them, potentially resulting in the complete and permanent loss of
value if the market for Bitcoin vanishes. Governments may impose restrictions on the use and
exchange of Bitcoin, and regulations are still evolving across most jurisdictions.
</p>

Brief Historical Overview

<p className="content-desc">
Being a relatively recent open-source technology, ongoing technical advancements in Bitcoin
are anticipated, potentially influencing it’s values. Given this brief history, the persistence of
economic value, governance structures, or functional aspects of Bitcoin remains uncertain.
Challenges such as inadequate software development, varying contribution rates, community
disagreements related to network development and scaling options, or unforeseen hurdles
that the community cannot navigate might negatively affect the Bitcoin&#39;s price. Developers in
the open-source blockchain technology sphere have expressed a commitment to enhancing
the scalability and security of Bitcoin. Unforeseen hardfork’s have occurred in Bitcoin’s history
causing chain splits and dividing community members into different projects. This all could
lead to unexpected changes to Bitcoin’s price and adoption later on in the future.
</p>


Volatility and Market Liquidity

<p className="content-desc">
The value of Bitcoin is highly volatile and unpredictable, posing a risk of significant loss within
a short time frame. It is crucial to thoroughly evaluate your financial situation to determine the
appropriateness of holding any Bitcoin. The Bitcoin market is particularly responsive to new
developments, and given the evolving nature of trading volumes, substantial shifts in market
sentiment, triggered by media sensationalism, celebrity statements, or other factors, may lead
to considerable volume swings and subsequent price fluctuations.
The price of Bitcoin on trading platforms has been susceptible to various factors, including
liquidity levels, public speculation on future value appreciation, fluctuations in investor
confidence, and the emergence of alternative Digital Assets gaining market share. In certain
scenarios, assessing the value of your Bitcoin might become challenging or even impossible.
The limited historical context of trading Digital Assets on public platforms has, in some
instances, resulted in increased volatility, influenced by factors unrelated to Digital Asset
values, such as liquidity levels and operational interruptions.
</p>

<p className="content-desc">
A change in the regulatory classification of a Digital Asset as a security or derivative could
also impact its liquidity and tradability. If the regulatory status or characterization of Bitcoin or
a Digital Asset available for trading undergoes a change, BitLoc will reevaluate the asset&#39;s
status and associated risks, providing updates accordingly.
</p>


Hardforks

<p className="content-desc">
Blockchain networks are typically powered by open-source software. When developers
release a modification to this software and a substantial majority of miners agree to the
change, the modification is implemented, and the blockchain network continues seamlessly.
However, if a modification is introduced without substantial majority consent or is incompatible
with the pre-modification software, it results in a &quot;Hard Fork&quot; (i.e., a split) of the blockchain.
This leads to two independent blockchains—one maintained by the pre-modification software
and the other by the post-modification software—operating in parallel. Past instances of such
hard forks exist, and they may occur in the future. You acknowledge that Hard Forks can
significantly impact the value, function, and name of the Digital Assets linked to your BitLoc
Account. In the event of a Hard Fork, BitLoc may temporarily suspend specific Platform
services (with or without notice) to determine, at its sole discretion, which Digital Asset to
support. Hard Forks are beyond BitLoc’s control, and its ability to deliver forked Digital Assets
from a forked network may rely on third parties. BitLoc is unlikely to support most forked
networks, and most forked Digital Assets may not be made available to you. BitLoc may
decide, at its sole discretion, not to support a forked network, and you have no claim or
privilege against BitLoc for unsupported forked Digital Assets. BitLoc may determine its
approach to such forked Digital Assets, including potentially not supporting them as part of its
services.
</p>


Technical Challenges

<p className="content-desc">
Historically, vulnerabilities in the source code of Digital Assets have been identified and
exploited, leading to issues such as the disabling of certain functionalities, exposure of users&#39;
personal information, and theft of Digital Assets. While the Bitcoin has exhibited a degree of
resilience and integrity over time, the underlying cryptography of Bitcoin could potentially be
flawed or rendered ineffective in the future. Advancements in mathematics and technology,
including progress in digital computing, algebraic geometry, and quantum computing, might
expose vulnerabilities in the cryptography of the Bitcoin network. Any decline in public
confidence regarding the security or source code in Bitcoin could have adverse effects on the
broader sector, consequently impacting the value of Bitcoin.
</p>


Regulatory Ambiguity

<p className="content-desc">
The regulatory landscape for Bitcoin is still undergoing continuous changes in various
jurisdictions, potentially leading to limitations of its use and influencing their demand.
Financial institutions, including banks, may opt not to facilitate fund processing for Bitcoin
transactions, execute wire transfers to or from BitLoc, engage with companies involved in
Bitcoin, or maintain accounts for individuals or entities involved in Bitcoin transactions.
Changes in legislation and regulations or governmental actions in diverse jurisdictions may
have adverse effects on the utilization, transfer, exchange, and value of Digital Assets. You acknowledge that Bitcoin represents a novel asset class, and the legal framework governing
their ownership, custody, transfer, and potential taxation is evolving and uncertain. Custody of
Bitcoin entails specific risks not present in more traditional asset classes. You further
recognize that you assume these risks, including the potential loss or decrease in the value of
Bitcoin due to changes or developments in the law or conditions under existing law, which
may inadequately protect your rights involving Bitcoin custody.
</p>


Risk of Ownership Concentration

<p className="content-desc">
Certain addresses within the Bitcoin network possess a considerable amount of the total
outstanding bitcoin. The potential exit of one of these addresses from their bitcoin holdings
could introduce volatility that might negatively impact the price.
When evaluating Bitcoin, BitLoc examines the concentration of ownership in that specific
asset. Various ways exist in which Bitcoin might face adverse effects due to concentration of
assets or power, including but not limited to: (i) the control of a significant portion of a
particular asset by a participant or node operator, (ii) the success of a 51% attack, where a
mining entity gains enough hash power or to control mined blocks, leading to a significant
erosion of trust in Bitcoin’s network, or (iii) if applicable, key actors making certain changes
against the broader community&#39;s will that could adversely impact Bitcoin.
</p>


Reliance on the Internet

<p className="content-desc">
BitLoc&#39;s internet-based Platform entails certain risks, including, but not limited to, potential
hardware and software failures. To minimize losses, BitLoc maintains an independent and
secure ledger of all transactions and implements contingency plans to mitigate the risk of
system failure. However, factors beyond BitLoc&#39;s control, such as signal power, reception,
internet routing, your equipment configuration, or the reliability of your internet connection,
may impact your ability to conduct transactions. A failure in any of these elements could result
in the inability to execute a transaction, deviations from your instructions, or the non-execution
of a transaction altogether. In specific market conditions, liquidating a position swiftly at a
reasonable price might become challenging or even impossible. This situation may arise, for
example, when the Bitcoin market experiences a sudden drop or if trading is halted due to
recent news events, unusual trading activity, or changes in the underlying Bitcoin protocol.
The higher the volatility, the increased likelihood of encountering challenges in transaction
execution. Apart from typical market risks, potential losses may occur due to system failures,
hardware issues, software malfunctions, disruptions in network connectivity, and data
corruption.
</p>


Cybersecurity Risk

<p className="content-desc">
The unique nature of Bitcoin introduces an elevated risk of fraud or cyber-attacks. A
cybersecurity breach, whether intentional or unintentional, may result in BitLoc Inc. losing
proprietary or privacy-protected information, experiencing data corruption, or facing
operational disruptions. Such incidents could lead to regulatory penalties, harm to reputation,
increased compliance costs related to corrective actions, and potential financial losses for
BitLoc Inc. Cybersecurity breaches encompass unauthorized access to BitLoc Inc.&#39;s digital
information systems, such as hacking or malicious software coding, as well as external
attacks like denial-of-service attempts aiming to render network services inaccessible. It&#39;s important to note that cybersecurity breaches affecting third-party service providers, like
liquidity providers working with BitLoc Inc., pose similar risks as direct breaches.
While BitLoc Inc. has implemented systems and processes to safeguard managed data,
prevent data loss, and address known and potential risks, and continues to invest significant
resources in enhancing these protections, there is no guarantee that these security measures
will offer absolute protection against breaches or attacks. BitLoc Inc. may encounter cyber-
attacks, extreme market conditions, or other operational or technical challenges. In such
instances, BitLoc Inc. is not and will not be held responsible or liable for any loss or damage
incurred by users due to cyber-attacks, operational issues, market disruptions, or technical
difficulties.
</p>


Depreciation of Collateral and Liquidation

<p className="content-desc">
In the event that the value of Bitcoin, which you have pledged as collateral for a BitLoc Inc.
Loan, decreases, it might be necessary for you to provide additional Bitcoin as collateral for
the loan. Furthermore, if a default event occurs on your Loan, BitLoc Inc. retains the right to
liquidate the collateral, either partially or entirely, by selling them in accordance with the terms
outlined in your Loan Agreement. It&#39;s important to note that you will remain liable for any
deficiency that exists after the net proceeds from these dispositions are applied against the
amounts you owe to BitLoc Inc.
</p>


Risk of Irreversible Transactions

<p className="content-desc">
Transactions involving Bitcoin may be irreversible, meaning that losses resulting from
fraudulent or accidental transactions may not be recoverable. It&#39;s important to note that
Bitcoin transactions are considered completed once recorded on a public ledger. However,
this recording date may not align with the date or time when you initially initiated the
transaction.
</p>

<p className="content-desc">
Accidental transfers or those resulting from theft, where Bitcoin is sent to the wrong recipient,
can only be rectified if the recipient agrees to send the Bitcoin back to the original sender in a
subsequent transaction. In the event of erroneous transfers, whether accidental or otherwise,
where Bitcoin is sent in incorrect amounts or to the wrong recipients, there is a risk that such
Bitcoin may become unrecoverable.
</p>


Risk of Losing &quot;Private Keys&quot;

<p className="content-desc">
The loss or destruction of specific private keys for BitLoc Inc. to access its Bitcoin may
impede BitLoc Inc.&#39;s ability to access its Bitcoin. Such loss of private keys could be
irreversible and might lead to a potential loss for you.
</p>


Transfer Delays

<p className="content-desc">
During peak times or periods of instability in the Bitcoin network, the swift movement of your
Bitcoin to your personal private keys may not be possible. When initiating a transaction
involving Bitcoin, delays may occur due to the congestion on the blockchain and/or technical
system delays within the network. While your BitLoc Inc. account may immediately reflect any
Digital Asset transaction you initiate, confirmation on the blockchain may take some time, and cancellation during this period is not possible. In rare cases, it could take 48-72 hours for a
failed transaction to be accurately displayed within the ledger. If you encounter unusual or
frustrating delays, please reach out to BitLoc Inc.&#39;s customer support for assistance.            </p>
          </div>
        </div>
      </div>
    </Wrapper2>
  );
};

export default RiskDisclosure;
