import {useState} from "react";
import {useUserRegisterMutation} from "../../store/services/authService";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import Wrapper from "../../routes/Wrapper";
import {customAlert, numberRegExp, passRegExp} from "../../helpers/MyHelpers";
import {setUser, setUserToken} from "../../store/reducers/authReducer";

const Registration = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cPass, setCPass] = useState('')
    const [state, setState] = useState({
        name: "",
        email: "",
        password: "",
        phone_number: "",
    })
    const handleInputs = (e) => {
        setState({...state, [e.target.name]: e.target.value});
        if (e.target.name == "email") {

        }
        if (e.target.name == "email") {

        }
    };
    const [register, response] = useUserRegisterMutation();

    const errors = response?.error?.data?.errors
        ? response?.error?.data?.errors
        : [];

    const userSignupFunction = async (e) => {
        e.preventDefault();
        if (state.password === cPass) {
            register(state).then((response) => {
                if (response?.data?.status === "Success") {
                    navigate("/auth/sms-verification", {state: {...response.data?.result?.user}})
                } else if (response?.error) {
                    customAlert({"icon": "error", "title": "Error", "text": response.error.data})
                } else {
                    customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                }
            });
        } else {
            customAlert({
                "icon": "warning",
                "title": "Passwords Don't Match",
                "text": "Please make sure to enter the same password in both fields to proceed."
            })
        }
    };
    return (
        <Wrapper>
            <div className="signup-page bg-white h-screen flex justify-center w-full">
                <div className="wrap wrapWidth flex flex-col">
                    <h3 className="page-title">Sign Up</h3>
                    <form
                        className="form-block flex flex-col"
                        onSubmit={userSignupFunction}>
                        <div className="input-field flex flex-col">
                            <h1 className="field-tag">Email *</h1>
                            <input required
                                   type="email"
                                   name="email"
                                   className="txt cleanbtn"
                                   onChange={handleInputs}
                                   value={state.email}
                                   placeholder="Enter email..."
                            />
                        </div>
                        <div className="input-field flex flex-col">
                            <h1 className="field-tag">Name *</h1>
                            <input required
                                   type="text"
                                   name="name"
                                   className="txt cleanbtn"
                                   onChange={handleInputs}
                                   value={state.name}
                                   placeholder="Enter name"
                            />
                        </div>
                        <div className="input-field flex flex-col">
                            <h1 className="field-tag">Password *</h1>
                            <input required pattern={passRegExp}
                                   title="Password must contain 1 uppercase, 1 lower case, special character and minimum length should be 8 character long"
                                   type="password"
                                   name="password"
                                   className="txt cleanbtn"
                                   onChange={handleInputs}
                                   value={state.password}
                                   placeholder="Enter password..."
                            />
                        </div>
                        <div className="input-field flex flex-col">
                            <h1 className="field-tag">Confirm Password *</h1>
                            <input required
                                   pattern={passRegExp}
                                   title="Password must contain 1 uppercase, 1 lower case, special character and minimum length should be 8 character long"
                                   type="password"
                                   name="cPassword"
                                   className="txt cleanbtn"
                                   onChange={(e) => {
                                       setCPass(e.target.value)
                                   }}
                                   value={cPass}
                                   placeholder="Enter confirm password..."
                            />
                        </div>
                        <div className="input-field flex flex-col">
                            <h1 className="field-tag">Phone Number *</h1>
                            <input required
                                   pattern="(\d{10})"
                                   title="Please enter a 10-digit number"
                                   type="text"
                                   name="phone_number"
                                   className="txt cleanbtn"
                                   onChange={handleInputs}
                                   value={state.phone_number}
                                   placeholder="Enter 10 Digits Phone Number"
                            />
                        </div>
                        <div
                            className="terms-field mb-10 flex items-center justify-start anim cursor-pointer">
                            <input required type="checkbox" className="h-4 w-4 mr-3"/>
                            <span className="thin-txt mr-2">I agree to </span>
                            <span className={' hover:text-themeColor'}>Terms and
                            Conditions</span>
                            <span className="thin-txt mx-2"> and </span> <span className={' hover:text-themeColor'}>Privacy Policy.</span>
                        </div>
                        <div className="actions flex items-center">
                            <input
                                type="submit"
                                value={response.isLoading ? "Loading..." : "Sign Up"}
                                className="btn-login button"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </Wrapper>
    );
};

export default Registration;
