import React, {useEffect, useRef, useState} from "react";
import AdminWrapper from "../../../routes/AdminWrapper";
import makeRequest from "../../../helpers/axiosRequest";
import {apiUrl, customAlert, imagesUrl} from "../../../helpers/MyHelpers";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../../../store/reducers/authReducer";
import {canStates, usaStates} from "../../../datasets/States";
import {Link} from "react-router-dom";
import {GoogleAuth2Fa} from "../../../components/GoogleAuth2Fa";
const PersonalInfo = name => {
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.authReducer)
    const [state, setState] = useState({});
    const [images, setImages] = useState([]);
    const [oldImages, setOldImages] = useState([]);
    const [countryStates, setCountryStates] = useState([]);
    useEffect(() => {
        setState({
            first_name: user?.first_name,
            last_name: user?.last_name,
            dob: user?.dob,
            email: user?.email,
            phone_number: user?.phone_number,
            occupation: user?.occupation,
            use_of_loan: user?.use_of_loan,
            address_1: user?.address_1,
            address_2: user?.address_2,
            address_3: user?.address_3,
            address_4: user?.address_4,
            state: user?.state,
            country: user?.country,
            citizenship: user?.citizenship,
            govt_identity_type: user?.govt_identity_type,
            government_issued_identification: user?.government_issued_identification,
        })
        if (state?.government_issued_identification) {
            let dbImages = state.government_issued_identification.split(',');
            let dbArrImg = [];
            for (let i = 0; i < dbImages.length; i++) {
                dbArrImg.push(dbImages[i])
            }
            setOldImages(dbArrImg);
        }
        if (state?.country === "USA") {
            setCountryStates(usaStates);
        } else {
            setCountryStates(canStates);
        }
    }, [countryStates])
    const personalFormSubmit = async (e) => {
        e.preventDefault()
        try {
            if (images.length > 0 || oldImages.length > 0) {
                const formData = new FormData(e.target);
                const response = await makeRequest('put', `${apiUrl}users/update_personal_info/${user?._id}`, formData, true)
                    .then(response => {
                        if (response?.data?.status === 'Success') {
                            customAlert({
                                "icon": "success", "title": "Success", "text": "Personal Info Has Been Updated"
                            })
                            dispatch(setUser(response?.data?.user));
                        } else if (response?.error) {
                            customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                        } else {
                            customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                        }
                    }).catch(error => {
                        customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                    })
            } else {
                customAlert({
                    "icon": "error",
                    "title": "Error",
                    "text": "Government Issued Identification Filed is Required"
                })
            }
        } catch (error) {
            customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
        }
    }
    const inputRef = useRef(null);
    const handleInputs = (e) => {
        // Check Country to Populate States
        if (e.target.name === "country") {
            if (e.target.value === "USA") {
                setCountryStates(usaStates);
            } else {
                setCountryStates(canStates);
            }
        }
        // Set State of Data on Input Fields
        setState({...state, [e.target.name]: e.target.value});
    };
    const handleClick = () => {
        // 👇️ open file input box on click of + Image
        inputRef.current.click();
    };
    const imageUpload = (e) => {
        let reader = new FileReader();
        let arrImg = [];
        for (let i = 0; i < e.target.files.length; i++) {
            arrImg.push(URL.createObjectURL(e.target.files[i]))
        }
        setImages(arrImg);
    }
    const removeOldImage = (e) => {
        let data = [...oldImages];
        let removed_img = data.splice(e, 1)
        setOldImages(data)
        let removed_img_tag = document.createElement("input");
        removed_img_tag.type = "hidden";
        removed_img_tag.name = "removed_images[]";
        removed_img_tag.value = removed_img[0];
        document.getElementById('removed_images').append(removed_img_tag)
    }
    const removeNewImages = (e) => {
        let attachments = document.getElementById("multi_file").files; // <-- reference your file input here
        let fileBuffer = new DataTransfer();
        for (let i = 0; i < attachments.length; i++) {
            // Exclude file in specified index
            if (e !== i) {
                fileBuffer.items.add(attachments[i]);
            }
        }
        let data = [...images];
        data.splice(e, 1)
        setImages(data);
        // Assign buffer to file input
        document.getElementById("multi_file").files = fileBuffer.files;
    }
    return (<AdminWrapper>
        <div className="dashboard-page flex bg-white">
            <div className="page-block flex flex-col w-full">
                <div className={'flex justify-between py-2 align-middle m_flex_direction m_align_center'}>
                    <div className="page-title text-black">Enter Your Information</div>
                    <div style={{width:"350px"}}>
                        <Link to={'/user/changepassword'} className="button btn-rounded float-left mr-2" type="button">
                            Change Password
                        </Link>
                        <GoogleAuth2Fa/>
                    </div>
                </div>
                <div className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-10">
                    <form className="" id={'personalInfoForm'} onSubmit={personalFormSubmit}>
                        <div>
                            <div className="mb-4">
                                <label className="block font-normal mb-2" htmlFor="first_name">
                                    First Name *
                                </label>
                                <input onChange={handleInputs} required={true}
                                       className="border rounded w-full py-2 px-3 leading-tight"
                                       value={state?.first_name} name="first_name" type="text"/>
                            </div>
                            <div className="mb-4">
                                <label className="block font-normal mb-2" htmlFor="last_name">
                                    Last Name *
                                </label>
                                <input onChange={handleInputs} required={true}
                                       className="border rounded w-full py-2 px-3 leading-tight"
                                       value={state?.last_name} name="last_name" type="input"/>
                            </div>
                            <div className="mb-4">
                                <label className="block font-normal mb-2" htmlFor="dob">
                                    Date Of Birth *
                                </label>
                                <input onChange={handleInputs} required={true} onClick={(e) => {
                                    e.target.showPicker()
                                }} className="border rounded w-full py-2 px-3 leading-tight"
                                       value={state?.dob} name="dob" type="date" placeholder="Date Of Birth"/>
                            </div>
                            <div className="mb-4">
                                <label className="block font-normal mb-2" htmlFor="email">
                                    Email *
                                </label>
                                <input disabled={true} onChange={handleInputs} required={true}
                                       className="border rounded w-full py-2 px-3 leading-tight"
                                       value={state?.email} name="email" type="email"/>
                            </div>
                            <div className="mb-4">
                                <label className="block font-normal mb-2" htmlFor="phone_number">
                                    Phone Number *
                                </label>
                                <input disabled={true} onChange={handleInputs} required={true}
                                       className="border rounded w-full py-2 px-3 leading-tight"
                                       value={state?.phone_number} name="phone_number" type="text"/>
                            </div>
                            <div className="mb-4">
                                <label className="block font-normal mb-2" htmlFor="occupation">
                                    Occupation *
                                </label>
                                <input onChange={handleInputs} required={true}
                                       className="border rounded w-full py-2 px-3 leading-tight"
                                       value={state?.occupation} name="occupation" type="text"/>
                            </div>
                            <div className="mb-4">
                                <div className="flex justify-between adress_flex">
                                    <label className="mr-3 py-2 pr-2 leading-tight block font-normal"
                                           htmlFor="address">
                                        Street *
                                        <input onChange={handleInputs} required={true}
                                               className="w-full mt-2 border rounded mr-2 py-2 px-2 leading-tight"
                                               value={state?.address_1} name="address_1" type="text"
                                               placeholder="Address"/>
                                    </label>
                                    <label className="py-2 px-2 leading-tight block font-normal"
                                           htmlFor="address">
                                        Apartment
                                        <input onChange={handleInputs}
                                               className="mt-2 border rounded  py-2 px-3 leading-tight"
                                               value={state?.address_4} name="address_4" type="text"
                                               placeholder="Apartment"/>
                                    </label>
                                    <label className="mx py-2 px-2 leading-tight block font-normal"
                                           htmlFor="address">
                                        City *
                                        <input onChange={handleInputs} required={true}
                                               className="mt-2 border rounded  py-2 px-3 leading-tight"
                                               value={state?.address_2} name="address_2" type="text"
                                               placeholder="City"/>
                                    </label>
                                    <label className="py-2 px-2 leading-tight block font-normal" htmlFor="address">
                                        Postal Code *
                                        <input onChange={handleInputs} required={true}
                                               className="mt-2 border rounded py-2 px-3 leading-tight"
                                               value={state?.address_3} name="address_3" type="text"
                                               placeholder="Postal Code/Zip Code"/>
                                    </label>
                                    <label className="py-2 px-2 leading-tight block font-normal" htmlFor="address">
                                        Province *
                                        <select onChange={handleInputs} value={state?.state}
                                                className="mt-2 block w-full bg-white border border-gray-200 py-2 px-4 rounded"
                                                name="state">
                                            {countryStates?.map((item, key) => {
                                                return (<option key={key} value={item}>{item}</option>)
                                            })}
                                        </select>
                                    </label>
                                    <label className=" py-2 px-2 leading-tight block font-normal" htmlFor="address">
                                        Country *
                                        <select onChange={handleInputs} value={state?.country}
                                                className="mt-2 block w-full bg-white border border-gray-200 py-2 px-4 rounded"
                                                name="country">
                                            <option value={'Canada'}>Canada</option>
                                            <option value={'USA'}>USA</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block font-normal mb-2" htmlFor="use_of_loan">
                                    Citizenship *
                                </label>
                                <select onChange={handleInputs} value={state?.citizenship}
                                        className="block w-full bg-white border border-gray-200 py-2 px-4 rounded"
                                        name="citizenship">
                                    <option value={'Canadian'}>Canadian
                                    </option>
                                    <option value={'American'}>American
                                    </option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <div className="flex justify-between items-center m_flex_direction">
                                    <label className="font-normal mb-2" htmlFor="use_of_loan">
                                        Government Issued Identification *
                                    </label>

                                    <select onChange={handleInputs} value={state?.govt_identity_type}
                                            className="m_width_100 bg-white w-1/4 border border-gray-200 py-2 px-4 rounded"
                                            name="govt_identity_type">
                                        <option value={'Passport'}>Passport</option>
                                        <option value={'Driver License'}>Driver License</option>
                                        <option value={'ID Card'}>ID Card</option>
                                    </select>
                                    <div className="file-inside" onClick={handleClick}>
                                        <input name="file[]" id={'multi_file'} multiple={true} ref={inputRef}
                                               onChange={imageUpload} type="file"/>
                                        <p className={'ml-0'}>{state?.government_issued_identification > 0 ? state.government_issued_identification + " files selected" : 'Add File'}</p>
                                    </div>
                                </div>
                                <div
                                    className="flex align-center items-start justify-start border border-gray-200 h-72 w-md-editor-fullscreen my-2">
                                    <div id="removed_images"></div>
                                    {oldImages?.map((item, key) => {
                                        return (<div key={key} className="relative">
                                                <div className="remove_img_btn" onClick={() => removeOldImage(key)}>X
                                                </div>
                                                <input name="old_images[]"
                                                       type="hidden" value={item}/>
                                                <img className={'m-2 image_preview'} src={`${imagesUrl}${item}`}
                                                     alt=""/>
                                            </div>
                                        )
                                    })}
                                    {images?.map((item, key) => {
                                        return (
                                            <div key={key} className="relative">
                                                <div className="remove_img_btn" onClick={() => removeNewImages(key)}>X
                                                </div>
                                                <img className={'m-2 image_preview'} src={item} alt=""/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <p className=" items-center m-auto mx-auto"> When uploading your government ID please include both the front and back of your ID</p>
                                {!user?.approved ?
                                    <button className="button btn-rounded" type="submit">
                                        Submit
                                    </button> : null
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </AdminWrapper>);
};
export default PersonalInfo;
