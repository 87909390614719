import React, { useState } from "react";
import Wrapper from "../../routes/Wrapper";
import ReactHtmlParser from "react-html-parser";
import { ArrowDownIcon, ArrowUpIcon } from "../../assets/Icons";

const FAQs = () => {
  const FaqsList = [
    {
      question: "What is Bitcoin backed lending?",
      answer:
        "Bitcoin backed lending is a form of lending where borrowers provide Bitcoin as collateral to secure a loan. This collateral helps mitigate risks for lenders and allows borrowers to access funds without selling their Bitcoin.",
    },
    {
      question: "How does the lending process work?",
      answer: "To initiate the borrowing process, follow these simple steps: <br/> <br/> 1. Create an Account Begin by signing up for a Bitloc account. This quick and straightforward process will grant you access to our lending platform. <br/>  2. Provide Information Complete your profile by furnishing the necessary personal and banking details. This information allows us to ensure a secure and compliant lending experience. <br/>   3. Verification and Approval Once you've submitted your information, our team will review your application. After successful verification, you'll proceed to the next steps. <br/>  4. Banking Information Setup Set up your banking details to facilitate the seamless transfer of funds. Specify your wire or e-transfer preferences for a convenient borrowing experience. <br/>   5. Bitcoin Address Entry Enter your receiving Bitcoin address. This serves as your designated destination for the return of your Bitcoin at the end of the loan term. <br/>  6. Collateral Deposit Below your receive address, you'll find a unique Bitloc deposit address. This is where you'll securely send your Bitcoin collateral. <br/>  7. Collateral Confirmation Once Bitloc confirms your collateral deposit, the process moves forward. You can expect your fiat funds to be dispatched within 24-72 business hours.",
    },
    {
      question: "What is the loan-to-value (LTV) ratio?",
      answer:
        "The loan-to-value ratio represents the percentage of the loan amount compared to the value of the Bitcoin collateral. For instance, an LTV of 50% means borrowers can receive a loan worth 50% of their deposited Bitcoin value.",
    },
    {
      question:
        "What happens if the value of Bitcoin fluctuates during the loan term?",
      answer:
        "As the value of Bitcoin fluctuates, the LTV ratio may change. Borrowers may need to add collateral or repay part of the loan to maintain a safe LTV level.",
    },
    {
      question: "What is the loan repayment process?",
      answer:
        "Borrowers need to make regular monthly interest payments, at the end of the term the borrower is expected to make their final interest payment plus pay back the principle.",
    },
    {
      question: "What is the origination fee?",
      answer:
        "The origination fee is a cost associated with processing a loan application. It is a percentage of the total loan amount and is charged by lenders to cover administrative expenses, underwriting, and processing the loan. This fee is deducted from the loan amount before you receive the funds, meaning you will receive a reduced loan amount after the origination fee is subtracted. The origination fee is a one-time upfront charge imposed at the beginning of the loan process.",
    },
    {
      question: "Who do we serve?",
      answer:
        "We serve all Canadian citizens residing in Ontario over the age of 18.",
    },
    {
      question: "Can I repay my loan early?",
      answer: "Yes, borrowers can repay their loans early without penalty.",
    },
    {
      question: "How long does it take to get approved for a loan?",
      answer:
        "Approval times can vary based on the application volume but should be done within a couple business days.",
    },
    {
      question: "What happens if I can’t repay my loan?",
      answer:
        "Defaulting on the loan may lead to liquidation of collateral and could incur a small fee.",
    },
    {
      question: "What is Rehypothecation?",
      answer:
        "Rehypothecation is a financial practice where an intermediary, such as a lending platform, uses assets (such as Bitcoin) provided by its customers as collateral to obtain financing for its own purposes, potentially exposing customers' assets to additional risk. ",
    },
    {
      question: "Does Bitloc engage in Rehypothecation?",
      answer:
        "No, at Bitloc, we are committed to ensuring the utmost safety and security of our users’ assets. We do not engage in rehypothecation practices with the assets our customers provide as collateral for loans. Your Bitcoin collateral remains safely in cold storage and is not used for any other purposes.",
    },
    {
      question: "Proof of Reserves?",
      answer:
        "Proof of Reserves is a transparency measure that allows borrowers to independently verify that their collateral remains secure in their designated deposit address.",
    },
    {
      question: "How does Bitloc implement Proof of Reserves?",
      answer:
        "Auditing your collateral is simple. Enter your Bitcoin deposit address into any reputable block explorer. You will be able to view the transaction history, balance and movements associated with that address, providing you with reassurance that your collateral remains intact",
    },
    {
      question: "Is there a minimum amount?",
      answer:
        "Yes, the minimum amount to acquire a loan from Bitloc is $1,000.00 CAD.",
    },
  ];

  const FaqsItems = ({ key, item }) => {
    const [expend, setExpend] = useState(false);
    return (
      <div key={key} className="faq-item flex flex-col">
        <div
          className="item-hdr flex items-left justify-start cursor-pointer"
          onClick={(e) => setExpend(!expend)}
        >
          <h1 className="qustion">{item.question}</h1>
          <button
            className="icon flex items-left justify-start cursor-pointer"
            onClick={(e) => setExpend(!expend)}
          >
            <ArrowDownIcon />
          </button>
        </div>
        <p className={`answer ${expend ? "show" : ""}`}>
          {ReactHtmlParser(item.answer)}
        </p>
      </div>
    );
  };
  return (
    <Wrapper>
      <div className="faqs-page bg-white h-screen flex flex-col">
        <div className="wrap wrapWidth flex flex-col">
          <h1 className="sec-title my-4">FAQ’s</h1>
          <div className="faqs-block flex flex-col">
            {FaqsList.map((item, index) => (
              <FaqsItems key={index} item={item} />
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default FAQs;
