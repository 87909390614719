import React, {useEffect, useState} from "react";
import "./css/App.scss";
import "./index.css";
import Routing from "./routes/Routing";
import {setUser, setUserToken} from "./store/reducers/authReducer";
import {useDispatch} from "react-redux";
import {apiUrl} from "./helpers/MyHelpers";
import ReactGA from "react-ga4";

ReactGA.initialize("G-JVM6TL3YSJ");

function App() {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("_token");
  const [loading, setLoading] = useState(true);
  const checkLogin = () => {
    return fetch(apiUrl+'users/check_login',{
      headers: {
        'Authorization': 'Bearer '+accessToken
      }
    }).then(res => res.json()).then(d => {
      if(d?.user != undefined){
        dispatch(setUserToken(accessToken));
        dispatch(setUser(d.user));
      } else {
        dispatch(setUserToken(""));
        dispatch(setUser(""));
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    if(accessToken){
      checkLogin()
    } else {
      setLoading(false);
    }
  }, [])

  return (
      loading ? <div className="loader"><img src={'/images/loading_gif.gif'} alt=""/>Loading...</div> :  <Routing/>
  );
}

export default App;
