import React from "react";
import { NavLink } from "react-router-dom";
const PagesMenuSidebar = () => {
  const sidebarMenuList = [
    { lbl: "Terms of Service", link: "/terms/TermsofService" },
    { lbl: "Privacy Policy", link: "/terms/PrivacyPolicy" },
    { lbl: "Risk Disclosure", link: "/terms/RiskDisclosure" },
  ];
  return (
    <div className="pages-menu-sidebar absolute ">
      <div className={`side-block flex flex-col anim pt-10`}>
        {sidebarMenuList.map((item, index) => (
          <NavLink key={index} to={item.link} className="nave-link">
            <h1 dangerouslySetInnerHTML={{ __html: item.lbl }} />
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default PagesMenuSidebar;
