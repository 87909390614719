import React from "react";
import Wrapper2 from "../../routes/Wrapper2";
const PrivacyPolicy = () => {
  return (
    <Wrapper2>
      <div className="b2x-terms-page flex bg-white">
        <div className="page-block flex flex-col">
          <div className="page-title text-black">
          PrivacyPolicy
          </div>
          <div className="content-list flex flex-col">
            <h5 className="content-title">
                Introduction
            </h5>
<p className="content-desc">
Please take a moment to read this Policy carefully. This Policy describes when, why, and how BitLoc Inc. (together with its affiliates and subsidiaries, “BitLoc”) collects, uses, discloses, and protects your personal information.
</p>
<p className="content-desc">
By accessing or using BitLoc’s website at [https://bitloc.com/](https://bitloc.com/) and its subdomains, or BitLoc’s mobile application, which can be downloaded from the Apple App Store or Google Play Store (collectively, the “Platform”), you acknowledge that you have read, understood, and accept this Privacy Policy (this “Policy”) and consent to the practices described herein. You acknowledge that BitLoc’s Terms of Service, found at [https://bitloc.com/legal/terms-of-service](https://bitloc.com/legal/terms-of-service), are incorporated into this Policy by reference. If you do not agree with this Policy, you should not access or use the Platform.
</p>
<p className="content-desc">
As part of BitLoc’s regular business operations, BitLoc collects personal information from you to provide you with BitLoc’s products and services. BitLoc’s company culture is centered on transparency, integrity, and fairness. These values guide everything that BitLoc does, including how BitLoc gathers and uses your personal data and information.
</p>
<p className="content-desc">
BitLoc will only use your personal information in accordance with this Policy unless otherwise required by applicable law. BitLoc takes steps to ensure that the personal information that BitLoc collects about you is adequate, relevant, not excessive, and used for limited purposes. BitLoc only collects the necessary information to originate, administer, and process BitLoc’s services (including as necessary to comply with BitLoc’s legal and regulatory obligations).
</p>
<p className="content-desc">
BitLoc’s services may include links to third-party websites, plug-ins, services, social networks, or applications. Clicking on those links or enabling those connections may allow the third party to collect or share personal information about you. If you follow a link to a third-party website or engage a third-party plugin or service, please note that these third parties have their own privacy policies, and BitLoc does not accept any responsibility or liability for those policies. BitLoc does not control these third-party websites, and BitLoc encourages you to read the Policy of every website you visit.
</p>


**What is personal information?**

<p className="content-desc">
Personal information is typically data that identifies an individual or relates to an identifiable individual. This includes information BitLoc can reasonably use to, directly or indirectly, identify you, such as your name, mailing address, email address, telephone number, Internet protocol (IP) address used to connect your computer to the Internet, username, or other similar identifier, billing and account information, social insurance number, and any other identifier BitLoc may use to contact you.
</p>
<p className="content-desc">
Non-personal information does not directly or indirectly reveal your identity or directly relate to an identifiable individual. Examples of non-personal information are statistical or aggregate data. Statistical or aggregated data does not directly identify a specific person, but BitLoc may derive non-personal statistical or aggregated data from personal information. For example, BitLoc may aggregate personal information to calculate the percentage of users accessing a specific page on BitLoc’s website.
</p>
<p className="content-desc">
Non-personal information also includes technical information such as your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform, or information about your internet connection, the equipment you use to access BitLoc’s website, and usage details. BitLoc also collects non-personal details about your website interactions, including the full Uniform Resource Locators (URLs), clickstream to, through and from BitLoc’s website (including date and time), pages, products, and services you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from a page, or any phone number used to call BitLoc’s customer service number.
</p>


**How does BitLoc collect information about you?**
<br/><br/>
**3.1. Overview**
<p className="content-desc">
BitLoc uses three different methods to collect your information.
</p>
<p className="content-desc">
First, BitLoc collects information when you provide it to BitLoc through BitLoc’s direct interactions with you, for example, by filling in forms or corresponding with BitLoc through BitLoc’s website or by phone, email, video call, video conference, instant messaging app, or otherwise. You also provide BitLoc with information when you interact with BitLoc through public websites, including social media and social networking websites.
</p>
<p className="content-desc">
Second, BitLoc collects information through cookies and other automated technologies or interactions, as you navigate through BitLoc’s website. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.
</p>
<p className="content-desc">
Third, BitLoc also collects information about you from third parties, as required or permitted by applicable law. Third-party sources may include public databases, credit bureaus, identity verification partners, resellers and channel partners, support channel partners, joint marketing partners, and social media platforms. Additionally, BitLoc receives personal information when you link a third-party service to BitLoc. Such linkage may include interactions with BitLoc’s social media sites. Please remember that third-party websites are governed by their own privacy policies, and you should review those privacy policies before using those sites.
</p>

**3.2. Information you provide to BitLoc**
<p className="content-desc">
BitLoc collects information from you by asking you directly for it; this includes when you interact with one of BitLoc’s representatives or when you fill certain forms on BitLoc’s website.
</p>
<p className="content-desc">
To establish an account and access BitLoc’s services, BitLoc will ask you to provide BitLoc with some information about yourself. This information is either required by law (e.g., to verify your identity), necessary to provide the requested services (e.g., you will need to provide your wallet address if you would like to link that wallet to your BitLoc account), or is relevant for certain specified purposes, described in greater detail below. As BitLoc adds new features and services, you may be asked to provide additional information. BitLoc may ask you for information when you enter a contest or promotion sponsored by BitLoc and when you report a problem with BitLoc’s website. BitLoc may also ask you for additional information or to confirm the information you previously provided to BitLoc if you make an unusual transaction or make an unusual request.
</p>
<p className="content-desc">
Please note that BitLoc may not be able to serve you as effectively or at all or offer you BitLoc’s services if you choose not to share certain information with BitLoc. Any information you provide to BitLoc that is not required is voluntary. For example, you may provide BitLoc with additional personal information if you select to designate beneficiaries to your account or if you safelist certain wallet addresses.
</p>
<p className="content-desc">
BitLoc may collect the following types of information from you.
</p>
<p className="content-desc">
(a) **Personal identification information:** such as your full legal name, date of birth, nationality, gender, signature, utility bills, photographs, phone number, home address, information about family members, and email.
</p>
<p className="content-desc">
(b) **Formal identification information:** including government-issued identity document such as passport, driver's license, national or state identity card, tax identification number, social insurance number (or any comparable identification number issued by a government), social security card, passport number, driver's license details, national identity card details, visa information, and/or any other information deemed necessary to comply with BitLoc’s legal obligations under financial, anti-money laundering, or counter-terrorism financing laws.
</p>
<p className="content-desc">
(c) **Employment information:** including employment or occupation industry, office location, job title, and/or description of role, employer identification number (or comparable number issued by a government)
</p>
<p className="content-desc">
(d) **Additional information:** including responses to questions that BitLoc is specifically required to ask or information that BitLoc is required to obtain to meet BitLoc’s regulatory, compliance, or other legal obligations, such as questions about the source and the intended use of any funds or digital assets; proof of legal formation (e.g., articles of incorporation), personal identification information for all material beneficial owners; bank account information, payment card account number, transaction history, trading data; information about the transactions you made or intend to make.
</p>
<p className="content-desc">
(e) **Other information:** BitLoc’s compliance team may request additional information from you, as may be required for compliance with law, regulations, or BitLoc’s internal policies. This may include information relating to travel history, relationship/marital status, and criminal convictions or charges.
</p>


**3.3. Information BitLoc collects through cookies**

<p className="content-desc">
As you navigate through and interact with BitLoc’s website, BitLoc uses cookies and other automatic data collection technologies to collect certain information about you, your equipment, browsing actions, and patterns, including details of your visits to BitLoc’s website, traffic data, location data, logs, information about your computer and internet connection, including your IP address, operating system, and browser type and other communication data and the resources that you access and use on the website.
</p>
<p className="content-desc">
Cookies (or browser cookies) are small text files sent from a site to the hard drive of your device (e.g., computer or phone) to store bits of information related to you. First-party cookies are put on your device directly by BitLoc’s website, which allows BitLoc to collect analytical data and provide other useful functions that create a good user experience.
</p>
<p className="content-desc">
Third-party cookies are placed on your device by a third party (e.g., advertiser or analytic system). The third parties who serve cookies on BitLoc’s site may link your name or email address to other information they collect, which may include past purchases made offline or online, or your online usage information. Examples of such third-party cookies that BitLoc’s website may use are Facebook, Twitter, LinkedIn, Google, and Bing. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of BitLoc’s website. Unless you have adjusted your browser setting so that it will refuse cookies, BitLoc’s system will issue cookies when you visit BitLoc’s website.
</p>
<p className="content-desc">
Cookies are very helpful and may be used for various different purposes. These purposes include, among other things, allowing you to navigate between pages efficiently, enabling automatic activation of certain features, remembering your preferences and making the interaction between you and the website quicker, easier, and smoother.
</p>
<p className="content-desc">
Certain features of BitLoc’s website may use local stored objects (sometimes referred to as flash cookies) to collect and store information about your preferences and navigation to, from, and on BitLoc’s website. Flash cookies are not managed by the same browser settings that are used for browser cookies.
</p>
<p className="content-desc">
Pages of BitLoc’s website and BitLoc’s emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that allow BitLoc, among other things, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).
</p>


**3.4. Third party cookies**

<p className="content-desc">
Some content or applications on BitLoc’s website may be served by third parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use BitLoc’s website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content. BitLoc does not control these third parties' tracking technologies or how they are used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.
</p>


**3.5. Publicly available information**


<p className="content-desc">
Anyone can see the balance and transaction history of any public digital asset address (public key). BitLoc, and any other person who can match your digital asset address to other information about you, may be able to identify you from a blockchain transaction. This is because information published on a blockchain can be correlated with information that BitLoc and others may have. This may be the case even if BitLoc, or they, were not involved in the blockchain transaction. Furthermore, by using data analysis techniques on a given blockchain, it may be possible to identify other information about you. As part of BitLoc’s security, anti-fraud and/or identity verification and authentication checks, BitLoc may conduct such analysis to collect and process such information about you. You acknowledge and agree to allow BitLoc to perform such practices.
</p>


**3.6. Online tracking and do not track signals**


<p className="content-desc">
BitLoc may, and BitLoc may allow third party service providers to, use cookies or other tracking technologies to collect information about your browsing activities over time and across different websites following your use of BitLoc’s website.
</p>


**3.7. Children**


<p className="content-desc">
BitLoc's services are designed for individuals of the age of majority, typically 18 years or older. We do not knowingly or intentionally collect personal information from individuals below the age of majority. If it comes to our attention that we have inadvertently obtained personal information from a minor, we will take legally permissible measures to promptly remove that information from our records. In such cases, we will require the minor user to close their account, and the use of BitLoc's products and services will not be permitted for individuals under the age of majority. If you are a parent or guardian and become aware that a minor has provided personal information to BitLoc, please contact us at privacy@bitloc.io.
</p>


**Why does BitLoc collect your information and how does BitLoc use it?**


<p className="content-desc">
The information collected and the practices described in this Policy are implemented to enhance your experience, protect against risks related to misuse and fraud, comply with legal and regulatory obligations, and improve BitLoc’s services and products.
</p>


**4.1. To Maintain Legal and Regulatory Compliance**

<p className="content-desc">
BitLoc's services are subject to various laws and regulations that necessitate the collection, use, and storage of personal information. This includes identifying and verifying customers to comply with anti-money laundering and counter-terrorism financing laws. To achieve this, BitLoc may request and store your photo identification and other relevant documents.
</p>

<p className="content-desc">
Third parties may also be involved in identity verification through comparison with third-party databases and public records. Failure to provide necessary personal information as required by law may result in the closure of your account.
</p>


**4.2. To Protect You and BitLoc**

<p className="content-desc">
Processing your personal information is crucial for administering and safeguarding BitLoc’s business. This includes preventing and mitigating fraud, criminal activity, or misuse of BitLoc’s services, as well as ensuring the security of systems and protecting against account compromise or funds loss.
</p>


**4.3. Enforce the Terms of BitLoc’s Agreements**


<p className="content-desc">
BitLoc utilizes personal information to actively monitor, investigate, and prevent potentially prohibited or illegal activities, enforcing agreements with users and third parties, and collecting fees based on your use of BitLoc’s services.
</p>


**4.4. To Provide You with BitLoc’s Services and Keep You Informed**


<p className="content-desc">
Personal information is processed to provide BitLoc’s services, such as storing digital assets, requiring identification, contact, and payment information. Administrative or account-related information is sent to keep you updated on services, relevant security issues, or transaction-related information.
</p>


**4.5. To Provide Customer Service and Support**

<p className="content-desc">
Processing personal information is essential for addressing user inquiries, resolving disputes, collecting fees, and troubleshooting problems. This ensures uninterrupted use of BitLoc’s services and maintains service quality.
</p>


**4.6. To Engage in Marketing and Promotional Activities**

<p className="content-desc">
BitLoc may send marketing communications to inform you about events, partner events, deliver targeted marketing, and provide promotional offers. Marketing activities align with your preferences and comply with applicable law.
</p>


**4.7. To Enhance Your Experience and for Development Purposes**

<p className="content-desc">
Personal information is processed to understand user interactions, customize, measure, and improve services, and develop new offerings. This is vital for providing a personalized experience and implementing user preferences.
</p>


**What Is the Legal Basis for Collecting and Processing Your Information?**

<p className="content-desc">
BitLoc relies on legal bases such as legal requirements, contractual necessity, legitimate interests, and consent when collecting and processing personal information. Consideration of potential impacts on privacy rights and interests is integral to BitLoc’s approach.
</p>


**With Whom Does BitLoc Share Your Information?**
<br/>  <br/>
**6.1. BitLoc’s Affiliates**
<p className="content-desc">
Personal information may be shared with BitLoc’s affiliates in accordance with this Policy for product distribution or marketing purposes.
</p>


**6.2. Service Providers**

<p className="content-desc">
BitLoc engages financial, legal, marketing, and technology companies to provide various services. Personal information may be shared with these entities for data processing, administrative services, regulatory support, and more, in compliance with applicable law.
</p>


**6.3. Regulatory and Government Authorities**

<p className="content-desc">
BitLoc may share personal information with law enforcement, regulatory agencies, and self-regulatory bodies to prevent fraud, legal proceedings, or as required by law.
</p>


**Why Does BitLoc Share Your Information with Others?**


<p className="content-desc">
Sharing information facilitates transaction processing, legal compliance, protection of legal rights, and, in certain cases, transfers related to business acquisitions or asset sales.
</p>


**Where Does BitLoc Store and Process Your Personal Information?**


<p className="content-desc">
BitLoc may transfer personal information outside your home jurisdiction to affiliates, service providers, and partners. Such transfers comply with applicable laws, ensuring lawful processing.
</p>


**How Does BitLoc Protect Your Personal Information?**

<p className="content-desc">
BitLoc maintains physical, electronic, and procedural safeguards to protect personal information throughout its lifecycle. Safeguards include firewalls, data encryption, access controls, and authorization for employee access based on job responsibilities.
</p>


**BitLoc’s Data Retention Policy**


<p className="content-desc">
Upon account closure, BitLoc marks accounts as "closed" but retains account information per BitLoc’s retention policy to prevent fraud and meet legal obligations. Personal information will not be used for further purposes after account closure.
</p>


**Your Privacy Rights**
<br/> <br/>

**11.1. Right to Access**

<p className="content-desc">
You may request BitLoc to provide you with a copy of the personal information BitLoc holds about you and information about its processing.
</p>


**11.2. Rectification**

<p className="content-desc">
You can request rectification of inaccurate or incomplete personal information.
</p>


**11.3. Right to Erasure**

<p className="content-desc">
You may request the deletion of your personal information from BitLoc’s records, subject to exceptions.
</p>


**11.4. Right to Data Portability**

<p className="content-desc">
You can request a machine-readable copy of your personal information for transfer.
</p>


**11.5. Right to Restrict Processing**

<p className="content-desc">
You may request the restriction or suppression of your personal information under certain circumstances.
</p>


**11.6. Right to Object**

<p className="content-desc">
You can object to BitLoc’s reliance on legitimate interests for processing your personal information and for direct marketing.
</p>


**11.7. Right to Withdraw Consent**

<p className="content-desc">
You have the right to withdraw consent where BitLoc relies on it for processing your personal information.
</p>


**11.8. Right Not to Sell**

<p className="content-desc">
BitLoc will not sell or rent your personal information without explicit consent.
</p>


**11.9. Jurisdictional Specific Privacy Rights**

<p className="content-desc">
Additional statutory rights may apply depending on your jurisdiction, and you can exercise these rights by contacting BitLoc.
</p>


**11.10. Additional Information**

<p className="content-desc">
The Cayman Islands Data Protection Act, 2017, applies to entities in the Cayman Islands. Affiliated entities adhere to the DPA requirements and may share personal data with affiliates, service providers, and regulatory authorities. Specific rights under the DPA apply to your personal data.
</p>


**Changes to this Policy**

<p className="content-desc">
This Policy is regularly reviewed and updated to consider new obligations, technologies, changes to BitLoc’s operations, and regulatory updates. Your continued use indicates acceptance of changes. BitLoc strives to notify you in advance of material changes and obtain your consent.
</p>


**Contact BitLoc**

<p className="content-desc">
For questions, comments, or complaints about this Policy or BitLoc’s privacy practices, contact privacy@bitloc.io. BitLoc aims to respond within 30 days, with longer timelines for complex requests. If dissatisfied, you have further rights, including the right to file a complaint with regulatory agencies.
</p>

          </div>
        </div>
      </div>
    </Wrapper2>
  );
};

export default PrivacyPolicy;
