import React from "react";

function ArrowUpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="7"
      fill="none"
      viewBox="0 0 13 7"
    >
      <path
        fill="#000"
        d="M6.095.165l-5.94 5.94A.52.52 0 000 6.474a.52.52 0 00.154.368l.007.006A.516.516 0 00.534 7a.537.537 0 00.373-.153l5.594-5.593 5.592 5.593a.515.515 0 00.373.153.537.537 0 00.373-.153l.006-.006c.1-.1.155-.23.155-.368a.52.52 0 00-.155-.367L6.905.166A.56.56 0 006.5 0a.583.583 0 00-.405.165z"
      ></path>
    </svg>
  );
}

export default ArrowUpIcon;
