import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setOpenSidebar } from "../../store/reducers/globalReducer";
import { CloseIcon } from "../../assets/Icons";
import Whitepaper from "../../assets/images/bitcoin.pdf";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { openSidebar } = useSelector((state) => state.globalReducer);
  const navBarItems = [
    {
      title: "Products",
      subList: [
        {
          lbl: "Bitcoin backed loans",
          link: "/bitcoinBackedLoans",
        },
      ],
    },
    {
      title: "About",
      subList: [
        {
          lbl: "Our team",
          link: "/",
        },
        {
          lbl: "Security",
          link: "/security",
        },
        {
          lbl: "Careers",
          link: "/careers",
        },
        {
          lbl: "About us",
          link: "/aboutus",
        },
      ],
    },
    {
      title: "Learn More",
      subList: [
        {
          lbl: "Contact",
          link: "/contactus",
        },
        {
          lbl: "Blog",
          link: "/blogs"
        },
        {
          lbl: "Risk management",
          link: "/riskManagement",
        },

        {
          lbl: "Bitcoin Whitepaper",
          link: Whitepaper,
        },
        {
          lbl: "Bitcoin Charts",
          link: "/bitcoinchart",
        },
      ],
    },
  ];

  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
      dispatch(setOpenSidebar(false));
    });
  }, []);

  return (
    <div
      className={`sidebar-s fixed rel anim ${openSidebar ? "show" : "hide"}`}
    >
      <div
        className={`side-block flex col anim ${openSidebar ? "show" : "hide"}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="hdr flex items-center justify-end">
          <div
            className="icon-close flex aic jc"
            onClick={(e) => {
              dispatch(setOpenSidebar(false));
            }}
          >
            <CloseIcon />
          </div>
        </div>

        <div className="menus-list flex flex-col">
          {navBarItems.map((item, index) => (
            <div key={index} className="menu-item flex flex-col">
              <h1 className="menu-title">{item.title}</h1>
              <div className="sub-menu-list flex flex-col">
                {item.subList.map((subItem, subIndex) => (
                  <Link
                    key={subIndex}
                    to={subItem.link}
                    className="sub-li"
                    onClick={(e) => {
                      dispatch(setOpenSidebar(false));
                    }}
                  >
                    {subItem.lbl}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="actions flex flex-col">
          <Link
            to="/auth/register"
            className="btn-login button"
            onClick={(e) => {
              dispatch(setOpenSidebar(false));
            }}
          >
            Sign Up
          </Link>
          <Link
            to="/auth/login"
            className="btn-login button"
            onClick={(e) => {
              dispatch(setOpenSidebar(false));
            }}
          >
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
