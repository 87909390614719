import React, {useEffect, useState} from "react";
import AdminWrapper from "../../../routes/AdminWrapper";
import {ArcElement, Chart} from 'chart.js'
import GaugeChart from 'react-gauge-chart'
import {apiUrl, customAlert,} from "../../../helpers/MyHelpers";
import {useSelector} from "react-redux";
import Tab from "@mui/material/Tab";
import makeRequest from "../../../helpers/axiosRequest";
import Swal from "sweetalert2";

const Loans = () => {
    const {user} = useSelector(state => state.authReducer)
    Chart.register(ArcElement);
    let authToken = localStorage.getItem("_token");
    const [activeTab, setActiveTab] = useState(null);
    const [myLoans, setMyLoans] = useState([]);
    const [payOption, setPayOption] = useState({paid: ''});
    const [toPay, setToPay] = useState(0);
    const [toPayBTC, setToPayBTC] = useState(0);
    const [payTypeBTC, setPayTypeBTC] = useState(false)
    const [singlePayment, setSinglePayment] = useState({
        _id: "",
        paid: "",
        payment: "",
        collateral_amount: "",
    })
    const [modalDisplay, setModalDisplay] = useState('none')
    const [cancelModalDisplay, setCancelModalDisplay] = useState('none')
    const [data, setData] = useState([]);
    const [apiValue, setApiValue] = useState('');
    const [loader, setLoader] = useState(true);
    // This is Api Request For Getting Bitcoins Rates
    // Getting Loans
    const getMyLoans = () => {
        return fetch(apiUrl + `users/get_my_loans/${user?._id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken
            }
        }).then(res => res.json()).then(d => {
            fetch("https://blockchain.info/ticker")
                .then((res) => res.json())
                .then((data) => {
                    setApiValue(data.CAD.last)
                    setMyLoans(d.loans)
                    setLoader(false)
                    // setApiValue(25000);
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }
    // Use Effect for Set Active tab Data
    useEffect(() => {
        if (activeTab !== null) {
            setData(myLoans[activeTab]?.payments)
        }
    }, [activeTab])
    // Set Current Month Payment
    let currentMonthPayment = [];
    if (data) {
        if (data[0]?.paid === null) {
            currentMonthPayment = [data[0]];
        } else {
            currentMonthPayment = data?.filter((item) => new Date(item.date).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long'
            }) === new Date().toLocaleString('en-US', {year: 'numeric', month: 'long'}));
        }

    }
    // UseEffect for gert loans and Bitcoin Value
    useEffect(() => {
        setActiveTab(0);
        getMyLoans()
        const interval = setInterval(() => {
            getMyLoans();
        }, 15000);
        return () => clearInterval(interval);
    }, [])
    // Splitting Paid and unpaid Payments
    const paidPayments = data?.filter((item) => item.paid !== null);
    const UnpaidPayments = data?.filter((item) => item.paid === null);
    let lastPaid;
    if (paidPayments?.length > 0) {
        lastPaid = paidPayments[paidPayments?.length - 1];
    }
    const handleTabClick = (index) => {
        setActiveTab(index);
        setPayOption({paid: ''});
    };
    useEffect(() => {
        // If Current Month Payment is Paid Set to Active
        if (currentMonthPayment.length > 0) {
            if (currentMonthPayment[0]?.paid != null) {
                const paidBy = currentMonthPayment[0]?.paid.includes('Bitcoin') ? "Bitcoin" : currentMonthPayment[0]?.paid;

                // Update the state only when the value changes
                if (paidBy !== payOption.paid) {
                    setPayOption({paid: paidBy});
                }
            }
        } else {
            const paidBy = lastPaid?.paid.includes('Bitcoin') ? "Bitcoin" : lastPaid?.paid;
            // Update the state only when the value changes
            if (paidBy !== payOption.paid) {
                setPayOption({paid: paidBy});
            }
        }

    }, [currentMonthPayment, payOption.paid, activeTab]);

    const handlePayOption = (e) => {
        if (currentMonthPayment.length === 0) {
            customAlert({
                "icon": "warning",
                "text": `Your Payment Has been Paid of This month. Your new payment date is ${UnpaidPayments[0]?.date?.toString().split("T")[0]}`
            })
            return;
        } else {
            if (currentMonthPayment[0].paid !== null) {
                customAlert({
                    "icon": "warning",
                    "text": `Your Payment Has been Paid of This month. Your new payment date is ${UnpaidPayments[0]?.date?.toString().split("T")[0]}`
                })
                return
            }
        }
        if (e.target.value === 'Bitcoin') {
            const ToPay = parseFloat(myLoans[activeTab]?.payments[0]?.payment_due)
            const ToPayBtc = (parseFloat(myLoans[activeTab]?.payments[0]?.payment_due) / apiValue)
            // 4.5% added to BTC
            const percentageBTC = (ToPayBtc * 4.5) / 100;
            const percentageCAD = (ToPay * 4.5) / 100;
            setToPay((ToPay + percentageCAD).toFixed(2))
            setToPayBTC(ToPayBtc + percentageBTC)
            modalHandle()
            setPayTypeBTC(true)
            setPayOption({[e.target.name]: e.target.value});
        } else {
            let ModalTitle, ModalDesc;
            if (e.target.value === 'EMT') {
                ModalTitle = "Send EMT";
                ModalDesc = '<p>Please send payment to info@bitloc.ca</p>'
            } else {
                ModalTitle = "Wire Transfer ";
                ModalDesc = '<div><p>Wire transfer details are below please confirm you would like to pay using Wire Transfer</p>' +
                    '<div class="flex justify-between pt-5 Sweet_cancel_modal">' +
                    '<div class="right-text"><p class="font-bold">Beneficiary Name:</p><p class="font-bold">Beneficiary Address:</p><br> <p class="font-bold">Beneficiary Account Nummber:</p> <p class="font-bold">Transit Nummber:</p> <p class="font-bold">Institution Nummber:</p> <p class="font-bold">Beneficiary Bank:</p></div>' +
                    '<div class="left-text"><p>Bitloc Inc</p> <p>16 Brokers Lane Apt 304, <br> Toronto Ontario M8V 0A5</p> <p>5259809</p> <p>17362</p> <p>004</p> <p>TD Canada Trust</p></div></div>' +
                    '</div>'
            }
            Swal.fire({
                title: ModalTitle,
                html: ModalDesc,
                customClass: "cancel_modal",
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm Payment'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        setPayOption({[e.target.name]: e.target.value});
                        setSinglePayment(prevState => ({
                            ...prevState,
                            _id: currentMonthPayment[0]?.loan,
                            paid: e.target.value,
                            payment: currentMonthPayment[0]?.payment_due,
                            collateral_amount: myLoans[activeTab]?.collateral_amount - currentMonthPayment[0]?.payment_due,
                        }))
                    } catch (err) {
                        customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                    }
                }
            })
            setPayTypeBTC(false)
        }
    };

    const modalHandle = () => {
        if (toPay !== undefined) {
            if (modalDisplay === "none") {
                setModalDisplay("flex")
            } else {
                setModalDisplay("none")
            }
        } else {
            customAlert({
                "icon": "error",
                "text": "No Payment Available to submit"
            })
        }
    }

    const cancleModalHandle = () => {
        if (cancelModalDisplay === "none") {
            setCancelModalDisplay("flex")
        } else {
            setCancelModalDisplay("none")
        }
    }
    const handleBTCPayment = (e) => {
        setSinglePayment(prevState => ({
            ...prevState,
            _id: currentMonthPayment[0]?.loan,
            paid: 'Bitcoin ' + toPayBTC.toFixed(6) + ' ' + e,
            payment: currentMonthPayment[0]?.payment_due,
            collateral_amount: myLoans[activeTab]?.collateral_amount - currentMonthPayment[0]?.payment_due,
        }))
        modalHandle()
    }


    const copyToClipboard = () => {
        navigator.clipboard.writeText(myLoans[activeTab]?.admin_wallet)
        customAlert({
            "icon": "success",
            "text": "Wallet Address Has Been Copied"
        })
    }
    const handlePaymentSubmit = async () => {
        if (singlePayment._id && singlePayment?.paid) {
            if (payOption === undefined) {
                customAlert({
                    "icon": "warning",
                    "text": "Kindly Select any type of payment"
                })
                return
            }
            if (payTypeBTC === true) {
                const is_btc = singlePayment?.paid.toLowerCase().includes('bitcoin')
                if (!is_btc) {
                    return
                }
            }
            if (currentMonthPayment[0] === undefined) {
                customAlert({
                    "icon": "error",
                    "text": "No Payment Available to submit"
                })
                return
            }
            const response = await makeRequest('post', `${apiUrl}users/save_payment_paid/${currentMonthPayment[0]._id}`, singlePayment)
                .then(response => {
                    if (response?.data?.status == 'Success') {
                        customAlert({
                            "icon": "success",
                            "title": "Success",
                            "text": "Payments Updated"
                        })
                        setTimeout(function () {
                            window.location.reload()
                        }, 700)
                        window.location.reload()
                    } else if (response?.error) {
                        customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                    } else {
                        customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                    }
                })
        } else {
            if (payOption === undefined) {
                customAlert({
                    "icon": "warning",
                    "text": "Kindly Select any type of payment"
                })
            }
        }
    }


    const handleCancelSubmit = async (_id, payType) => {
        setCancelModalDisplay('none')
        let ModalTitle, ModalDesc;
        if (payType === 'Canceled Collateral') {
            ModalTitle = "Use Collateral ";
            ModalDesc = '<p>Wire transfer details are below please confirm you would like to cancel loan with wire transfer</p>'
        } else {
            ModalTitle = "Wire Transfer ";
            ModalDesc = '<div><p>Wire transfer details are below please confirm you would like to cancel loan using Collateral</p>' +
                '<div class="flex justify-between pt-5 Sweet_cancel_modal">' +
                '<div class="right-text"><p class="font-bold">Beneficiary Name:</p><p class="font-bold">Beneficiary Address:</p><br> <p class="font-bold">Beneficiary Account Nummber:</p> <p class="font-bold">Transit Nummber:</p> <p class="font-bold">Institution Nummber:</p> <p class="font-bold">Beneficiary Bank:</p></div>' +
                '<div class="left-text"><p>Bitloc Inc</p> <p>16 Brokers Lane Apt 304, <br> Toronto Ontario M8V 0A5</p> <p>5259809</p> <p>17362</p> <p>004</p> <p>TD Canada Trust</p></div></div>' +
                '</div>'
        }
        Swal.fire({
            title: ModalTitle,
            html: ModalDesc,
            customClass: "cancel_modal",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Cancel it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const formData = new FormData();
                    formData.append('id', _id);
                    formData.append('pay_type', payType)
                    const response = await makeRequest('post', `${apiUrl}users/cancel_loan`, formData)
                        .then(response => {
                            if (response?.data?.status === 'Success') {
                                customAlert({
                                    "icon": "success",
                                    "text": "Cancelled Successfully"
                                })
                                window.location.reload();
                            } else if (response?.error) {
                                customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                            } else {
                                customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                            }
                        })
                } catch (err) {
                    customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                }
            }
        })
    }
    const chartStyle = {
        height: 200,
        width: 380,
    }
    const currentLtv = ((myLoans[activeTab]?.collateral_amount * 2) / apiValue);
    const ltvStart = myLoans[activeTab]?.ltv_start
    const percantageLtv = Math.round((myLoans[activeTab]?.borrow_amount / (ltvStart * apiValue)) * 100);
    const newLTV = "0." + (percantageLtv).toFixed(0)
    return (
        <AdminWrapper>
            {loader ?
                <div style={{height: "100vh", display: "flex", alignItems: "center"}}>
                    <img style={{height: "100px"}} className={'m-auto'} src={'/images/loading_gif.gif'} alt=""/>
                </div>
                : myLoans?.length < 1 ? <div className="dashboard-page flex bg-white">
                        <div className="page-block flex flex-col w-full">
                            <div className={'noDataFound'}>
                                <h1>No Loans Founds</h1>
                            </div>
                        </div>
                    </div> :
                    <div className="dashboard-page flex bg-white">
                        <div className="page-block flex flex-col w-full">
                            <div className="tabs-container">
                                <div className="tabs">
                                    {myLoans.map((tab, index) => (
                                        <Tab className={activeTab === index ? 'customTabs tabActive' : 'customTabs'}
                                             value={index}
                                             key={index}
                                             label={`Loan ${index + 1}`}
                                             onClick={() => handleTabClick(index)}
                                        />
                                    ))}
                                </div>
                                <div className="page-title text-black">Loans</div>
                                <div className="tab-content">
                                    <div style={{height: "290px", overflowY: "scroll"}}
                                         className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-5">
                                        <table className="w-full text-left">
                                            <thead>
                                            <tr>
                                                <th className="font-normal px-6 py-3">Billing Period</th>
                                                <th className="font-normal px-6 py-3">Payment Due</th>
                                                <th className="font-normal px-6 py-3">Date</th>
                                                <th className="font-normal px-6 py-3">Remaining Principal</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {myLoans[activeTab]?.payments?.map((item, index) => {
                                                return (
                                                    <tr key={index} className="bg-white border-b dark:border-gray-700">
                                                        <td className="px-6 py-3">{item?.billing_period}</td>
                                                        <td className="px-6 py-3">${Number(item?.payment_due).toLocaleString()}</td>
                                                        <td className="px-6 py-3">{item?.date.toString().split("T")[0]}</td>
                                                        <td className="px-6 py-3">${Number(item?.remaining_principle).toLocaleString()}</td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/*Modal For Bitcoin Payment*/}
                                    <div id="customModal" className="custommodal" style={{display: `${modalDisplay}`}}>
                                        <div className="custommodal_modal-content">
                                            <span className="close" onClick={modalHandle}
                                                  id="closeModalBtn">&times;</span>
                                            <div className={'modal_innner'}>
                                                <h2 className={'modal_title'}>Select Pay Type</h2>
                                                <div className={'payment_div'}>
                                                    <div className={'p-5'}>
                                                        <div className="btc_payment">
                                                            <h1 className={'font-bold text-xl pb-3 f'}>Paying</h1>
                                                            <p className={'ml-0 text-[26px]'}>${toPay}</p>
                                                            <p>{toPayBTC.toFixed(6)} BTC</p>
                                                        </div>
                                                    </div>
                                                    <div className={'p-5'}>
                                                        <button onClick={() => {
                                                            handleBTCPayment('Collateral')
                                                        }} className="payment_button btn-rounded mt-2.5">Use
                                                            Collateral
                                                        </button>
                                                        <button onClick={() => {
                                                            handleBTCPayment('BitLoc')
                                                        }} className="payment_button btn-rounded mt-2.5">Deposit
                                                            BitLoc
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-title text-black mt-10">Interest Payment</div>
                                    <div className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-5">
                                        <div className="mb-4">
                                            <label className="block font-normal mb-2" htmlFor="pay_type">
                                                Payment Type *
                                            </label>
                                            <div className={'flex terms_btn items-center mb-4'}>
                                                <button
                                                    className={payOption?.paid === 'Wire Transfer' ? 'active_btn' : ''}
                                                    type="button"
                                                    onClick={handlePayOption} name="paid" value="Wire Transfer">
                                                    Wire Transfer
                                                </button>
                                                <button className={payOption?.paid === 'EMT' ? 'active_btn' : ''}
                                                        type="button"
                                                        onClick={handlePayOption} name="paid" value="EMT">
                                                    EMT
                                                </button>
                                                <button
                                                    className={payOption?.paid === 'Bitcoin' ? 'active_btn' : ''}
                                                    type="button"
                                                    onClick={handlePayOption} name="paid" value="Bitcoin">
                                                    Bitcoin
                                                </button>

                                            </div>
                                            <label className={'mt-5'}>Loan payment using bitcoin will charge a 4.5%
                                                service fee.</label>
                                            <h6>
                                                Month {currentMonthPayment[0] !== undefined ?
                                                currentMonthPayment[0].date.toString().split("T")[0] :
                                                lastPaid?.date?.toString().split("T")[0]}
                                            </h6>
                                            {currentMonthPayment[0] !== undefined && currentMonthPayment[0].paid === null ?
                                                <button className="button btn-rounded mt-5" type="submit"
                                                        onClick={handlePaymentSubmit}>
                                                    Submit Payment
                                                </button> : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="page-title text-black mt-10">Loan Cancellation</div>
                                    <div className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-5">
                                        <div className={'flex'}>
                                            <div className={'w-full'}>
                                                <table className="w-full text-left">
                                                    <tbody>
                                                    <tr className="bg-white border-b dark:border-gray-700">
                                                        <td className="px-6 py-3">Beginning Of Loan Term</td>
                                                        <td className="px-6 py-3 text-end">{myLoans[activeTab]?.createdAt?.toString().split("T")[0]}</td>
                                                    </tr>
                                                    <tr className="bg-white border-b  dark:border-gray-700">
                                                        <td className="px-6 py-3">Remaining Balance</td>
                                                        <td className="px-6 py-3 text-end">${myLoans[activeTab]?.payments[11]?.payment_due}</td>
                                                    </tr>
                                                    </tbody>
                                                    <button
                                                        onClick={() => cancleModalHandle()}
                                                        className="button btn-rounded mt-5" type="submit">
                                                        Cancel Loan
                                                    </button>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/*Modal For Cancle Loan*/}
                                    <div id="customModal" className="custommodal"
                                         style={{display: `${cancelModalDisplay}`}}>
                                        <div className="custommodal_modal-content">
                                            <span className="close" onClick={() => {
                                                cancleModalHandle()
                                            }}
                                                  id="closeModalBtn">&times;</span>
                                            <div className={'modal_innner'}>
                                                <h2 className={'modal_title'}>Cancel Loan</h2>
                                                <p className={'modal_desc'}>Please Choose an option to pay final balance
                                                    on loan</p>
                                                <div className={'payment_div'}>
                                                    <div className={'p-5'}>
                                                        <div className="btc_payment">
                                                            <h1 className={'font-bold text-3xl pb-8 f'}>Paying</h1>
                                                            <p className={'ml-0 text-2xl'}>${myLoans[activeTab]?.payments[11]?.payment_due}</p>
                                                        </div>
                                                    </div>
                                                    <div className={'p-5'}>
                                                        <button
                                                            onClick={() => handleCancelSubmit(myLoans[activeTab]?._id, 'Canceled Collateral')}
                                                            className="payment_button btn-rounded mt-2.5">Use
                                                            Collateral
                                                        </button>
                                                        <button onClick={() => {
                                                            handleCancelSubmit(myLoans[activeTab]?._id, 'Canceled Wire transfer')
                                                        }} className="payment_button btn-rounded mt-2.5">Wire transfer
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-title text-black mt-10">Borrowing Information</div>
                                    <div className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-5">
                                        <div className={'flex m_flex_direction'}>
                                            <div className="chart-label-container">
                                                <div className="flex-row mb-3 mt-0">
                                                        <span style={{background: "#882727", color: "white"}}
                                                              className="inline-flex items-center rounded-md px-2 py-1 text-sm font-medium ring-1 ring-inset ring-gray-500/10">90%</span>
                                                </div>
                                                <div className="flex-row mb-3">
                                                        <span style={{background: "#435e43", color: "white"}}
                                                              className="inline-flex items-center rounded-md px-2 py-1 text-sm font-medium ring-1 ring-inset ring-gray-500/10">50%</span>
                                                </div>
                                                <div className="flex-row">
                                                        <span style={{background: "#807979", color: "white"}}
                                                              className="inline-flex items-center rounded-md px-2 py-1 text-sm font-medium ring-1 ring-inset ring-gray-500/10">25%</span>
                                                </div>
                                            </div>
                                            <div className='PieChart'>
                                                <GaugeChart id="gauge-chart5"
                                                            nrOfLevels={3}
                                                            arcsLength={[0.3, 0.5, 0.3]}
                                                            colors={["#807979", "#435e43", "#882727"]}
                                                            percent={newLTV}
                                                            arcPadding={0}
                                                            cornerRadius={0}
                                                            arcWidth={0.4}
                                                            style={chartStyle}
                                                            hideText={true}/>
                                            </div>
                                            <div className={'w-10/12 m_padding32'}>
                                                <table className="w-full text-left mt-3">
                                                    <tbody>
                                                    <tr className="bg-white border-b dark:border-gray-700">
                                                        <td className="px-6 py-3">You Borrowed</td>
                                                        <td className="px-6 py-3">${Number(myLoans[activeTab]?.borrow_amount).toLocaleString()}</td>
                                                    </tr>
                                                    <tr className="bg-white border-b  dark:border-gray-700">
                                                        <td className="px-6 py-3">Your Collateral</td>
                                                        <td className="px-6 py-3">BTC {(parseFloat(myLoans[activeTab]?.ltv_start)).toFixed(4)}</td>
                                                    </tr>
                                                    <tr className="bg-white border-b dark:border-gray-700">
                                                        <td className="px-6 py-3">Current LTV</td>
                                                        <td className="px-6 py-3">{(percantageLtv)} %</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-title text-black mt-10">Bitcoin Deposit Address</div>
                                    <p className="leading-6">This is your designated Bitloc address. <br/> Once the
                                        transaction is
                                        confirmed on the blockchain, Bitloc will initiate the loan disbursement to
                                        the
                                        bank account linked with this loan.</p>
                                    <div
                                        className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-10 mt-5 flex m_flex_direction">
                                        <input type="text" min="1" max="100" required={true}
                                               placeholder="Admin Wallet"
                                               className="mr-3 border rounded w-10/12 py-2 px-3 leading-tight"
                                               value={myLoans[activeTab].admin_wallet} readOnly={true}/>
                                        <button className="button btn-rounded w-2/12 m_search_btn"
                                                onClick={copyToClipboard}
                                                type="button">Copy
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

            }
        </AdminWrapper>
    );
};

export default Loans;
