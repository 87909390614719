import { createSlice } from "@reduxjs/toolkit";
const userToken = localStorage.getItem("_token");

const authReducer = createSlice({
  name: "authReducer",
  initialState: {
    userToken: userToken ? userToken : null,
    user: {}
  },
  reducers: {
    setUser:(state, action)=>{
      state.user = action.payload
    },
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
    logout: (state, { payload }) => {
      localStorage.removeItem(payload);
      state.userToken = null;
      state.user = null;
    },
  },
});
export const { setUserToken, logout,setUser } = authReducer.actions;
export default authReducer.reducer;
