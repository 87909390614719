import React, {useEffect, useState} from "react";
import AdminWrapper from "../../../routes/SuperAdminWrapper";
import {customAlert, apiUrl} from "../../../helpers/MyHelpers";

const AdminWallet = () => {
    let authToken = localStorage.getItem("_token")
    const [wallet, setWallet] = useState([])
    const [walletId, setWalletId] = useState([])
    const getWallet = () => {
        return fetch(apiUrl+'admin/get_wallet',{
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then(res => res.json()).then(d => d.wallet);
    }
    useEffect(() => {
        getWallet().then(data => {
            setWallet(data?.address)
            setWalletId(data?._id)
        });
    }, [])
    const saveAdminWallet = (e) => {
        e.preventDefault();
        return fetch(apiUrl+'admin/save_wallet',{
            method: 'post',
            headers: {
                'Authorization': 'Bearer '+authToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({_id:walletId, address:wallet})
        }).then(res => res.json()).then(d => {
            setWallet(d.wallet)
            customAlert({"icon": "success", "title": "Success", "text": "Wallet Saved..!"})
        });
    }
    return (
        <AdminWrapper>
            <div className="dashboard-page flex bg-white">
                <div className="page-block flex flex-col w-full">
                    <div className="page-title text-black">BitLoc Address</div>
                    <div style={{height: "70%", overflowY: "scroll"}}
                         className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-5">
                        <form className="" onSubmit={saveAdminWallet}>
                            <div className="mb-4">
                                <label className="block font-normal mb-2" htmlFor="first_name">
                                    BitLoc Address
                                </label>
                                <input onChange={(e)=>{setWallet(e.target.value)}} required={true} className="border rounded w-full py-2 px-3 leading-tight"
                                       value={wallet} name="first_name" type="text"/>
                            </div>
                            <div className="flex items-center justify-between">
                                <button className="button btn-rounded" type="submit">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </AdminWrapper>
    );
};

export default AdminWallet;
