import React from "react";
import AdminWrapper from "../../../routes/AdminWrapper";
import {useLocation} from "react-router-dom";

const MyLoanView = () => {
    const {state} = useLocation();
    let generatePayments = () => {
        let data = [];
        for (let i = 0; i < state?.payments?.length; i++) {
            data.push(
                <tr key={i}>
                    <th className="border font-normal px-6 py-3">
                        {state.payments[i].billing_period}
                    </th>
                    <th className="border font-normal px-6 py-3">
                        {state.payments[i].payment_due}
                    </th>
                    <th colSpan={2} className="border font-normal px-6 py-3">
                        {state.payments[i].date.toString().split("T")[0]}
                    </th>
                    <th colSpan={2} className="border font-normal px-6 py-3">
                        {state.payments[i].remaining_principle}
                    </th>
                </tr>
            )
        }

        return data;
    }

    return (
        <AdminWrapper>
            <div className="dashboard-page flex bg-white">
                <div className="page-block flex flex-col w-full">
                    <div className="page-title text-black">Loan Details</div>
                    <div
                        className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-5">
                        <table className="w-full text-left">
                            <tbody>
                            <tr>
                                <th className="border font-normal px-6 py-3">Borrow Amount</th>
                                <td className="border px-6 py-3">{state.borrow_amount}</td>
                                <th className="border font-normal px-6 py-3">Term Length</th>
                                <td className="border px-6 py-3">{state.term_length}</td>
                                <th className="border font-normal px-6 py-3">Loan Type</th>
                                <td className="border px-6 py-3">{state.loan_type}</td>
                            </tr>
                            <tr>
                                <th className="border font-normal px-6 py-3">Category</th>
                                <td className="border px-6 py-3">{state.category}</td>
                                <th className="border font-normal px-6 py-3">Use Of Funds</th>
                                <td className="border px-6 py-3">{state.use_of_funds}</td>
                                <th className="border font-normal px-6 py-3">Status</th>
                                <td className="border px-6 py-3">Pending Approval</td>
                            </tr>
                            <tr>
                                <th colSpan={6} className="border font-normal px-6 py-5">
                                    <h2 className="font-normal text-xl float-left">Payments Information</h2>
                                </th>
                            </tr>
                            <tr>
                                <th className="border font-normal px-6 py-3">Billing Period</th>
                                <th className="border font-normal px-6 py-3">Payment Due</th>
                                <th colSpan={2} className="border font-normal px-6 py-3">Date</th>
                                <th colSpan={2} className="border font-normal px-6 py-3">Remaining Principle</th>
                            </tr>
                            {generatePayments()}
                            </tbody>
                        </table>
                        <div className="flex items-center justify-between p-3">
                            <button className="button btn-rounded" type="submit">
                                Submit Loan Details
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </AdminWrapper>
    );
};
export default MyLoanView;
