import React, {useEffect, useState} from "react";
import AdminWrapper from "../../../routes/AdminWrapper";
import makeRequest from "../../../helpers/axiosRequest";
import {apiUrl, customAlert} from "../../../helpers/MyHelpers";
import {useDispatch, useSelector} from "react-redux";
import LoanCalculator from "../../../components/LoanCalculator";

const ApplyForLoan = () => {
    const {user} = useSelector(state => state.authReducer)
    const [useOfFunds, setUseOfFunds] = useState(false);
    const [apiValue, setApiValue] = useState('');
    const [state, setState] = useState({
        collateral_amount: "",
        borrow_amount: "",
        term_length: "",
        loan_type: "",
        use_of_funds: "",
        ltv_start: "",
        category: "Investment",
        currency_type: "CAD",
        wallet_group: user?.wallet_groups[0]?._id,
        bank: user?.banks[0]?._id,
    });
    useEffect(() => {
        GetPrices()
    }, [])
    const GetPrices = () => {
        fetch("https://blockchain.info/ticker")
            .then((res) => res.json())
            .then((data) => {
                setApiValue(data.CAD.last)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const handleInputs = (e) => {
        setState({...state, [e.target.name]: e.target.value})
        if (e.target.name === "borrow_amount") {
            setState(prevState => ({
                ...prevState,
                collateral_amount: e.target.value,
                ltv_start: ((e.target.value * 2) / apiValue)
            }));
        }
        if (e.target.name === "category") {
            if (e.target.value === "Other") {
                setUseOfFunds(true);
            } else {
                setUseOfFunds(false);
            }
        }

    };
    const dispatch = useDispatch();
    const loanSubmit = async (e) => {
        e.preventDefault()
        try {
            if (state?.borrow_amount < 1000) {
                customAlert({"icon": "error", "title": "Error", "text": "Minimum Loan $1000 allowed"})
                return;
            }
            if (!state?.term_length && state?.term_length === "") {
                customAlert({"icon": "error", "title": "Error", "text": "Terms of Length is Required"})
                return;
            }
            if (!state?.loan_type && state?.loan_type === "") {
                customAlert({"icon": "error", "title": "Error", "text": "Loan Type is Required"})
                return;
            }
            const response = await makeRequest('post', `${apiUrl}users/apply_loan/${user?._id}`, state)
                .then(response => {
                    if (response?.data == 'Success') {
                        customAlert({
                            "icon": "success",
                            "title": "Success",
                            "text": "Loan Applied"
                        })
                    } else if (response?.error) {
                        customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                    } else {
                        customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                    }
                })
            setState({
                borrow_amount: "",
                term_length: "",
                loan_type: "",
                use_of_funds: "",
                category: ""
            });
        } catch (error) {
            console.error(error);
        }
    }
    const wallets = () => {
        if (user?.wallet_groups !== undefined) {
            return (
                <div className="mb-4">
                    <label className="block font-normal mb-2" htmlFor="wallet_group">
                        Wallet * </label>
                    <select required={true} onChange={handleInputs}
                            className="block w-full bg-white border border-gray-200 py-2 px-4 rounded"
                            name="wallet_group">
                        {
                            user.wallet_groups.map((item, i) => {
                                return <option value={item._id}>{item.group_name}</option>
                            })
                        }
                    </select>
                </div>
            )
        }
    }
    const banks = () => {
        if (user?.banks !== undefined) {
            return (
                <div className="mb-4">
                    <label className="block font-normal mb-2" htmlFor="bank">
                        Bank * </label>
                    <select required={true} onChange={handleInputs}
                            className="block w-full bg-white border border-gray-200 py-2 px-4 rounded"
                            name="bank">
                        <option disabled={true}>Please Select Bank</option>
                        {
                            user.banks.map((item, i) => {
                                return <option value={item._id}>{item.account_number}</option>
                            })
                        }
                    </select>
                </div>
            )
        }
    }
    return (
        <AdminWrapper>
            {
                user?.approved && user?.approved === true ?
                    <div className="dashboard-page flex bg-white">
                        <div className="page-block flex flex-col w-full">
                            <div className="page-title text-black">Apply For Loan</div>
                            <div className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-10">
                                <form className="" onSubmit={loanSubmit}>
                                    <div className="mb-4">
                                        <label className="block font-normal mb-2" htmlFor="borrow_amount">
                                            Borrow Amount *
                                        </label>
                                        <input onChange={handleInputs} required={true}
                                               className="border rounded w-full py-2 px-3 leading-tight"
                                               value={state?.borrow_amount} name="borrow_amount" type="number"/>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block font-normal mb-2" htmlFor="category">
                                            Currency* </label>
                                        <select required={true} onChange={handleInputs}
                                                className="block w-full bg-white border border-gray-200 py-2 px-4 rounded"
                                                name="currency_type">
                                            <option value={'CAD'}>CAD</option>
                                            <option value={'USD'}>USD</option>
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block font-normal mb-2" htmlFor="term_length">
                                            Term Length (months) *
                                        </label>
                                        <div className={'flex terms_btn'}>
                                            <button className={state?.term_length === '12' ? 'active_btn' : ''}
                                                    type="button" onClick={handleInputs} name="term_length"
                                                    value="12">12
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block font-normal mb-2" htmlFor="loan_type">
                                            Loan Type *
                                        </label>
                                        <div className={'flex terms_btn'}>
                                            <button className={state.loan_type === 'Personal' ? 'active_btn' : ''}
                                                    type="button"
                                                    onClick={handleInputs} name="loan_type" value="Personal">
                                                Personal
                                            </button>
                                            <button className={state.loan_type === 'Business' ? 'active_btn' : ''}
                                                    type="button"
                                                    onClick={handleInputs} name="loan_type" value="Business">
                                                Business
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block font-normal mb-2" htmlFor="category">
                                            Use Of Loan * </label>
                                        <select required={true} onChange={handleInputs}
                                                className="block w-full bg-white border border-gray-200 py-2 px-4 rounded"
                                                name="category">
                                            <option value={'Investment'}>Investment</option>
                                            <option value={'Personal Loan'}>Personal Loan</option>
                                            <option value={'Major Purchase'}>Major Purchase</option>
                                            <option value={'Other'}>Other</option>
                                        </select>
                                    </div>
                                    {wallets()}
                                    {banks()}

                                    {useOfFunds ? <div className="mb-4">
                                        <label className="block font-normal mb-2" htmlFor="use_of_funds">
                                            Use Of Funds * </label>
                                        <textarea onChange={handleInputs} required={true}
                                                  className="border rounded w-full py-2 px-3 leading-tight"
                                                  value={state.use_funds} name="use_of_funds" rows={'10'}/>
                                    </div> : ''}
                                    <div className="flex items-center justify-between">
                                        <button className="button btn-rounded" type="submit">
                                            Submit Application
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="mt-8 page-title text-black">Loan Calculator</div>
                            <div
                                className="bitcoin-backed-loans-panel bg-white h-screen flex flex-col rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-10">
                                <div className="loan-calculator-section-panel flex bg-white">
                                    <div className="wrap
                              flex flex-col">
                                        <LoanCalculator/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="dashboard-page flex bg-white">
                        <div className="page-block flex flex-col w-full">
                            <div
                                className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-10 h-1/2 flex items-center justify-center font-normal">
                                <h1>User info is waiting to be confirmed</h1>
                            </div>
                        </div>
                    </div>
            }
        </AdminWrapper>
    );
};

export default ApplyForLoan;
