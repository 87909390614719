import React from 'react';

export const WalletTabs = ({walletGroups, setFormData}) => {
    return (
        <div>
            {
                walletGroups.map((item, i) => {
                    return <button  key={i} type="button" onClick={()=>{setFormData(item)}} className="button btn-rounded float-right ml-2">{item.group_name}</button>
                })
            }
            {
                walletGroups.length>0 ? <button key={99} type="button" onClick={()=>{setFormData(null)}} className="button btn-rounded float-right ml-2">Add New</button> : null
            }
        </div>
    );
};
