import React, { useState, useEffect } from "react";
import Select from "react-select";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { useNavigate, Link } from "react-router-dom";
import PagesMenuSidebar from "../components/PagesMenuSidebar";

const Wrapper = ({ children }) => {
  const navigate = useNavigate();
  const customStyles = {
    control: (base, state) => ({
      ...base,
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#72AA8F" : isSelected ? "#72AA8F" : undefined,
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      marginTop: 8,
      fontSize: "14px",
      fontWeight: 400,
      background: "#FAA334",
    }),
    menuList: (base) => ({
      ...base,
      fontSize: "14px",
      fontWeight: 400,
    }),
  };

  const dropDownList = [
    { value: "/terms/TermsofService", label: "Terms Of Service" },
    { value: "/terms/PrivacyPolicy", label: "Privacy Policy" },
    { value: "/terms/RiskDisclosure", label: "Risk Disclosure" },
  ];
  const handleReceiveIn = (e) => {
    navigate(e.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={`terms-dashboard-page flex flex-col`}>
      <div className="pages-block flex flex-col relative h-full">
        <Header />
        <Sidebar />
        <PagesMenuSidebar />
        <section className="terms-pages ml-[200px] flex flex-col">
          <div className="pages-selection-dropdown w-full">
            <Select
              onChange={handleReceiveIn}
              styles={customStyles}
              options={dropDownList}
              className="select w-full"
              placeholder="Receive in"
            />
          </div>
          {children}
        </section>
        <Footer />
      </div>
    </div>
  );
};
export default Wrapper;
