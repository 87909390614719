import React, {useState} from "react";
import AdminWrapper from "../../../routes/AdminWrapper";
import {BankForm} from "../../../components/BankForm/bankForm";
import {BankTabs} from "../../../components/BankForm/bankTabs";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../../../store/reducers/authReducer";

const BankingInfo = () => {
    const {user} = useSelector(state => state.authReducer)
    const dispatch = useDispatch();
    const [formData, setFormData] = useState([]);
    const setFormDataFromInside = (data) => {
        setFormData(data)
    }
    const setUserData = (data) => {
        dispatch(setUser(data));
    }
    return (
        <AdminWrapper>
            <div className="dashboard-page flex bg-white">
                <div className="page-block flex flex-col w-full">
                    <div className="page-title text-black">Enter Your Banking Information</div>
                    <BankTabs banks={user?.banks} setFormData={setFormDataFromInside}/>
                    <div className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-10 mt-3">
                        <BankForm formData={formData} setUserData={setUserData}/>
                    </div>
                </div>
            </div>
        </AdminWrapper>
    );
};

export default BankingInfo;
