import React from "react";
import { Link } from "react-router-dom";
import Wrapper from "../../routes/Wrapper";

const Main = () => {
  const devicesList = [
    {
      tag: "Canadian-Based Trust and Protection:",
      desc: "As a Canadian-based company, BitLoc operates under the Federal Laws of Canada, ensuring the highest standards of consumer protection and data privacy. Your security is our top priority.",
    },
    {
      tag: "No Rehypothecation:",
      desc: "We firmly believe in maintaining the integrity of your assets and protecting your investments. Bitloc prioritizes the security and trust of our users. We want to assure you that we do not rehypothecate user funds. Your assets remain securely held and are never used for any purposes other than the agreed upon loan.",
    },
    {
      tag: "No Third Party:",
      desc: "We don't rely on third-party custodians. By taking custody of your Bitcoin, we ensure that your assets are protected and readily available when needed. This eliminates the reliance on third-party custodians and provides you with peace of mind knowing that your Bitcoin is in the hands of a trusted and dedicated partner throughout the loan process. Your financial security and satisfaction are our utmost priorities.",
    },
  ];
  return (
    <Wrapper>
      <div className="lading-page bg-[#fff] h-screen flex flex-col">
        <div className="wrap wrapWidth flex flex-col">
          <div className="hero-sec flex">
            <div className="hero-left flex items-center">
              <div className="meta flex flex-col">
                <h1 className="slug">Your Bitcoin Line Of Credit</h1>
                <h4 className="subtitlehome">
                  Get money for your Bitcoin without selling your Bitcoin
                </h4>
                <p className="desc">
                  Welcome to BitLoc, the leading Canadian-based financial
                  technology company that empowers users to unlock the potential
                  of their bitcoin holdings, providing secure and transparent
                  bitcoin-backed lending services. With our innovative
                  solutions, you can confidently access immediate capital,
                  leverage your assets, and move forward with your financial
                  goals while fostering trust in the bitcoin space.
                </p>
                <Link to="/auth/register">
                  <button className="btn-started button">Get Started</button>
                </Link>
              </div>
            </div>
            <div className="hero-right flex items-center justify-center">
              <img src="/images/h2.jpg" className="hero-img" />
            </div>
          </div>
        </div>
        <div className="learn-more-sec flex">
          <div className="wrap wrapWidth flex">
            <div className="more-learn-meta flex items-center">
              <div className="coin-img">
                <img src="/images/h4.png" className="img" />
              </div>
              <div className="meta flex flex-col">
                <h1 className="sec-slug">
                  Interested in learning more? Dive deeper into BitLoc's secure
                  Bitcoin-backed loans.
                </h1>
                <p className="sec-desc">
                  Curious to explore further? Take a deep dive into the world of
                  BitLoc's secure Bitcoin-backed loans and discover a trusted
                  platform that empowers you to access funds while safeguarding
                  your valuable BTC holdings.
                </p>
                <Link to="/bitcoinBackedLoans">
                  <button className="btn-more button">Learn More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="wrap wrapWidth flex">
          <div className="bit-coin-tv-sec flex items-center">
            <div
              className="tv-right flex items-stretch h-full justify-start"
              style={{ backgroundImage: `url(/images/h6.jpg)` }}
            ></div>
            <div className="tv-left flex">
              <div className="bitcoins-tv-info-list flex-col m-4">
                {devicesList.map((item, index) => (
                  <div key={index} className="info-item flex">
                    <div className="info-meta flex flex-col">
                      <h1 className="title">{item.tag}</h1>
                      <p className="desc">{item.desc}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="numbers-sec flex">
          <div className="wrap wrapWidth flex items-center justify-center">
            <div className="numbs-items flex items-center justify-between w-full">
              <div className="numb-item flex flex-col">
                <h1 className="numb">15% APR</h1>
                <p className="desc">
                  BitLoc offers a competitive 15% Annual Percentage Rate{" "}
                </p>
              </div>
              <div className="numb-item flex flex-col">
                <h1 className="numb">250k</h1>
                <p className="desc">
                  Borrowers can access loans of up to $250,000 through BitLoc
                </p>
              </div>
              <div className="numb-item flex flex-col">
                <h1 className="numb">10 BTC</h1>
                <p className="desc">
                  BitLoc has collateralized over 10 BTC to it's borrowers
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="handBitcoin-more-sec flex ">
          <div className="wrap wrapWidth md:flex h-full py-10 ">
            <div className="points md:flex md:flex-col justify-between lg:w-1/3 lg:min-w-[30%] mr-10 mb-6 md:mb-0">
              <div className="block flex items-center">
                <img
                  src="/images/check.svg"
                  alt="Based In Canada<"
                  className="h-8 w-8 text-white mr-4"
                />
                <h3 className="text-[22px] text-white">Based In Canada</h3>
              </div>
              <div className="block flex items-center">
                <img
                  src="/images/check.svg"
                  alt="No Rehypothecation"
                  className="h-8 w-8 text-white mr-4"
                />
                <h3 className="text-[22px] text-white">No Rehypothecation</h3>
              </div>
              <div className="block flex items-center">
                <img
                  src="/images/check.svg"
                  alt="Bitcoin Only"
                  className="h-8 w-8 text-white mr-4"
                />
                <h3 className="text-[22px] text-white">Bitcoin Only</h3>
              </div>
            </div>
            <div className="handBitcoin-learn-meta flex flex-col min-w-[70%] items-center">
              <div className="meta flex flex-col">
                <h1 className="sec-slug">
                  Simplified Access to Financial Freedom
                </h1>
                <p className="sec-desc">
                  Joining BitLoc is effortless - sign up today and experience
                  the ease of unlocking your financial potential with our
                  user-friendly platform. Whether you're a seasoned investor or
                  new to the world of Bitcoin-backed loans, we're here to guide
                  you every step of the way. Start your journey towards
                  financial freedom with BitLoc now.
                </p>
                <button className="btn-more button">
                  <Link to="/auth/register">Learn More</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Main;
