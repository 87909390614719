import React from "react"
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../store/reducers/authReducer";

export const HeaderButtons = (props) => {
    const {user} = useSelector(state => state.authReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClick = (data)=> {
        navigate('/admin/loandetails', { state: { ...data } });
    }
    const userLogout = () => {
        dispatch(logout("_token"));
    };

    if(user?._id != undefined) {
        return (
            <div className="actions flex items-center">
                <Link to={user.isAdmin ? "/admin/users" : "/user/personalinfo"} className="btn-login button">
                    Dashboard
                </Link>
                <Link to="/auth/login" onClick={userLogout} className="btn-login button">
                    Logout
                </Link>
            </div>
        )
    } else {
        return (
            <div className="actions flex items-center">
                <Link to="/auth/register" className="btn-login button">
                    Sign Up
                </Link>
                <Link to="/auth/login" className="btn-login button">
                    Log In
                </Link>
            </div>
        );
    }
}
