import {Link} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";

export let GoogleAuth2Fa = () => {
    const {user} = useSelector(state => state.authReducer)
    let authenticatorText = "Enable 2FA";
    if(user?.auth2FaEnabled === true){
        authenticatorText = "Disable 2FA"
    }
    return(
        <Link to={'/user/2fa'} className="button btn-rounded float-left" type="button">
            {authenticatorText}
        </Link>
    )
}
