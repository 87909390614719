import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Public from "./Public";
import Login from "../Pages/Login";
import Registration from "../Pages/Registration";
import SmsVerification from "../Pages/Registration/SmsVerification";
import AccountSuccess from "../Pages/Registration/AccountSuccess";
import Home from "../Pages/Home";
import AboutUs from "../Pages/AboutUs";
import BitcoinChart from "../Pages/BitcoinChart";
import BitcoinBackedLoans from "../Pages/BitcoinBackedLoans";
import Security from "../Pages/Security";
import Careers from "../Pages/Careers";
import ContactUs from "../Pages/ContactUs";
import Blogs from "../Pages/Blogs";
import BlogDetail from "../Pages/Blogs/blogDetail"
import RiskManagement from "../Pages/RiskManagement";
import FAQs from "../Pages/FAQs";
import ForgotPassword from "../Pages/ForgotPassword";
import ResetPassword from "../Pages/ResetPassword";
import { TermsofService, RiskDisclosure, PrivacyPolicy } from "../Pages/Terms";
// User Dashboard Pages
import PrivateUser from "./PrivateUser";
import BankingInfo from "../Pages/User/BankingInfo";
import WalletAddress from "../Pages/User/WalletAddress";
import PersonalInfo from "../Pages/User/PersonalInfo";
import IdentityVideo from "../Pages/User/IdentityVideo";
import Loans from "../Pages/User/Loans";
import ApplyForLoan from "../Pages/User/ApplyForLoan";
import MyLoanView from "../Pages/User/Loans/MyLoanView";

import Users from "../Pages/Admin/Users";
import LoansAdmin from "../Pages/Admin/Loans";
import UserView from "../Pages/Admin/Users/view";
import LoanView from "../Pages/Admin/Loans/view";
import PrivateAdmin from "./PrivateAdmin";
import AdminWallet from "../Pages/Admin/AdminWallet";
import PendingLoan from "../Pages/User/PendingLoan";
import ChangePassword from "../Pages/User/ChangePassword";
import AdminChangePassword from "../Pages/Admin/AdminChangePassword";
import GoogleAuth2FaPage from "../Pages/GoogleAuth2Fa";

const Routing = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Public>
                            <Home />
                        </Public>
                    }
                />
                <Route
                    path="/aboutus"
                    element={
                        <Public>
                            <AboutUs />
                        </Public>
                    }
                />
                <Route
                    path="/bitcoinBackedLoans"
                    element={
                        <Public>
                            <BitcoinBackedLoans />
                        </Public>
                    }
                />
                <Route
                    path="/security"
                    element={
                        <Public>
                            <Security />
                        </Public>
                    }
                />
                <Route
                    path="/careers"
                    element={
                        <Public>
                            <Careers />
                        </Public>
                    }
                />
                <Route
                    path="/contactus"
                    element={
                        <Public>
                            <ContactUs />
                        </Public>
                    }
                />
                <Route
                    path="/blogs"
                    element={
                        <Public>
                            <Blogs />
                        </Public>
                    }
                />
                <Route
                    path="/blogs-detail/:id"
                    element={
                        <Public>
                            <BlogDetail />
                        </Public>
                    }
                />
                <Route
                    path="/riskManagement"
                    element={
                        <Public>
                            <RiskManagement />
                        </Public>
                    }
                />
                <Route
                    path="/bitcoinchart"
                    element={
                        <Public>
                            <BitcoinChart />
                        </Public>
                    }
                />
                <Route
                    path="/faqs"
                    element={
                        <Public>
                            <FAQs />
                        </Public>
                    }
                />
                <Route path="auth">
                    <Route
                        path="login"
                        element={
                            <Public>
                                <Login />
                            </Public>
                        }
                    />
                    <Route
                        path="register"
                        element={
                            <Public>
                                <Registration />
                            </Public>
                        }
                    />
                    <Route
                        path="sms-verification"
                        element={
                            <Public>
                                <SmsVerification />
                            </Public>
                        }
                    />
                    <Route
                        path="accountSuccess"
                        element={
                            <Public>
                                <AccountSuccess />
                            </Public>
                        }
                    />
                    <Route
                        path="forgotPassword"
                        element={
                            <Public>
                                <ForgotPassword />
                            </Public>
                        }
                    />
                    <Route
                        path="resetPassword/:id"
                        element={
                            <Public>
                                <ResetPassword />
                            </Public>
                        }
                    />
                </Route>
                <Route path="terms">
                    <Route
                        path="TermsofService"
                        element={
                            <Public>
                                <TermsofService />
                            </Public>
                        }
                    />
                </Route>

                <Route path="terms">
                    <Route
                        path="RiskDisclosure"
                        element={
                            <Public>
                                <RiskDisclosure />
                            </Public>
                        }
                    />
                </Route>
                <Route path="terms">
                    <Route
                        path="PrivacyPolicy"
                        element={
                            <Public>
                                <PrivacyPolicy />
                            </Public>
                        }
                    />
                </Route>

                <Route path="user">
                    <Route
                        path="personalinfo"
                        element={
                            <PrivateUser>
                                <PersonalInfo />
                            </PrivateUser>
                        }
                    />
                    <Route
                        path="2fa"
                        element={
                            <PrivateUser>
                                <GoogleAuth2FaPage />
                            </PrivateUser>
                        }
                    />
                    <Route
                        path="changepassword"
                        element={
                            <PrivateUser>
                                <ChangePassword />
                            </PrivateUser>
                        }
                    />
                    <Route
                        path="bankinginfo"
                        element={
                            <PrivateUser>
                                <BankingInfo />
                            </PrivateUser>
                        }
                    />
                    <Route
                        path="walletaddress"
                        element={
                            <PrivateUser>
                                <WalletAddress />
                            </PrivateUser>
                        }
                    />
                    <Route
                        path="idconfimation"
                        element={
                            <PrivateUser>
                                <IdentityVideo />
                            </PrivateUser>
                        }
                    />
                    <Route
                        path="loans"
                        element={
                            <PrivateUser>
                                <Loans />
                            </PrivateUser>
                        }
                    />
                    <Route
                        path="applyforloan"
                        element={
                            <PrivateUser>
                                <ApplyForLoan />
                            </PrivateUser>
                        }
                    />
                    <Route
                        path="myloandetails"
                        element={
                            <PrivateUser>
                                <MyLoanView />
                            </PrivateUser>
                        }
                    />
                    <Route
                        path="pendingloans"
                        element={
                            <PrivateUser>
                                <PendingLoan />
                            </PrivateUser>
                        }
                    />
                </Route>
                <Route path="admin">
                    <Route
                        path="users"
                        element={
                            <PrivateAdmin>
                                <Users />
                            </PrivateAdmin>
                        }
                    />
                    <Route
                        path="userdetails"
                        element={
                            <PrivateAdmin>
                                <UserView />
                            </PrivateAdmin>
                        }
                    />
                    <Route
                        path="loans"
                        element={
                            <PrivateAdmin>
                                <LoansAdmin />
                            </PrivateAdmin>
                        }
                    />
                    <Route
                        path="loandetails"
                        element={
                            <PrivateAdmin>
                                <LoanView />
                            </PrivateAdmin>
                        }
                    />
                    <Route
                        path="wallet"
                        element={
                            <PrivateAdmin>
                                <AdminWallet />
                            </PrivateAdmin>
                        }
                    />
                    <Route
                        path="change_password"
                        element={
                            <PrivateAdmin>
                                <AdminChangePassword />
                            </PrivateAdmin>
                        }
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Routing;
