import React, {useState} from "react";
import {NavLink} from "react-router-dom";

const DashboardSidebar = () => {
    const sidebarMenuList = [
        {lbl: "Users", link: "/admin/users"},
        {lbl: `Loans`, link: "/admin/loans"},
        {lbl: `Change Password`, link: "/admin/change_password"},
        // { lbl: `Admin Wallet`, link: "/admin/wallet" },
    ];
    const [isOpen, setIsOpen] = useState(false);
    const [closeBtn, setCloseBtn] = useState(false);
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
        setCloseBtn(!closeBtn)
    };
    return (
        <>
            <div className={'dashboard_sidebar_btn cursor-pointer'} onClick={() => {
                toggleSidebar()
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" fill="none" viewBox="0 0 16 11">
                    <path fill="#000"
                          d="M0 .782C0 .575.084.376.234.23A.81.81 0 01.8 0h14.4a.81.81 0 01.566.23.773.773 0 010 1.105.81.81 0 01-.566.23H.8a.81.81 0 01-.566-.23A.773.773 0 010 .782zM0 5.5c0-.207.084-.406.234-.553a.81.81 0 01.566-.23h14.4a.81.81 0 01.566.23.773.773 0 010 1.106.81.81 0 01-.566.23H.8a.81.81 0 01-.566-.23A.773.773 0 010 5.5zm.8 3.936a.81.81 0 00-.566.229.773.773 0 000 1.106A.81.81 0 00.8 11h14.4a.81.81 0 00.566-.23.773.773 0 000-1.105.81.81 0 00-.566-.23H.8z"></path>
                </svg>
            </div>
            <div className={`dashboard_sidebar_btn_close cursor-pointer hidden ${closeBtn ? 'active' : ''}`}
                 onClick={() => {
                     toggleSidebar()
                 }}>
                X
            </div>
            <div className={`dashboard-sidebar ${isOpen ? 'active' : ''} absolute`}>
                <div className={`side-block flex flex-col anim`}>
                    {sidebarMenuList.map((item, index) => (
                        <NavLink key={index} to={item.link} className="nave-link">
                            {item.lbl}
                        </NavLink>
                    ))}
                </div>
            </div>
        </>
    );
};

export default DashboardSidebar;
