import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom";
import {apiUrl, customAlert} from "../../helpers/MyHelpers";
import Swal from "sweetalert2";
import makeRequest from "../../helpers/axiosRequest";

export const LoansDataTable = (props) => {
    let authToken = localStorage.getItem("_token");
    const navigate = useNavigate();
    const [loans, setLoans] = useState([])
    const [loader, setLoader] = useState(true)
    const getLoans = () => {
        return fetch(apiUrl + 'admin/get_loans', {
            headers: {
                'Authorization': 'Bearer ' + authToken
            }
        }).then(res => res.json()).then(d => d.loans);
    }
    useEffect(() => {
        getLoans().then(data => {
            setLoans(data);
            setLoader(false)
        });
    }, [])
    const handleClick = (data) => {
        navigate('/admin/loandetails', {state: {...data}});
    }
    const deleteLoan = async (id) => {
        Swal.fire({
            title: 'Do you really want to delete this Loan and related payments?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let data = new FormData();
                data.append('id', id);
                try {
                    const response = await makeRequest('post', `${apiUrl}admin/delete_loan`, data)
                        .then(response => {
                            if (response?.data?.status === 'Success') {
                                customAlert({
                                    "icon": "success",
                                    "text": "Loan Deleted"
                                })
                            } else if (response?.error) {
                                customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                            } else {
                                customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                            }
                        })
                } catch (err) {
                    customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                }
            }
        })
    }
    return (
        <>
            {
                loader === true ?
                    <div style={{height: "60vh", display: "flex", alignItems: "center"}}>
                        <img style={{height: "100px"}} className={'m-auto'} src={'/images/loading_gif.gif'} alt=""/>
                    </div>
                    :
                    <div style={{height: "70%", overflowY: "scroll"}}
                         className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-5">
                        <table className="w-full text-left">
                            <thead>
                            <tr>
                                <th className="font-normal px-6 py-3">Full Name</th>
                                <th className="font-normal px-6 py-3">Amount</th>
                                <th className="font-normal px-6 py-3">Term</th>
                                <th className="font-normal px-6 py-3">Type</th>
                                <th className="font-normal px-6 py-3">Category</th>
                                <th className="font-normal px-6 py-3">Use of Funds</th>
                                <th className="font-normal px-6 py-3">Status</th>
                                <th className="font-normal px-6 py-3">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loans.map((item, key) => {
                                    return (
                                        <tr key={key} className="bg-white border-b  dark:border-gray-700">
                                            <td className="px-6 py-3">{item?.user?.first_name + " " + item.user?.last_name}</td>
                                            <td className="px-6 py-3">${Number(item.borrow_amount).toLocaleString()}</td>
                                            <td className="px-6 py-3">{item.term_length}</td>
                                            <td className="px-6 py-3">{item.loan_type}</td>
                                            <td className="px-6 py-3">{item.category}</td>
                                            <td className="px-6 py-3">{item.use_of_funds}</td>
                                            <td className="px-6 py-3">{item.approved ? item.approved : "Pending Approval"}</td>
                                            <td className="px-6 py-3 flex">
                                                <img className={'user_view'} onClick={() => {
                                                    handleClick(item)
                                                }} src={require('../../assets/images/eye.png')} alt={''}/>
                                                <img className={'user_delete'} onClick={() => {
                                                    deleteLoan(item._id)
                                                }} src={require('../../assets/images/delete.png')} alt={''}/>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
            }
        </>
    );
}
