import {useState} from "react";
import {useNavigate, Link} from "react-router-dom";
import Wrapper from "../../routes/Wrapper";
import {apiUrl, customAlert} from "../../helpers/MyHelpers";
import makeRequest from "../../helpers/axiosRequest";
import {setUser} from "../../store/reducers/authReducer";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const handleInputs = (e) => {
        setEmail(e.target.value);
    };

    const ForgotPassFunction  = async (e) => {
        e.preventDefault();
        try {
            if (email) {
                const formData = new FormData(e.target);
                const response = await makeRequest('post', `${apiUrl}auth/forgot-password`, formData)
                    .then(response => {
                        if (response?.data?.status === 'Success') {
                            customAlert({
                                "icon": "success", "title": "Success", "text": "Email has been delivered to you."
                            })
                            setEmail('')
                        } else if (response?.error) {
                            customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                        } else {
                            customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                        }
                    }).catch(error => {
                        customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                    })
            } else {
                customAlert({
                    "icon": "error",
                    "title": "Error",
                    "text": "Email Required"
                })
            }
        } catch (error) {
            customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
        }
    };

    return (
        <Wrapper>
            <div className="login-page bg-white h-screen flex justify-center w-full">
                <div className="wrap wrapWidth flex flex-col justify-center">
                    <h3 className="page-title">Reset Password</h3>
                    <form
                        className="form-block flex flex-col"
                        onSubmit={ForgotPassFunction}>
                        <div className="input-field flex flex-col">
                            <h1 className="field-tag">Email *</h1>
                            <input
                                type="email"
                                name="email"
                                className="txt cleanbtn"
                                onChange={handleInputs}
                                value={email}
                                placeholder="Enter email..."
                            />
                        </div>
                        <div className="mb-4 flex items-center justify-start hover:text-themeColor anim">
                            <Link to="/auth/login" className="">
                                Back to Login?
                            </Link>
                        </div>
                        <div className="actions flex items-center">
                            <input
                                type="submit"
                                value={ "Reset Password"}
                                className="btn-login button"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </Wrapper>
    );
};

export default ForgotPassword;
