import { useState } from "react";

export const Toggle = ({value, index, handleChange})=> {
    const [enabled, setEnabled] = useState(value);

    return (
        <div className="flex">
            <label className="inline-flex relative items-center mr-5 cursor-pointer">
                <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={enabled}
                    readOnly
                />
                <div className="w-11 h-6 bg-red-600 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-red-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                     onClick={ () => { handleChange(!enabled, index); setEnabled(!enabled); } }></div>
            </label>
        </div>
    );
}


/*
w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-red-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600
 */
