import React, {useState} from "react";
import {useUserLoginMutation, useUserVerifyOtpCallMutation} from "../../store/services/authService";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, Link} from "react-router-dom";
import {setUser, setUserToken} from "../../store/reducers/authReducer";
import Wrapper from "../../routes/Wrapper";
import {customAlert} from "../../helpers/MyHelpers";
const Login = () => {
    const [showOtp, setShowOtp] = useState(false);
    const [otpUser, setOtpUser] = useState(false);
    const {user} = useSelector(state => state.authReducer);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [login,response] = useUserLoginMutation();
    const [verifyOtpCall, responseOtp] = useUserVerifyOtpCallMutation();
    const [state, setState] = useState({
        email: "",
        password: "",
    });
    if (user?._id != undefined) {
        if (user.isAdmin) {
            navigate("/admin/users");
        } else {
            navigate("/user/personalinfo");
        }
    }
    const handleInputs = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    };

    const adminLoginFunction = (e) => {
        e.preventDefault();
        login(state).then((response) => {
            if (response.data?.status == "Success") {
                if(response.data?.result?.user?.auth2FaEnabled === true) {
                    setShowOtp(true)
                    setOtpUser(response.data?.result?.user)
                    return
                } else {
                    dispatch(setUserToken(response.data?.result?.accessToken));
                    dispatch(setUser(response.data?.result?.user));
                    if (response.data?.result?.user?.isAdmin == false) {
                        navigate("/user/personalinfo");
                    } else {
                        navigate("/admin/users");
                    }
                    localStorage.setItem("_token", response.data?.result?.accessToken);
                }
            } else if (response.data?.status == "Failure") {
                customAlert({"icon": "error", "title": "Error", "text": response.data?.result})
            } else if (response.data?.status == "OTPFailure") {
                navigate("/auth/sms-verification",{state: {...response.data?.result?.user}});
            } else {
                customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
            }
        });
    };
    const verifyOtp = async (e) => {
        e.preventDefault();
        let formData = new FormData(e.target)
        let data = {
            id:otpUser._id,
            code:formData.get('code'),
        }
        verifyOtpCall(data).then((responseOtp) => {
            if (responseOtp?.data?.status == 'Success') {
                dispatch(setUserToken(responseOtp.data?.result?.accessToken));
                dispatch(setUser(responseOtp.data?.result?.user));
                if (responseOtp.data?.result?.user?.isAdmin == false) {
                    navigate("/user/personalinfo");
                } else {
                    navigate("/admin/users");
                }
                localStorage.setItem("_token", responseOtp.data?.result?.accessToken);
            } else if (responseOtp?.error) {
                customAlert({"icon": "error", "title": "Error", "text": responseOtp?.error?.data})
            } else {
                customAlert({"icon": "error", "title": "Unable to Verify OTP", "text": "Try again"})
            }
        })
    };
    let showForm = () => {
        if(showOtp) {
            return (
                <form className="form-block flex flex-col" onSubmit={verifyOtp}>
                    <h2>2 Factor Authentication Enabled</h2>
                    <div className="input-field flex flex-col">
                        <label className="block font-normal mb-2">
                            Enter OTP Code
                        </label>
                    </div>
                    <div className="input-field flex flex-col">
                        <input name={'code'} placeholder={'Code'} className="border rounded w-full py-2 px-3 leading-tight" type={"number"}/>
                    </div>
                    <div className="actions flex items-center">
                        <input value={"Verify"} className="button btn-login my-2" type={"submit"}/>
                    </div>
                </form>
            )
        } else {
            return (
                <form className="form-block flex flex-col" onSubmit={adminLoginFunction}>
                    <div className="input-field flex flex-col">
                        <h1 className="field-tag">Email *</h1>
                        <input type="email" name="email"
                               className="txt cleanbtn"
                               onChange={handleInputs}
                               value={state.email}
                               placeholder="Enter email..."/>
                    </div>
                    <div className="input-field flex flex-col">
                        <h1 className="field-tag">Password *</h1>
                        <input type="password" name="password"
                               className="txt cleanbtn"
                               onChange={handleInputs}
                               value={state.password}
                               placeholder="Enter password..."/>
                    </div>
                    <div className="mb-4 flex items-center justify-start hover:text-themeColor anim">
                        <Link to="/auth/forgotPassword" className="">
                            Forgot your password?
                        </Link>
                    </div>
                    <div className="actions flex items-center">
                        <input type="submit" value={response.isLoading ? "Loading..." : "Log In"}
                               className="btn-login button"/>
                        <input type="button" onClick={() => {navigate('/auth/register')}}
                               value={"Sign Up"} className="btn-login button" />
                    </div>
                </form>
            )
        }
    }
    return (
        <Wrapper>
            <div className="login-page bg-white h-screen flex justify-center w-full">
                <div className="wrap wrapWidth flex flex-col">
                    <h3 className="page-title">Log In</h3>
                    {showForm()}
                </div>
            </div>
        </Wrapper>
    );
};

export default Login;
