import React from "react";
import { Link } from "react-router-dom";
import Wrapper from "../../routes/Wrapper";

/*
       <div className="right flex items-center max-w-[35%]">
              <img src="/images/career2.jpg" className="crypto-img" />
            </div>
*/

const Careers = () => {
  return (
    <Wrapper>
      <div className="careers-page bg-white h-screen flex flex-col">
        <div className="page-hero-sec flex ">
          <div className="wrap wrapWidth flex flex-col">
            <h1 className="sec-title">Careers</h1>
            <h2 className="sec-desc">
              Join Bitloc and Be a Part of the Bitcoin Movement.
            </h2>
          </div>
        </div>
        <div className="graph-section w-full">
          <div className="wrap wrapWidth flex">
            <div className="left flex flex-col">
              <h1 className="sec-title pt-4">
                Are you excited about Bitcoin's potential to reshape finance?
              </h1>
              <h2 className="sec-tag">
                Bitloc invites you to explore an exciting career at the
                forefront of this revolutionary movement.
              </h2>
              <p className="sec-desc">
                At Bitloc, we're harnessing the power of Bitcoin to transform
                lending. With a commitment to transparency and security, we're
                reimagining financial possibilities. As we pave the way for a
                new era in finance, we're seeking dynamic individuals across
                various fields – from technology and finance to customer support
                and marketing.
              </p>
            </div>
          </div>
        </div>

        <div className="join-our-team-sec flex">
          <div className="wrap wrapWidth flex flex-col">
            <h1 className="sec-title">Want to join our team?</h1>
            <p className="sec-desc">
              Ready to play a role in shaping the Bitcoin-powered future? Share
              your resume and a brief cover letter outlining your desired role
              with us at Bitloc. Join us in making history by revolutionizing
              finance through the power of Bitcoin. Your journey begins with a
              single email.
            </p>

            <button className="justify-left flex btn-more">
              <Link to="/contactus">Contact Us</Link>
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Careers;
