import React, {useEffect, useState} from 'react';
import {apiUrl, customAlert} from "../../helpers/MyHelpers";
import makeRequest from "../../helpers/axiosRequest";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../../store/reducers/authReducer";

export const BankForm = ({formData, setUserData}) => {
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.authReducer)
    const [state, setState] = useState({
        bank_institution: "",
        branch_number: "",
        account_number: "",
        branch_address: "",
        main_account_currency: "",
        account_name: "",
    });
    useEffect(() => {
        if (formData?.account_name !== undefined) {
            setState({...formData});
        } else {
            setState({
                bank_institution: "",
                branch_number: "",
                account_number: "",
                branch_address: "",
                main_account_currency: "",
                account_name: "",
            });
        }
    }, [formData])
    const handleInputs = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    };
    const countryChange = (e) => {
        setState({...state, country: e.label});
    };
    const submitForm = async (e) => {
        e.preventDefault()
        try {
            const formData = new FormData(e.target);
            const response = await makeRequest('put', `${apiUrl}users/update_banking_info/${user?._id}`, formData)
                .then(response => {
                    if (response?.data?.status == 'Success') {
                        setUserData(response?.data?.user)
                        customAlert({
                            "icon": "success",
                            "title": "Success",
                            "text": "Bank Info Has Been Updated"
                        })
                        setState({
                            bank_institution: "",
                            branch_number: "",
                            account_number: "",
                            branch_address: "",
                            main_account_currency: "",
                            account_name: "",
                        })
                        dispatch(setUser(response?.data?.user));
                    } else if (response?.error) {
                        customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                    } else {
                        customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                    }
                })
        } catch (error) {
            console.error(error);
        }
    }
    const deleteBank = async () => {
        try {
            const formData = new FormData();
            formData.append('_id',user?._id)
            const response = await makeRequest('post', `${apiUrl}users/delete_bank/${state._id}`, formData)
                .then(response => {
                    if (response?.data?.status === 'Success') {
                        setUserData(response?.data?.user)
                        customAlert({
                            "icon": "success",
                            "title": "Success",
                            "text": "Bank Info Has Been Updated"
                        })
                        setState({
                            bank_institution: "",
                            branch_number: "",
                            account_number: "",
                            branch_address: "",
                            main_account_currency: "",
                            account_name: "",
                        })
                        dispatch(setUser(response?.data?.user));
                    } else if (response?.error) {
                        customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                    } else {
                        customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                    }
                })
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <form className="" onSubmit={submitForm}>
            {
                state?._id == undefined ? null : <input type="hidden" name="id" value={state._id}/>
            }
            <div className="mb-4">
                <label className="block font-normal mb-2" htmlFor="bank_institution">
                    Bank Institution *
                </label>
                <input onChange={handleInputs} required={true} className="border rounded w-full py-2 px-3 leading-tight"
                       value={state.bank_institution} name="bank_institution" type="text"/>
            </div>
            <div className="mb-4">
                <label className="block font-normal mb-2" htmlFor="branch_number">
                    Branch Number *
                </label>
                <input onChange={handleInputs} required={true} className="border rounded w-full py-2 px-3 leading-tight"
                       value={state.branch_number} name="branch_number" type="input"/>
            </div>
            <div className="mb-4">
                <label className="block font-normal mb-2" htmlFor="account_number">
                    Account Number *
                </label>
                <input onChange={handleInputs} required={true} className="border rounded w-full py-2 px-3 leading-tight"
                       value={state.account_number} name="account_number" type="text"/>
            </div>
            <div className="mb-4">
                <label className="block font-normal mb-2" htmlFor="branch_address">
                    Branch Address *
                </label>
                <input onChange={handleInputs} required={true} className="border rounded w-full py-2 px-3 leading-tight"
                       value={state.branch_address} name="branch_address" type="text"/>
            </div>
            <div className="mb-4">
                <label className="block font-normal mb-2" htmlFor="main_account_currency">
                    Main Account Currency *
                </label>
                <select defaultValue={state?.main_account_currency} onChange={handleInputs}
                        className="block w-full bg-white border border-gray-200 py-2 px-4 rounded"
                        name="main_account_currency">
                    <option value={'$CAD'}>$CAD</option>
                    <option value={'$USD'}>$USD</option>
                </select>
            </div>
            <div className="mb-4">
                <label className="block font-normal mb-2" htmlFor="account_name">
                    Name Account Is Registered With (First & Last) *
                </label>
                <input onChange={handleInputs} required={true} className="border rounded w-full py-2 px-3 leading-tight"
                       value={state.account_name} name="account_name" type="text"/>
            </div>
            <div className="flex items-center justify-between">
                <button className="button btn-rounded" type="submit">
                    Submit
                </button>
                {state?._id !== undefined ?
                    <button onClick={() => deleteBank()} className="button btn-rounded" type={'button'}>
                        Delete
                    </button> : ''}
            </div>
        </form>
    );
};
