import React, {useEffect, useState} from "react";
import Wrapper from "../../routes/Wrapper";
import {useNavigate, useLocation} from "react-router-dom";
import makeRequest from "../../helpers/axiosRequest";
import {apiUrl, customAlert} from "../../helpers/MyHelpers";


const SmsVerification = () => {
    const navigate = useNavigate();
    const {state} = useLocation();
    const [count, setCount] = useState(60);
    const [isCounting, setIsCounting] = useState(false);

    const handleResend = () => {
        if (!isCounting) {
            reSendOtp()
            setIsCounting(true);
        }
    };

    useEffect(() => {
        if (isCounting) {
            const interval = setInterval(() => {
                setCount((prevCount) => prevCount - 1);
            }, 1000);

            // Stop the counter when it reaches 0
            if (count === 0) {
                setIsCounting(false);
                setCount(60)
                clearInterval(interval);
            }

            return () => {
                clearInterval(interval);
            };
        }
    }, [isCounting, count]);

    const reSendOtp = () => {
        return makeRequest('get', `${apiUrl}auth/resend_otp/${state?._id}`, false)
            .then(res => {
                if (res?.data?.status === "Success"){
                customAlert({
                    "icon": "success",
                    "text": "Otp Code Sent"
                })
                }
            });
    }
    const OtpSubmit = async (e) => {
        e.preventDefault()
        try {
            const formData = new FormData(e.target);
            const response = await makeRequest('put', `${apiUrl}auth/verify-OTP`, formData)
                .then(response => {
                    if (response?.data?.status == 'Success') {
                        navigate("/auth/accountSuccess");

                    } else if (response?.data?.status === "Failure") {
                        customAlert({"icon": "error", "text": "Incorrect OTP Code"})
                    } else {
                        customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                    }
                })
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Wrapper>
            <div className="sms-verification-page flex">
                <div className="wrap wrapWidth flex flex-col">
                    <h3 className="page-title">SMS Verification</h3>
                    <form className="form-block flex flex-col" onSubmit={OtpSubmit}>
                        <div className="input-field flex flex-col">
                            <h1 className="field-tag">SMS Verification Code *</h1>
                            <input type="hidden" name='_id' value={state?._id}/>
                            <input
                                type="number"
                                name="otp"
                                className="txt cleanbtn"
                                placeholder="Enter SMS Code..."
                            />
                        </div>
                        <div className="actions flex items-center mt-8">
                            <button type={"submit"} className="btn-login button">Submit</button>
                            <button onClick={handleResend} type={"button"} disabled={isCounting} className={'btn-login button w-40'}>
                                {isCounting ? count : "Resend OTP"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Wrapper>
    );
};

export default SmsVerification;
