import React, {useEffect, useRef, useState} from "react";
import AdminWrapper from "../../../routes/AdminWrapper";
import Select from "react-select";
import makeRequest from "../../../helpers/axiosRequest";
import {apiUrl, customAlert, imagesUrl} from "../../../helpers/MyHelpers";
import {useSelector} from "react-redux";
import Loader from "../../../components/Loader";
import Spinner from "react-svg-spinner";
import TypeWriterEffect from "react-typewriter-effect";

const IdentityVideo = () => {
    const {user} = useSelector(state => state.authReducer)
    const inputRef = useRef(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [previewURL, setPreviewURL] = useState("");
    const [uploadProgress, setUploadProgress] = useState(false);
    const handleClick = () => {
        // 👇️ open file input box on click of + Image
        inputRef.current.click();
    };

    const imageUpload = (e) => {
        const file = e.target.files[0];
        setPreviewURL(URL.createObjectURL(file));
        setSelectedVideo(file);
    }
    const identificationForm = async (e) => {
        e.preventDefault()
        try {
            setUploadProgress(true)
            const formData = new FormData(e.target);
            const response = await makeRequest('put', `${apiUrl}users/upload_identity_files/${user?._id}`, formData, true)
                .then(response => {
                    if (response?.data?.status === 'Success') {
                        customAlert({
                            "icon": "success",
                            "title": "Success",
                            "text": "Personal Info Has Been Updated"
                        })
                        setSelectedVideo(null);
                        setPreviewURL("");
                        setUploadProgress(0);
                    } else if (response?.error) {
                        customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                    } else {
                        customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                    }
                })
            setUploadProgress(false)
        } catch (err) {
            setUploadProgress(false)
            customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
        }
    };
    return (
        <AdminWrapper>
            {uploadProgress ? <div className="min-h-[80vh] flex justify-center items-center">
                    <div className="">
                        <div className="block mb-6">
                            <Spinner color="black" size="100px" thickness={2}/>
                        </div>
                        <TypeWriterEffect
                            textStyle={{fontFamily: "inherit", color: "black"}}
                            startDelay={100}
                            cursorColor="black"
                            text="Uploading..."
                            typeSpeed={150}
                            loop={true}
                        />
                    </div>
                </div> :
                <div className="dashboard-page flex bg-white">
                    <div className="page-block flex flex-col w-full">
                        <div className="page-title text-black">Upload Identity Confirmation Video</div>
                        <div className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-10">
                            <form className="" onSubmit={identificationForm}>
                                <div className="plus_button">
                                    <div className="add-more-btn" onClick={handleClick}>
                                        <input name='id_files[]' className="hide" onChange={imageUpload}
                                               multiple={false}
                                               ref={inputRef}
                                               type="file"/>

                                    </div>
                                </div>
                                <div className="relative pt-16">
                                    {previewURL ? <video src={previewURL} controls  className={'identify_vid'}/> :
                                        <video controls className={'identify_vid'}
                                               src={`${imagesUrl}/${user?.identity_files}`}></video>}
                                </div>
                                <div className="flex items-center justify-between pt-4">
                                    <button disabled={!selectedVideo} className="button btn-rounded" type="submit">
                                        Submit Identification
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </AdminWrapper>
    );
};

export default IdentityVideo;
