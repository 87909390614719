import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom";
import {apiUrl, customAlert} from "../../helpers/MyHelpers";
import makeRequest from "../../helpers/axiosRequest";
import Swal from "sweetalert2";

export const UsersDataTable = (props) => {
    const authToken = localStorage.getItem("_token");
    const navigate = useNavigate();
    const [users, setUsers] = useState([])
    const getUsers = () => {
        return fetch(apiUrl + 'admin/get_users', {
            headers: {
                'Authorization': 'Bearer ' + authToken
            }
        }).then(res => res.json()).then(d => d.users);
    }

    useEffect(() => {
        getUsers().then(data => setUsers(data));
    }, [])
    const handleClick = (data) => {
        navigate('/admin/userdetails', {state: {...data}});
    }
    const deleteUser = async (id) => {
        Swal.fire({
            title: 'Do you really want to delete this user and related loans?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let data = new FormData();
                data.append('id', id);
                try {
                    const response = await makeRequest('post', `${apiUrl}admin/delete_user`, data)
                        .then(response => {
                            if (response?.data?.status === 'Success') {
                                customAlert({
                                    "icon": "success",
                                    "text": "User Deleted"
                                })
                                getUsers().then(data => setUsers(data));
                            } else if (response?.error) {
                                customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                            } else {
                                customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                            }
                        })
                } catch (err) {
                    customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                }
            }
        })
    }
    return (
        <>
            {
                users?.length === 0 ?
                    <div style={{height: "60vh", display: "flex", alignItems: "center"}}>
                        <img style={{height: "100px"}} className={'m-auto'} src={'/images/loading_gif.gif'} alt=""/>
                    </div>
                    :
                    <div style={{height: "70%", overflowY: "scroll"}}
                         className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-5">
                        <table className="w-full text-left">
                            <thead>
                            <tr>
                                <th className="font-normal px-6 py-3">Full Name</th>
                                <th className="font-normal px-6 py-3">Email</th>
                                <th className="font-normal px-6 py-3">Occupation</th>
                                <th className="font-normal px-6 py-3">Use Of Loan</th>
                                <th className="font-normal px-6 py-3">Approved</th>
                                <th className="font-normal px-6 py-3">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                users?.map((item, key) => {
                                    return (
                                        <tr key={key} className="bg-white border-b  dark:border-gray-700">
                                            <td className="px-6 py-3">{item.first_name + ' ' + item.last_name}</td>
                                            <td className="px-6 py-3">{item.email}</td>
                                            <td className="px-6 py-3">{item.occupation}</td>
                                            <td className="px-6 py-3">{item.use_of_loan}</td>
                                            <td className="px-6 py-3">{item.approved ? "Approved" : 'Not Approved'}</td>
                                            <td className="px-6 py-3 flex ">
                                                <img className={'user_view'} onClick={() => {
                                                    handleClick(item)
                                                }} src={require('../../assets/images/eye.png')} alt={''}/>
                                                <img className={'user_delete'} onClick={() => {
                                                    deleteUser(item._id)
                                                }} src={require('../../assets/images/delete.png')} alt={''}/>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
            }
        </>
    );
}
