import axios from 'axios';
import {apiUrl} from "./MyHelpers";

const api = axios.create({
    baseURL: apiUrl , // Replace with your API base URL
});

const makeRequest = (method, endpoint, data, file_upload = false) => {
    return api.request({
        headers: {
            'Content-Type': file_upload ? 'multipart/form-data' : 'application/json',
            "Authorization": `Bearer ${localStorage.getItem("_token")}`
        },
        method: method,
        url: endpoint,
        data: data,
        onUploadProgress: (progressEvent) => {
            const percent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        },
    });
};
export default makeRequest;
