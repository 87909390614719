import React, { useEffect, useState } from "react";
import AdminWrapper from "../../../routes/AdminWrapper";
import { useDispatch, useSelector } from "react-redux";
import { WalletTabs } from "../../../components/WalletForm/walletTabs";
import { WalletForm } from "../../../components/WalletForm/walletForm";
import { setUser } from "../../../store/reducers/authReducer";

const WalletAddress = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const [walletGroups, setWalletGroups] = useState([]);
  const [formData, setFormData] = useState([]);
  const [flag, setFlag] = useState(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (flag) {
      setWalletGroups(user?.wallet_groups);
      setFlag(false);
    }
  }, [flag]);
  const setFormDataFromInside = (data) => {
    setFormData(data);
  };
  const setUserData = (data) => {
    setWalletGroups(data?.wallet_groups);
    dispatch(setUser(data));
  };
  const handleSearch = () => {
    // let win = window.open("https://www.blockchain.com/explorer/search?search="+search, '_blank');
    let win = window.open("https://mempool.space/address/" + search, "_blank");
    win.focus();
  };
  const handleSearchValue = (e) => {
    setSearch(e.target.value);
  };
  return (
    <AdminWrapper>
      <div className="dashboard-page flex bg-white flex-col">
        <div className="page-block flex flex-col w-full">
          <div className="page-title text-black">Add Your Wallet Address</div>
          <p className="mb-5 leading-6">
            Upon loan maturity, your Bitcoin will be directed to this designated
            address. Should you prefer to allocate your Bitcoin across multiple
            addresses, simply click the '+' icon to append another address.
            Remember, the sum of all combined addresses must equate to 100%.
          </p>
          <WalletTabs
            walletGroups={walletGroups}
            setFormData={setFormDataFromInside}
          />
          <div className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-10 m-2">
            <WalletForm
              formData={formData}
              setFlag={setFlag}
              setUserData={setUserData}
            />
          </div>
        </div>
        <div className="page-block flex flex-col w-full pt-10">
          <div className="page-title text-black">
            Search Blockchain, Transactions, Addresses and Blocks
          </div>
          <p className="mb-5 leading-6">
            {" "}
            Curious about your Bitcoin reserves with Bitloc? It's easy! Simply
            enter your Bitcoin address below and get real-time updates. See if
            Bitloc has sent or received your Bitcoin, giving you complete
            transparency and control over your assets. Stay informed, stay
            secure!
          </p>
          {/*<p className="mb-5 leading-6">Search Blockchain, Transactions, Addresses and Blocks</p>*/}
          <div className="flex rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-10 m-2 m_flex_direction">
            <input
              onChange={(e) => {
                handleSearchValue(e);
              }}
              type="text"
              required={true}
              placeholder="Search Here"
              className="mr-3 border rounded w-full py-3 px-3 leading-tight"
              name="group_name"
            />
            <button
              className="m_search_btn button btn-rounded w-1/5"
              onClick={() => {
                handleSearch();
              }}
              type="button"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default WalletAddress;
