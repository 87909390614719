import Select from "react-select";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

const LoanCalculator = () => {
    const [youSend, setYouSend] = useState(0);
    const [youReceive, setYouReceive] = useState(0);
    const [youReceiveIn, setYouReceiveIn] = useState("CAD");
    const [apiValue, setApiValue] = useState([{CAD: ""}, {USD: ""}]);
    const [calculatorOf, setCalculatorOf] = useState(null)
    const GetPrices = () => {
        fetch("https://blockchain.info/ticker")
            .then((res) => res.json())
            .then((data) => {
                setApiValue({CAD: data.CAD.last, USD: data.USD.last})
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        GetPrices()
        calculator()
    }, [youSend, youReceiveIn, youReceive]);


    const customStyles = {
        control: (base, state) => ({
            ...base,
        }),
        option: (styles, {isFocused, isSelected}) => ({
            ...styles,
            background: isFocused ? "#72AA8F" : isSelected ? "#72AA8F" : undefined,
            zIndex: 1,
        }),
        menu: (base) => ({
            ...base,
            marginTop: 8,
            fontSize: "14px",
            fontWeight: 400,
            background: "#FAA334",
        }),
        menuList: (base) => ({
            ...base,
            fontSize: "14px",
            fontWeight: 400,
        }),
    };
    const dropDownList = [
        {value: "CAD", label: "CAD", name: "receiveIn"},
        {value: "USD", label: "USD", name: "receiveIn"},
    ];
    const handleYouSend = (e) => {
        setCalculatorOf("Dol")
        setYouSend(e.target.value)
        calculator()
    };
    const handleReceiveIn = (e) => {
        setYouReceiveIn(e.value)
        calculator()
    }

    const handleBtc = (e) => {
        setCalculatorOf("BTC")
        setYouReceive(e.target.value)
    }
    const calculator = () => {
        if (calculatorOf === "BTC") {
            const totalVal = parseFloat(youReceive) / apiValue[youReceiveIn];
            const fifty = totalVal * 2;
            setYouSend(fifty)
        } else {
            const totalVal = apiValue[youReceiveIn] * parseFloat(youSend);
            const fifty = totalVal / 2;
            setYouReceive(fifty)
        }

    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Adds smooth scrolling behavior
        });
    };
    return (
        <>
            <div className="calculator-from-block flex flex-col">
                <div className="grid-row3 mb-8">
                    <div className="grid-item flex flex-col">
                        <div className="item-tag">You send</div>
                        <div className="item-field flex items-center">
                            <input onChange={handleYouSend} type="number" value={youSend} name={'youSend'}
                                   className="txt cleanbtn"/>
                            <h1 className="f-lbl">BTC</h1>
                        </div>
                    </div>
                    <div className="grid-item flex flex-col">
                        <div className="item-tag">You receive</div>
                        <div className="item-field flex items-center">
                            <input onChange={handleBtc} type="number" value={youReceive}
                                   className="txt cleanbtn" name={'youReceive'}/>
                            <h1 className="f-lbl">{youReceiveIn}</h1>
                        </div>
                    </div>
                    <div className="grid-item flex flex-col">
                        <div className="item-tag">Receive in</div>
                        <Select
                            onChange={handleReceiveIn}
                            styles={customStyles}
                            options={dropDownList}
                            className="select"
                            placeholder={youReceiveIn}
                            name={'receiveIn'}
                        />
                    </div>
                </div>
                <div className="grid-row3">
                    <div className="grid-item flex flex-col">
                        <div className="item-tag">Term</div>
                        {" "}
                        <h1 className="val">12 months</h1>
                    </div>
                    <div className="grid-item flex flex-col">
                        <div className="item-tag">Loan to value (LTV)</div>
                        <h1 className="val">50%</h1>
                    </div>
                    <div className="grid-item flex flex-col">
                        <div className="item-tag">Annual interest rate*</div>
                        <h1 className="val">Starting at 12.5%</h1>
                        <p className="f-desc">
                            Please note that a 2.5% origination fee will be deducted from the principal amount of your loan.
                        </p>
                    </div>
                </div>
                <div className="action flex flex-col">
                    <button className="btn-get button"><Link onClick={scrollToTop} to={'/user/applyforloan'}> Get
                        Loan</Link></button>

                </div>
            </div>
        </>
    )
}
export default LoanCalculator;
