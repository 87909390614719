import {useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Wrapper from "../../routes/Wrapper";
import {apiUrl, customAlert} from "../../helpers/MyHelpers";
import makeRequest from "../../helpers/axiosRequest";

const ResetPassword = () => {
    const [state, setState] = useState({
        new_password: '',
        canfirm_new_password: ''
    });
    const {id} = useParams();
    const navigate = useNavigate();
    const handleInputs = (e) => {
        // Set State of Data on Input Fields
        setState({...state, [e.target.name]: e.target.value});
    };

    const changePasswordFormSubmit = async (e) => {
        e.preventDefault()
        try {
            if (state?.new_password !== state?.canfirm_new_password) {
                customAlert({
                    "icon": "error",
                    "title": "Password Confirmation Error",
                    "text": "The passwords you entered do not match. Please make sure the new password and confirmation password are identical."
                })
                return
            }
            const response = await makeRequest('post', `${apiUrl}auth/reset_pass/${id}`, state)
                .then(response => {
                    if (response?.data?.status === 'Success') {
                        customAlert({
                            "icon": "success", "title": "Success", "text": "Password has been changed. Login Now"
                        })
                        navigate("/auth/login");
                    } else {
                        customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                    }
                }).catch(error => {
                    customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                })
        } catch (error) {
            customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
        }
    }

    return (
        <Wrapper>
            <div className="login-page bg-white h-screen flex justify-center w-full">
                <div className="wrap wrapWidth flex flex-col justify-center">
                    <h3 className="page-title">Change Password</h3>
                    <form
                        className="form-block flex flex-col"
                        onSubmit={changePasswordFormSubmit}>
                        <div className="input-field flex flex-col">
                            <h1 className="field-tag">New Password *</h1>
                            <input
                                type="password"
                                name="new_password"
                                className="txt cleanbtn"
                                onChange={handleInputs}
                                value={state?.new_password}
                                placeholder="Enter Password..."
                            />
                        </div>
                        <div className="input-field flex flex-col">
                            <h1 className="field-tag">Confirm New Password *</h1>
                            <input
                                type="password"
                                name="canfirm_new_password"
                                className="txt cleanbtn"
                                onChange={handleInputs}
                                value={state?.canfirm_new_password}
                                placeholder="Enter Confirm Password..."
                            />
                        </div>
                        <div className="mb-4 flex items-center justify-start hover:text-themeColor anim">
                            <Link to="/auth/login" className="">
                                Back to Login?
                            </Link>
                        </div>
                        <div className="actions flex items-center">
                            <input
                                type="submit"
                                value={"Change Password"}
                                className="btn-login button"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </Wrapper>
    );
};

export default ResetPassword;
