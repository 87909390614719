import React from "react";
import { Link } from "react-router-dom";
import Wrapper from "../../routes/Wrapper";

const AccountSuccess = () => {
  return (
    <Wrapper>
      <div className="account-success-page flex">
        <div className="wrap wrapWidth flex justify-center items-center">
          <div className="success-block flex flex-col items-center justify-center">
            <img src="/images/coins.png" className="coins-img" />
            <div className="tag">Account Creation Successful!</div>
            <Link to="/auth/login" className="btn-login button rounded-3xl">
              Login Now
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default AccountSuccess;
