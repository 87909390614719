import React from "react";

function ArrowDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="7"
      fill="none"
      viewBox="0 0 13 7"
    >
      <path
        fill="#FFF"
        d="M6.905 6.835l5.94-5.94c.1-.1.155-.231.155-.368a.52.52 0 00-.155-.368L12.84.153A.515.515 0 0012.466 0a.536.536 0 00-.373.153L6.499 5.746.907.153A.516.516 0 00.534 0a.537.537 0 00-.373.153L.154.159A.52.52 0 000 .527a.52.52 0 00.154.367l5.941 5.94A.56.56 0 006.5 7a.583.583 0 00.405-.165z"
      ></path>
    </svg>
  );
}

export default ArrowDownIcon;
