import React from "react";

function MenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="18"
      fill="none"
      viewBox="0 0 16 11"
    >
      <path
        fill="#ffff"
        d="M0 .782C0 .575.084.376.234.23A.81.81 0 01.8 0h14.4a.81.81 0 01.566.23.773.773 0 010 1.105.81.81 0 01-.566.23H.8a.81.81 0 01-.566-.23A.773.773 0 010 .782zM0 5.5c0-.207.084-.406.234-.553a.81.81 0 01.566-.23h14.4a.81.81 0 01.566.23.773.773 0 010 1.106.81.81 0 01-.566.23H.8a.81.81 0 01-.566-.23A.773.773 0 010 5.5zm.8 3.936a.81.81 0 00-.566.229.773.773 0 000 1.106A.81.81 0 00.8 11h14.4a.81.81 0 00.566-.23.773.773 0 000-1.105.81.81 0 00-.566-.23H.8z"
      ></path>
    </svg>
  );
}

export default MenuIcon;
