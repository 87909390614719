import AdminWrapper from "../../../routes/SuperAdminWrapper";
import {UsersDataTable} from "../../../components/Datatable/UserDatatable";

const Users = () => {
    return (
        <AdminWrapper>
            <div className="dashboard-page flex bg-white">
                <div className="page-block flex flex-col w-full">
                    <div className="page-title text-black">Users</div>
                    <UsersDataTable/>
                </div>
            </div>
        </AdminWrapper>
    );
};

export default Users;
