import React from "react";
import Wrapper2 from "../../routes/Wrapper2";

const TermsofService = () => {
  return (
    <Wrapper2>
      <div className="b2x-terms-page flex bg-white">
        <div className="page-block flex flex-col">
          <div className="page-title text-black">Terms Of Service</div>
          <div className="content-list flex flex-col">
            <h5 className="content-title">
              Acceptance of Terms
            </h5>
            <p className="content-desc">


By accessing or using BitLoc Inc.'s website at https://bitloc.io/ and its subdomains, or BitLoc Inc.'s mobile application available on the Apple App Store or Google Play Store (“BitLoc Mobile App”) (collectively, the “Platform”), you agree that you have read, understood, and accept these Terms of Service (“Terms”), which form a legally binding contract between you and BitLoc Inc.
</p>
<p className="content-desc">
You acknowledge that the following additional BitLoc terms and conditions are incorporated into these Terms by reference: (i) Privacy Policy, found at https://bitloc.io/legal/privacy-policy; (ii) Risk Disclosure Statement, found at https://bitloc.io/legal/risk-disclosure-statement; and (iii) Disclaimers, found at https://bitloc.io/legal/disclaimers.
</p>
<p className="content-desc">
In these Terms, users of the Platform and holders of BitLoc Accounts (as defined herein) are referred to as “you” or “your.” Your sole and exclusive counterparty to these Terms is BitLoc Inc., a company registered as a Virtual Asset Service Provider.
</p>
<p className="content-desc">
Your access and use of the Platform and BitLoc Inc.’s products and services (the “Services”) are expressly conditioned on your acceptance of and compliance with these Terms. IF YOU DO NOT AGREE WITH ANYTHING CONTAINED IN THESE TERMS, PLEASE DO NOT OPEN A BITLOC ACCOUNT OR ACCESS OR USE THE PLATFORM.
</p>
<p className="content-desc">
Opening your BitLoc Account and Know-Your-Client (“KYC”) verification
</p>
<p className="content-desc">
You may open a BitLoc account (“BitLoc Account”) at https://platform.bitloc.io/signup. As part of BitLoc Inc.’s anti-money laundering and counterterrorism financing compliance program (“AML Program”) and applicable laws, BitLoc Inc. may be required to obtain, verify, and record certain KYC information about you. BitLoc Inc.'s AML Program is designed to prevent the use of BitLoc’s Services for money laundering or terrorist financing activities. BitLoc Inc. takes all necessary steps to prohibit fraudulent transactions, report suspicious activities, and prevent money laundering and any related acts that facilitate money laundering, terrorist financing, or any other financial crimes, among others. As such, you shall provide BitLoc Inc. with all information required for the purposes of identity verification and the detection of money laundering, terrorist financing, fraud, or any other financial crimes, and permit BitLoc Inc. to keep a record of such information. You shall complete certain verification procedures before you are permitted to use BitLoc’s Services. Your access to the Platform and Services and any limits that may apply to your use thereof may be altered as a result of information BitLoc Inc. collects about you on an ongoing basis.
</p>
<p className="content-desc">
The information BitLoc Inc. requires from you may include certain personal information, including your name, address, telephone number, email address, date of birth, taxpayer identification number, government identification, and information regarding your bank account (such as the name of the bank, the account type, routing number, and account number), as applicable. In providing BitLoc Inc. with this or any other information that may be required, you confirm that the information is true, accurate, current, non-misleading, and complete. You agree to keep BitLoc Inc. updated if any of the information you provide changes. You authorize BitLoc Inc. to make inquiries, whether directly or through third parties, that BitLoc Inc. considers necessary to verify your identity or protect you or BitLoc Inc. against fraud or other financial crime, and to take action BitLoc Inc. reasonably deems necessary based on the results of such inquiries. When BitLoc Inc. carries out these inquiries, you acknowledge that your personal information may be disclosed to fraud prevention, regulatory, or financial crime agencies.
</p>
<p className="content-desc">
BitLoc Inc. will monitor your BitLoc Account, your use of the Platform, and review your personal information on an ongoing basis as may be required by applicable law or pursuant to BitLoc Inc.’s internal policies and procedures. At any time, BitLoc Inc. may require you to provide BitLoc Inc. with additional information as a condition to your continued access to and use of your BitLoc Account and the Platform. During such time, your access to and use of your BitLoc Account may be temporarily restricted.
</p>
<p className="content-desc">
If you use the BitLoc Mobile App on your mobile device, you may choose to enable the biometrics feature within the BitLoc Mobile App to authenticate your identity to log into your BitLoc Account. By choosing to enable biometrics in the BitLoc Mobile App, you acknowledge and agree that: (a) your biometric information is stored on your mobile device only and is never shared with, used, accessed, or stored by BitLoc Inc.; (b) you will not enable biometrics in the BitLoc Mobile App if there is any biometric information of any other individuals stored on your mobile device or if you share your mobile device with any other individuals; (c) you accept responsibility for all transactions in your BitLoc Account that are carried out through the BitLoc Mobile App while the biometrics feature is enabled, regardless of whether the transaction was completed by you or any person with biometric information saved in your mobile device; and (d) in no event will BitLoc Inc. be liable for any damages arising out of or related to your enablement of biometrics in the BitLoc Mobile App. You may choose to disable the biometrics feature at any time within the BitLoc Mobile App.
</p>
<p className="content-desc">
<h5 className="content-title">
Access to the Platform
</h5>
<p className="content-desc">
To access the Platform, you must have the necessary equipment (such as a smartphone or laptop) and the associated telecommunication service subscriptions to access the Internet. Access to the Platform may become degraded or unavailable during times of significant volatility or volume. This could result in your inability to conduct transactions for periods of time and may also lead to support response time delays. Although BitLoc Inc. strives to provide you with excellent service, BitLoc Inc. does not represent that the Platform or Services will be available without interruption, and BitLoc Inc. does not guarantee that any transaction will be executed, accepted, or recorded. BitLoc Inc. will not be liable to you for any losses resulting from or arising out of transaction delays. You acknowledge that with respect to the BitLoc Mobile App, you may not have access to all the Services, features, functionalities, content, or information found on BitLoc Inc.’s website.
</p>


<h5 className="content-title">Security</h5>


<p className="content-desc">
Please note that no technology can be considered completely secure or impenetrable, and internet protocols and other public and/or proprietary technology used or accessed by the Platform may be vulnerable to exploitation or compromise by persons engaged in hacking or criminal conduct. Subject to applicable law, BitLoc Inc. will not be liable to you for any such exploitation or criminal conduct by third parties.
</p>
<p className="content-desc">
Attacks upon the digital asset industry are increasing in their frequency, persistence, and sophistication, and, in many cases, are being conducted by sophisticated, well-funded, and organized groups and individuals, including state actors. Although BitLoc Inc. has developed systems and processes designed to protect the data BitLoc Inc. manages, prevent data loss and other security breaches, effectively respond to known and potential risks, and expects to continue to expend significant resources to bolster these protections, there can be no assurance that these security measures will provide absolute security or prevent breaches or attacks.
</p>
<p className="content-desc">
BitLoc Inc.’s security technology is designed to prevent, detect, and mitigate inappropriate access to BitLoc Inc.’s systems by internal or external threats. BitLoc Inc. believes it has developed and maintained reasonable administrative, technical, and physical safeguards designed to comply with applicable laws and industry standards. However, it is nevertheless possible that hackers, employees, service providers, or others acting contrary to BitLoc Inc.’s policies and procedures could circumvent these safeguards to improperly access BitLoc Inc.’s systems or documents or the systems or documents of BitLoc Inc.’s business partners, agents, or service providers, and improperly access, obtain, misuse client digital assets and funds. The methods used to obtain unauthorized access, disable, or degrade service or sabotage systems are also constantly changing and evolving and may be difficult to anticipate or detect for long periods of time.
</p>
<p className="content-desc">
You are solely responsible for maintaining adequate security and control of any and all login IDs, passwords, private keys, personal identification numbers (PINs), and any other codes or account credentials that you use to access your BitLoc Account ("Account Credentials"). You shall keep your Account Credentials confidential and not authorize any third party to access or use your BitLoc Account on your behalf unless BitLoc Inc. provides an approved mechanism for such access or use. You shall prevent unauthorized access to or use of your BitLoc Account and promptly notify BitLoc Inc. at support@bitloc.io of any actual or suspected unauthorized access, use, or security breach of your BitLoc Account, system, or network (“Client Security Breach”) as soon as possible. Upon confirming receipt of such notice, BitLoc Inc. shall take reasonable steps to protect your BitLoc Account, including, for example, by temporarily restricting access to your BitLoc Account, suspending any pending transactions, or requiring you to change your Account Credentials. You shall promptly report any Client Security Breach to the relevant legal authorities if you are required to do so by applicable law, and to provide BitLoc Inc. a copy of any report prepared by such legal authorities.
</p>
<p className="content-desc">
You shall fully cooperate with BitLoc Inc. in its internal investigation of a Client Security Breach and provide BitLoc Inc. with the results of any third-party forensic investigation that you undertake. If an investigation of a Client Security Breach is conducted by relevant legal authorities, you further agree to (i) fully cooperate with such legal authorities and BitLoc Inc. in such investigation; (ii) complete any required affidavits promptly, accurately, and thoroughly; and (iii) allow such legal authorities access to your mobile device, computer, and network as may be required in such investigation. Failure to cooperate in any such investigation may cause delays in regaining access to your BitLoc Account and any funds held within.
</p>
<p className="content-desc">
You are solely responsible for all activity that occurs in connection with your BitLoc Account, whether or not authorized by you, and for maintaining the confidentiality of your Account Credentials. BitLoc Inc. will not be liable to you or any other person for acts or omissions made or committed by you, your agent, representative, or third-party service provider in respect of your BitLoc Account.
</p>


<h5 className="content-title">Risk Disclosure Statement and Disclaimers</h5>


<p className="content-desc">
In connection with your access and use of your BitLoc Account, the Platform, and the Services, you hereby acknowledge that you have read and understood BitLoc Inc.'s Risk Disclosure Statement, found at https://bitloc.io/legal/risk-disclosure-statement, and Disclaimers, found at https://bitloc.io/legal/disclaimers.
</p>
<p className="content-desc">


<h5 className="content-title">Your representations, warranties, and covenants</h5>


</p>
<p className="content-desc">
As a material inducement for BitLoc Inc. to provide you with a BitLoc Account under these Terms, you hereby make the following representations, warranties, and covenants to BitLoc Inc., and such representations, warranties, and covenants are deemed to be made continuously for as long as your BitLoc Account is open, provided, however, that those representations and warranties expressly referring to another specific date shall be true and correct as of such date:
</p>
<p className="content-desc">
a) If you are a legal entity, (i) you are duly incorporated or organized, validly existing and in good standing under the laws of your jurisdiction of incorporation or organization, (ii) you have the legal capacity or power and authority to own all of your property and carry on your business and to agree to these Terms and to perform your obligations hereunder, (iii) you are duly licensed and qualified and, if applicable, in good standing in each jurisdiction in which the nature of the business conducted by you or the nature of the property owned or leased by you requires such licensing or qualification, (iv) you have taken all necessary action to authorize the opening of your BitLoc Account and the acceptance and performance of these Terms and there are no provisions in any shareholder agreement or the constating documents which restrict or limit your powers to agree to and accept these Terms, and (v) these Terms constitute valid and legally binding obligations enforceable against you in accordance with their respective terms, subject, however, to limitations with respect to enforcement imposed by law in connection with bankruptcy or similar proceedings and to the extent that equitable remedies such as specific performance and injunction are in the discretion of the court from which they are sought;
</p>
<p className="content-desc">
b) If you are an individual, you are the age of majority in your jurisdiction of residence, and in any case, you are at least 18 years old, and you have the capacity to enter into and perform your obligations under these Terms;
</p>
<p className="content-desc">
c) You are not a resident of an Ineligible Jurisdiction as specified at https://bitloc.io/legal/ineligible-jurisdictions;
</p>
<p className="content-desc">
d) No consent, approval, order, license or exemption from or filing with or registration with any governmental authority nor any approval of any other person is or will be required as a condition to (i) the acceptance of these Terms, (ii) your use of your BitLoc Account, or (iii) the validity or enforceability of these Terms;
</p>
<p className="content-desc">
e) There are no actions, suits, litigation or proceedings, at law or in equity, in progress or pending or threatened by or against you before any court, administrative agency or arbitrator or any other person which could affect your present or prospective ability to agree to these Terms, and you are not aware of any existing grounds on which any such claim or proceeding might be commenced. You are not in default with respect to any judgment, order, injunction, decree or award;
</p>
<p className="content-desc">
f) You are, and at all times while your BitLoc Account is open, will be in compliance with all applicable laws;
</p>
<p className="content-desc">
g) You will notify BitLoc Inc. in writing prior to: (i) if you are an individual, making any change to your address of residence; or (ii) if you are a legal entity (that is, not an individual), making any change in your principal place of business or jurisdiction of formation;
</p>
<p className="content-desc">
h) You have never been previously suspended or removed from the Platform or have had your BitLoc Account closed by BitLoc Inc.;
</p>
<p className="content-desc">
i) You will use your BitLoc Account only for yourself, and not on behalf of any third party;
</p>
<p className="content-desc">
j) All information, materials, data, content, or documents you provide or make available to BitLoc Inc. is and will remain true, accurate, current, non-misleading, and complete. You will maintain and update all personal information you provide to BitLoc Inc. to ensure it is true, accurate, current, non-misleading, and complete at all times;
</p>
<p className="content-desc">
k) You will keep all login details, user account, and passwords secure at all times and will not allow anyone else to access your BitLoc Account;
</p>
<p className="content-desc">
l) You have a sophisticated understanding of digital assets and the technology that underlies them;
</p>
<p className="content-desc">
m) You agree that you are and will remain solely liable for all the activity that occurs in connection with your BitLoc Account; and
</p>
<p className="content-desc">
n) You will not transmit to or make available on or upload any information to the Platform that: (i) is commercial or promotional in nature; (ii) is unlawful, harmful, deceptive, or otherwise violates the legal rights or privacy of others; (iii) is capable of giving rise to legal action whether against you or BitLoc Inc.; (iv) infringes any patent, trademark, trade secret, copyright, or other property rights of any party; (v) impersonates any person or entity; or (iv) contains viruses, malware, or any program, code, or technology designed to disrupt, intercept, impair, or destroy the functionality of the Platform or its software, data, or network.
</p>

<h5 className="content-title">Changes to Services</h5>

<p className="content-desc">
The availability of BitLoc Inc.’s Services is subject to change from time to time without notice. BitLoc Inc. reserves the right at any time to modify or discontinue one or more Services (or any part thereof) without notice. BitLoc Inc. will not be liable to you for any such modification or discontinuance of any Service.
</p>
<p className="content-desc">
Suspending, restricting, or closing your BitLoc Account
</p>
<p className="content-desc">
BitLoc Inc. may suspend, restrict, or terminate your access or use of your BitLoc Account if you fail to comply with these Terms, or if, in BitLoc Inc.’s sole discretion, such action is reasonably necessary to comply with: (a) BitLoc Inc.’s AML Program; (b) valid court orders, law enforcement or regulatory requests; (c) requirements under applicable law; (d) internal controls to prevent and investigate fraud or suspicious transactions; or (e) other security reasons. You hereby acknowledge that BitLoc Inc.’s decision to take such actions may be based on confidential criteria and reasons related to BitLoc Inc.’s risk management, legal, regulatory, and security protocols, and BitLoc Inc. is under no obligation to disclose any of the foregoing to you.
</p>
<p className="content-desc">
BitLoc Inc. may suspend or restrict your BitLoc Account for any period that is reasonably necessary to resolve any of the above-noted matters. BitLoc Inc. may close your BitLoc Account in accordance with this Section at any time without advance notice. If your BitLoc Account has any account balances associated with the Services when BitLoc Inc. closes it, BitLoc Inc. will return such account balances to you pursuant to the terms of such Services unless such transfer is prohibited under applicable law.
</p>

<h5 className="content-title">Fair Practices</h5>

<p className="content-desc">
You agree not to: (a) make any representations, warranties, or guarantees on BitLoc Inc.’s behalf with respect to the Platform or Services, (b) make any false or misleading representations with respect to BitLoc Inc., the Platform, or Services; or (c) participate or engage in any illegal, deceptive, misleading, fraudulent, unethical, or improper practices through your BitLoc Account, the Platform, or Services.
</p>


<h5 className="content-title">Feedback</h5>

<p className="content-desc">
You acknowledge and agree that BitLoc Inc. (a) may, from time to time, collect suggestions, comments, ideas, materials, or other feedback from you with respect to the Platform or Services on an ongoing basis and also through questionnaires, surveys, emails, and/or any other communication media available at the time (collectively referred to as “Feedback”); (b) shall at all times own and be the exclusive owner of such Feedback; (c) may use such Feedback for any purpose whatsoever; and (d) where legally required and necessary, anonymize the Feedback and remove any personally identifiable information from such Feedback before BitLoc Inc.’s use or exploitation of such Feedback. You further acknowledge that you have no right, and hereby waive any request, for acknowledgment or compensation for any Feedback or any modifications to BitLoc Inc.’s Platform or Services based on any Feedback.
</p>
<p className="content-desc">
By submitting Feedback to BitLoc Inc., you: (e) acknowledge and agree that such Feedback is non-confidential, except for any personal and financial information; (f) grant BitLoc Inc. a perpetual, worldwide, royalty-free, irrevocable, transferable, sublicenseable, fully paid-up right to copy, use, reproduce, modify, adapt, publish, create derivative works from, translate, transmit, display, distribute, market, promote, sell, rent, or lease such Feedback in any form or medium known or later developed; and (g) agree that you will have no claim against BitLoc Inc. for any actual or alleged infringement of any proprietary rights, rights of privacy or publicity, moral rights, or rights of attribution in connection with BitLoc Inc.’s use of any Feedback you provide.
</p>


<h5 className="content-title">Copyrights, Trademarks, and Other Intellectual Property Rights</h5>

<p className="content-desc">
Unless otherwise indicated by BitLoc Inc., all copyright and other intellectual property rights in all content and other materials contained on BitLoc Inc.’s Platform or provided in connection with the Services, including any logos, identifying marks, images, illustrations, designs, icons, photographs, videos, text, and other written and multimedia materials, all of BitLoc Inc.’s product information and requirements, services, advertising materials or collateral, login or registration criteria and instructions, help guidelines, user documentation, and customer and technical support documents, and the Platform’s likeness, look and feel, format, layout, software, code (whether binary, assembly, source, object, HTML, or otherwise), routines, scripts, platforms, and applications, as well as any data, files, archives, folders, or downloads (collectively, "BitLoc Materials") are the sole, exclusive, and proprietary property of BitLoc Inc. or BitLoc Inc.’s licensors or suppliers and are protected by applicable copyright laws and other intellectual property rights laws. All rights, title, and interest in and to the Platform and the BitLoc Materials and any intellectual property contained therein or relating thereto, including any copyright, patent, or trademark, are and will remain the sole and exclusive property of BitLoc Inc. or its licensors, as applicable.
</p>


<h5 className="content-title">Third-Party Websites and Content</h5>

<p className="content-desc">
The Platform and some of BitLoc Inc.’s communications to you may contain links to third-party websites. Any such links are provided only as a convenience and should be used at your own risk. BitLoc Inc. has no control over any such links and websites. The existence of any link on the Platform or BitLoc Inc.’s communications does not imply any affiliation, sponsorship, endorsement, approval, investigation, representation, warranty, verification, or monitoring by BitLoc Inc. Your use of any such third-party websites is governed by the terms of service and the privacy policies of those websites. BitLoc Inc. will not be liable to you for any loss arising from or related to your access and use of any third-party website.
</p>


<h5 className="content-title">Electronic Delivery of Documents</h5>

<p className="content-desc">
BitLoc Inc. may be required to provide certain legal and regulatory disclosures, agreements, terms and conditions, account statements, notices, and other communications (collectively “Documents”) to you in written form. You agree and consent to BitLoc Inc. delivering such Documents to you in electronic form, including by e-mail. You agree that all Documents that BitLoc Inc. provides to you electronically satisfy any legal requirement that such communications would satisfy if they were provided in writing. To the extent permissible under applicable law, you waive any rights to require an original non-electronic signature or delivery or retention of non-electronic records.
</p>


<h5 className="content-title">Changes to the Platform and updates to these Terms</h5>

<p className="content-desc">
These Terms are effective as of the date herein. BitLoc Inc. reserves the right to make changes to the Platform at any time. BitLoc Inc. may update these Terms from time to time for commercial, security, legal, or regulatory reasons. BitLoc Inc. may notify you of such changes via BitLoc Inc.’s website, in your BitLoc Account or by email, but BitLoc Inc. has no obligation to notify you of every change. You are responsible for periodically checking these Terms for updates. Updates will take effect immediately once BitLoc Inc. has posted the updated Terms on the Platform. Your continued use of the Platform after any such updates will constitute your acceptance of the updated Terms. If you do not agree with the updated version of these Terms, your sole and exclusive remedy is to close your BitLoc Account and discontinue using the Platform. Notwithstanding the foregoing, BitLoc Inc. will provide notices for future updates to these Terms to the extent required pursuant to applicable laws.
</p>


<h5 className="content-title">Indemnification</h5>

<p className="content-desc">
You shall indemnify and hold all BitLoc Service Providers harmless from or against any and all liabilities, obligations, losses, damages, penalties, judgments, suits, fines, costs, expenses (including reasonable attorneys’ fees), claims, disbursements, or actions of any kind arising out of or relating to your use of your BitLoc Account or any BitLoc Service Provider’s activities in connection with such BitLoc Account, or any of the information, services, or transactions contemplated by or related to these Terms, or any BitLoc Service Provider’s performance or non-performance of its obligation to you under these Terms, or your breach of these Terms.
</p>
<p className="content-desc">
You shall indemnify all BitLoc Service Providers for reasonable legal and enforcement costs and expenses that arise out of or relate to any regulatory inquiry, legal action, litigation, dispute, or investigation that arises out of or relates to your use of your BitLoc Account. In connection with the foregoing, BitLoc Inc. may suspend or restrict your BitLoc Account in accordance with Section 8 until such matter is resolved and will be entitled to charge your BitLoc Account for all such costs without notice.
</p>


<h5 className="content-title">Disclosure of BitLoc Account information</h5>


<p className="content-desc">
You acknowledge that BitLoc Inc. may, in accordance with BitLoc Inc.’s Privacy Policy, disclose information about your BitLoc Account and transactions to third parties: (a) with your written permission; (b) where it is necessary to process transfers; (c) to verify the existence and condition of your BitLoc Account for a third party; (d) to protect or enforce BitLoc Inc.’s legal rights; or (e) to comply with BitLoc Inc.’s obligations under applicable law, law enforcement or regulatory requests, or any judgment, order, injunction, decree, or award rendered by a court or government agency.
</p>


<h5 className="content-title">Assignment; Binding effect</h5>


<p className="content-desc">
You may not assign these Terms, in whole or in part. Any purported assignment of these Terms by you in violation of this Section is null and void. BitLoc Inc. may assign these Terms, in whole or in part, at any time without notice to you. These Terms are binding upon and inure to the benefit of you and your heirs, administrators, executors, legal and personal representatives, and to BitLoc Inc. and its successors and assigns.
</p>


<h5 className="content-title">Waiver</h5>

<p className="content-desc">
BitLoc Inc. will not be deemed to have waived any rights under these Terms unless such waiver is given in writing by BitLoc Inc.’s duly appointed representative. No delay or omission on BitLoc Inc.’s part in exercising any right shall operate as a waiver of such right or any other right. A waiver by BitLoc Inc. of a provision of these Terms shall not prejudice or constitute a waiver of BitLoc Inc.’s right otherwise to demand strict compliance with that provision or any other provision of these Terms. No prior waiver by BitLoc Inc., nor any course of dealing between BitLoc Inc. and you shall constitute a waiver of any of BitLoc Inc.’s rights or of your obligations as to any future transactions. Whenever BitLoc Inc.’s consent is required under these Terms, the granting of such consent by BitLoc Inc. in any instance shall not constitute continuing consent to subsequent instances where such consent is required and in all cases such consent may be granted or withheld in BitLoc Inc.’s sole discretion.
</p>


<h5 className="content-title">Entire Agreement</h5>

<p className="content-desc">
These Terms constitute the entire agreement between you and BitLoc Inc. relating to the subject matter hereof and supersede all prior and contemporaneous understandings, agreements, or representations, whether oral or written, relating to the subject matter hereof, including any previous versions of these Terms.
</p>


<h5 className="content-title">Severability</h5>

<p className="content-desc">
If any provision of these Terms is found to be invalid, illegal, or unenforceable by a court of competent jurisdiction in any jurisdiction, such provision will be deemed severable and the invalidity, illegality, or unenforceability thereof will not affect any other provision of these Terms, nor invalidate or render unenforceable such provision in any other jurisdiction.
</p>


<h5 className="content-title">Governing Law and Jurisdiction</h5>

<p className="content-desc">
These Terms and all matters arising out of or related to these Terms are governed by and construed in accordance with the laws of the Cayman Islands.
</p>
<p className="content-desc">
You hereby agree that the courts of the Cayman Islands will have exclusive jurisdiction to hear and determine any action or proceeding arising out of or related to these Terms, and you agree to irrevocably submit to the jurisdiction of such courts.
</p>


<h5 className="content-title">Interpretation provisions</h5>

<p className="content-desc">
Section headings in these Terms are for convenience only and are not to be used to interpret the provisions of these Terms. Any reference to a period of “days” means calendar days and not business days. The word "including" in these Terms is deemed to be followed by the words "without limitation." References in these Terms (a) to other BitLoc terms and conditions means such terms and conditions as amended from time to time, and (b) to a statute means such statute as amended from time to time and includes any successor legislation thereto and any regulations promulgated thereunder.
</p>


            </p>
          </div>
        </div>
      </div>
    </Wrapper2>
  );
};

export default TermsofService;
