import React from "react";
import Wrapper from "../../routes/Wrapper";

const RiskManagement = () => {
  return (
    <Wrapper>
      <div className="risk-management-page bg-white h-screen flex flex-col">
        <div className="wrap wrapWidth flex flex-col">
          <h1 className="sec-title my-4">Risk Management</h1>

          <div className="points-block flex flex-col">
            <div className="point-item flex items-center justify-between">
              <div className="left-side flex items-center">

                <h1 className="title">Open Source Software</h1>
              </div>
              <div className="right-side flex items-center justify-end">
                <p className="desc">
                  Bitloc relies on open-source software to boost safety and security. Open-source software's transparency and a large community of developers continuously enhance its security. This approach reduces the risk of vulnerabilities and strengthens our services' safety, fostering a trustworthy environment for all users.
                </p>
              </div>
            </div>
            <div className="point-item flex items-center justify-between">
              <div className="left-side flex items-center">

                <h1 className="title">Carefully Chosen Asset</h1>
              </div>
              <div className="right-side flex items-center justify-end">
                <p className="desc">
                  Bitloc's commitment to exclusively utilizing Bitcoin is rooted in its status as the paramount and battle-tested digital asset, widely recognized as a commodity and not a security. Our focus on Bitcoin reflects its nature as a resilient protocol within the cryptocurrency realm. This strategic choice serves as a risk mitigation measure, shielding our balance sheet from potential volatility and risks associated with altcoins, some of which have seen significant fluctuations, even going to zero. By prioritizing Bitcoin, we bolster the security, stability, and reliability of our services, emphasizing our dedication to providing customers with a dependable Bitcoin lending experience.
                </p>
              </div>
            </div>
            <div className="point-item flex items-center justify-between">
              <div className="left-side flex items-center">

                <h1 className="title">No Stablecoins</h1>
              </div>
              <div className="right-side flex items-center justify-end">
                <p className="desc">
                Bitloc abstains from the use of stablecoins, primarily due to concerns associated with third-party trust and the vulnerabilities frequently observed in these digital assets. Stablecoins often rely on centralized entities or reserve mechanisms, introducing an element of dependency and counterparty risk that runs counter to the principles of decentralization and self-sovereignty inherent to Bitcoin. By exclusively focusing on Bitcoin, Bitloc ensures that its users benefit from a trusted, resilient, and fully decentralized digital asset, minimizing exposure to the uncertainties and potential pitfalls associated with stablecoins.
                </p>
              </div>
            </div>
            <div className="point-item flex items-center justify-between">
              <div className="left-side flex items-center">

                <h1 className="title">
                Simplicity and Security
                </h1>
              </div>
              <div className="right-side flex items-center justify-end">
                <p className="desc">
                   Bitloc's strategy revolves around simplicity and security. We keep our operations straightforward, opting exclusively for cold storage over hot wallets and avoiding the use of smart contracts, web wallets, or third-party custodians. This streamlined approach reflects our commitment to transparency and user trust. By eliminating unnecessary complexities and intermediaries, we provide our clients with a direct and secure Bitcoin-backed lending platform, ensuring a reliable and hassle-free experience. Simplicity and security are at the core of Bitloc's mission, empowering our users to navigate the lending landscape with clarity and confidence.
                </p>
              </div>
            </div>
          </div>
          <h1 className="btm-title my-4">
          Bitloc takes risk management seriously, employing trusted technology and keeping up to date with a constantly evolving environment to help identify and mitigate potential risks. We continually enhance our protocols, adhering to strict community standards, to ensure the utmost security for our clients' financial well-being.
          </h1>
        </div>
      </div>
    </Wrapper>
  );
};

export default RiskManagement;
