import React, { useState } from "react";
import Select from "react-select";
import Wrapper from "../../routes/Wrapper";
import LoanCalculator from "../../components/LoanCalculator";
import { Link } from "react-router-dom";

const BitcoinBackedLoans = () => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#72AA8F" : isSelected ? "#72AA8F" : undefined,
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      marginTop: 8,
      fontSize: "14px",
      fontWeight: 400,
      background: "#FAA334",
    }),
    menuList: (base) => ({
      ...base,
      fontSize: "14px",
      fontWeight: 400,
    }),
  };
  const [receiveIn, setReceiveIn] = useState();
  const dropDownList = [
    { value: "cda", label: "CDA" },
    { value: "btc", label: "BTC" },
    { value: "abc", label: "ABC" },
    { value: "xyz", label: "XYZ" },
  ];
  const handleReceiveIn = (e) => {
    setReceiveIn(e.value);
  };
  return (
    <Wrapper>
      <div className="bitcoin-backed-loans-page bg-white h-screen flex flex-col">
        <div className="page-hero-sec flex ">
          <div className="wrap wrapWidth flex flex-col">
            <h1 className="sec-title">Bitcoin Backed Loans</h1>
            <p className="sec-desc">
              Unlock Dollars Quickly with Bitcoin-Backed Loans
              <br />
              <br />
              Looking for a hassle-free way to access dollars without parting
              ways with your BTC? Our Bitcoin-backed loans provide a fast and
              straightforward solution. With BitLoc, you can tap into the value
              of your BTC holdings while retaining ownership, giving you the
              financial flexibility you need without selling your precious
              Bitcoin.
            </p>
            <div className="actions flex items-center">
              <button className="btn-loan button">
                <Link to={"/auth/register"}> Get A Loan</Link>{" "}
              </button>
              <button className="btn-loan button">
                <a href={"#calculator"}>Try Loan Calculator</a>
              </button>
            </div>
          </div>
        </div>
        <div className="our-rates-section flex w-full">
          <div className="wrap wrapWidth flex flex-col">
            <h1 className="sec-title">Our Rates</h1>
            <div className="rate-block flex">
              <div className="rate-item flex flex-col">
                <h1 className="i-val">50% LTV</h1>
                <h1 className="i-desc">
                  BitLoc is offering a 50% loan-to-value ratio to eligible borrowers.
                </h1>
              </div>
              <div className="rate-item flex flex-col">
                <h1 className="i-val">12.5% Interest Rate</h1>
                <h1 className="i-desc">
                  Offering a 12.5% interest rate on all loans to eligible borrowers.
                </h1>
              </div>
              <div className="rate-item flex flex-col">
                <h1 className="i-val">Starting at $1,000</h1>
                <h1 className="i-desc">
                  Offering loans starting from as little as $1,000.
                </h1>
              </div>
            </div>
            <h1 className="sec-title my-4">Experience the BitLoc Advantage</h1>

            <div className="points-block flex flex-col">
              <div className="point-item flex items-center justify-between">
                <div className="left-side flex items-center">
                  <h1 className="title">Effortless Access to Funds</h1>
                </div>
                <div className="right-side flex items-center justify-end">
                  <p className="desc">
                    At BitLoc, accessing funds is a breeze. With a minimum BTC
                    collateral equivalent of $1,000 CAD, you can easily unlock
                    the funds you need. Say goodbye to lengthy credit checks as
                    BitLoc Loans offer automatic approval, making the process
                    quick and straightforward.
                  </p>
                </div>
              </div>
              <div className="point-item flex items-center justify-between">
                <div className="left-side flex items-center">
                  <h1 className="title">Tax Efficiency at Its Best</h1>
                </div>
                <div className="right-side flex items-center justify-end">
                  <p className="desc">
                  Unlike many standard bitcoin to dollar exchanges, taking out a loan with Bitloc may not be considered a taxable event in certain cases. Additionally, it is possible that the interest expenses incurred on the loan could be eligible for tax deductions, potentially providing you with a tax-efficient solution to meet your financial needs. However, individual tax situations can vary, and we strongly recommend consulting a qualified tax professional to understand the potential tax implications related to taking a loan with Bitloc based on your specific circumstances.
                  </p>
                </div>
              </div>
              <div className="point-item flex items-center justify-between">
                <div className="left-side flex items-center">
                  <h1 className="title">Swift & Flexible Solutions</h1>
                </div>
                <div className="right-side flex items-center justify-end">
                  <p className="desc">
                    Time is of the essence, and BitLoc understands that. Our
                    loans are typically funded within 24 hours of approval,
                    ensuring you get access to funds when you need them most.
                    What's more, we offer flexibility in repayment with no
                    penalties for early payoff and no mandatory monthly
                    payments, giving you control over your loan.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loan-calculator-section flex" id={"calculator"}>
          <div className="wrap wrapWidth flex flex-col">
            <h1 className="sec-title">Loan Calculator</h1>
            <p className="sec-desc">
            BitLoc's Bitcoin-backed loans are denominated in Canadian dollars, providing you with a convenient way to access funds. Use our Dollar Loan Calculator to explore the possibilities and find the loan amount that works best for you.

            </p>
            <LoanCalculator />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default BitcoinBackedLoans;
