import React, { useEffect, useRef } from "react";
// import { useEffect } from 'react';
import ApexChart from "../../components/apexchart/mychart";
import { useState } from "react";
import axios from "axios";
import Wrapper from "../../routes/Wrapper";

let tvScriptLoadingPromise;
function MyPage() {
  const onLoadScriptRef = useRef();

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current()
    );

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (
        document.getElementById("tradingview_7ac3f") &&
        "TradingView" in window
      ) {
        new window.TradingView.widget({
          autosize: true,
          symbol: "BITSTAMP:BTCUSD",
          interval: "1",
          timezone: "Etc/UTC",
          theme: "dark",
          style: "1",
          locale: "en",
          enable_publishing: true,
          withdateranges: true,
          hide_side_toolbar: false,
          allow_symbol_change: true,
          watchlist: ["BITSTAMP:BTCUSD"],
          details: true,
          hotlist: true,
          calendar: true,
          show_popup_button: true,
          popup_width: "1000",
          popup_height: "650",
          container_id: "tradingview_7ac3f",
        });
      }
    }
  }, []);
  useEffect(() => {
    // This code will run after the component mounts
    var divElement = document.getElementById("viz1712690946270");
    var vizElement = divElement.getElementsByTagName("object")[0];
    if (divElement.offsetWidth > 800) {
      vizElement.style.width = "100%";
      vizElement.style.height = divElement.offsetWidth * 0.75 + "px";
    } else if (divElement.offsetWidth > 500) {
      vizElement.style.width = "100%";
      vizElement.style.height = divElement.offsetWidth * 0.75 + "px";
    } else {
      vizElement.style.width = "100%";
      vizElement.style.height = divElement.offsetWidth * 1.77 + "px";
    }
    var scriptElement = document.createElement("script");
    scriptElement.src = "https://public.tableau.com/javascripts/api/viz_v1.js";
    vizElement.parentNode.insertBefore(scriptElement, vizElement);
  }, []);

  return (
    <Wrapper>
      <div className="flex flex-col h-screen bg-white risk-management-page">
        <div className="flex flex-col wrap wrapWidth">
          <h1 className="mb-4 sec-title">Bitcoin Charts</h1>
          <div style={{ width: "97%" }} className="my-3 mt-3">
            <div
              className="tradingview-widget-container"
              style={{ height: "800px", width: "100%" }}
            >
              <div
                id="tradingview_7ac3f"
                style={{ height: "calc(100% - 32px)", width: "100%" }}
              />
              <div className="tradingview-widget-copyright">
                <a
                  href="https://www.tradingview.com/"
                  rel="noopener nofollow"
                  target="_blank"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-12 max-w-[1200px] mx-auto">
        <div
          className="tableauPlaceholder"
          id="viz1712690946270"
          style={{ position: "relative", margin: "auto" }}
        >
          <noscript>
            <a href="#">
              <img
                alt="Main Dashboard"
                src="https://public.tableau.com/static/images/Bi/BitlocBTCHalvingChart/MainDashboard/1_rss.png"
                style={{ border: "none" }}
              />
            </a>
          </noscript>
          <object className="tableauViz" style={{ display: "none" }}>
            <param
              name="host_url"
              value="https%3A%2F%2Fpublic.tableau.com%2F"
            />
            <param name="embed_code_version" value="3" />
            <param name="site_root" value="" />
            <param name="name" value="BitlocBTCHalvingChart/MainDashboard" />
            <param name="tabs" value="no" />
            <param name="toolbar" value="yes" />
            <param
              name="static_image"
              value="https://public.tableau.com/static/images/Bi/BitlocBTCHalvingChart/MainDashboard/1.png"
            />
            <param name="animate_transition" value="yes" />
            <param name="display_static_image" value="yes" />
            <param name="display_spinner" value="yes" />
            <param name="display_overlay" value="yes" />
            <param name="display_count" value="yes" />
            <param name="language" value="en-US" />
          </object>
        </div>
      </div>
    </Wrapper>
  );
}

export default MyPage;
