import React from "react";
import Wrapper from "../../routes/Wrapper";

const AboutUs = () => {
  return (
    <Wrapper>
      <div className="about-us-page bg-white h-screen flex flex-col">
        <div className="about-hero-sec flex items-center justify-center">
          <div className="wrap wrapWidth flex flex-col">
            <h1 className="page-title">About Us</h1>
            <p className="desc">
              BitLoc is a Canadian-based financial technology company that
              empowers users to leverage their assets to access immediate
              capital. Our secure bitcoin-backed lending services provide a way
              for users to tap into the value of their bitcoin holdings. Unlike
              traditional lending practices, we prioritize user fund integrity –
              and do not participate in rehypothecation. Transparency is one of
              our core values, and it’s at the heart of everything we do. Users
              can easily track their bitcoin on the blockchain using their
              issued public key, ensuring full visibility and accountability.
              <br />
              <br />
              BitLoc recognizes the unique opportunities and challenges of
              bitcoin, and is dedicated to fostering trust in the bitcoin space
              while adding utility and liquidity to the industry. By enabling
              users to unlock the potential of their bitcoin, our powerful
              monetary tool empowers them to progress in their lives while still
              investing in their future. With BitLoc, you can confidently access
              the capital you need, leverage your bitcoin assets, and move
              forward with your financial goals. We’re here to support you, and
              continue to provide innovative solutions that harness the
              potential of bitcoin.
            </p>
          </div>
        </div>
        <div className="page-block1 mission-statement flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="statement-sec flex justify-center items-center align-middle">
              <div className="statement-sec flex align-middle items-center">
                <div className="left flex flex-col py-8">
                  <h1 className="sec-tag">Mission Statement</h1>
                  <p className="sec-desc">
                    At BitLoc, our mission is to propel the widespread adoption
                    of Bitcoin in Canada by providing innovative financial
                    services that enhance the utility and accessibility of this
                    transformative digital currency. We believe that Bitcoin’s
                    potential goes beyond its price, and we are committed to
                    making it a practical and useful financial tool for all
                    individuals.
                    <br />
                    <br />
                    Through open-source technology and stringent security
                    measures, we ensure a seamless and trustworthy borrowing
                    experience. By providing competitive interest rates,
                    flexible loan terms, and exceptional customer service, we
                    strive to foster trust and build lasting relationships with
                    our clients.
                    <br />
                    <br />
                    As we pave the way for greater financial inclusion, we aim
                    to break down barriers and democratize access to capital. We
                    want to give every individual, regardless of their
                    background or financial status, the opportunity to
                    participate in the new era of finance powered by Bitcoin.
                    <br />
                    <br />
                    By offering more services and exploring innovative ways to
                    utilize Bitcoin’s potential, we aspire to revolutionize the
                    financial landscape in Canada. Our commitment to
                    transparency, compliance, and regulatory best practices
                    strengthens the foundation of our mission, ensuring that our
                    customers' interests remain at the core of everything we do.
                    Together, we embark on a journey to transform Bitcoin from a
                    speculative asset into a viable and practical financial
                    instrument that empowers individuals, fosters economic
                    growth, and contributes to a more equitable and prosperous
                    future for all Canadians.
                  </p>
                </div>
                <div className="right flex items-center justify-end">
                  <img src="/images/about4.jpg" className="bitcoin-img" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-[#f9c46e] py-6">
          <h1 className="wrapWidth sec-tag text-center">
            Building a Future of Secure Innovation, with Transparency and
            Financial Freedom.
          </h1>
        </div>
        <div className="page-block1 gride_section flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="numbs-item page-block2 flex w-full">
              <div className="grid-sec flex flex-col">
                <div className="grid-block">
                  <div className="grid-item flex flex-col justify-start items-center">
                    <h2 className="aboutHeader py-8 text-center text-white text-xl">
                      {" "}
                      Bitcoin
                      <br />
                      Only:
                    </h2>
                    <div className="img">
                      {" "}
                      <img src="/images/icon1.png" className="bitcoin-img" />
                    </div>
                    <p className="grid-desc">
                      We're proud to be a Bitcoin-only platform, and this is a
                      significant advantage for you. Our exclusive focus on
                      Bitcoin means specialized expertise, greater security, and
                      tailored services, ensuring your experience with Bitloc is
                      secure and seamless.
                    </p>
                  </div>
                  <div className="grid-item flex flex-col justify-start items-center">
                    <h2 className="aboutHeader py-8 text-center text-white text-xl">
                      {" "}
                      No
                      <br />
                      Rehypothecation:
                    </h2>
                    <div className="img">
                      <img src="/images/icon2.png" className="bitcoin-img" />
                    </div>
                    <p className="grid-desc">
                      Bitloc does not engage in REHYPOTHECATION. This means that
                      we do not use our customers' assets as collateral for
                      other financial transactions or lending activities. Your
                      assets remain secure and are not utilized for purposes
                      beyond the agreed terms of our services, ensuring the
                      protection of your investment.
                    </p>
                  </div>
                  <div className="grid-item flex flex-col justify-start items-center">
                    <h2 className="aboutHeader py-8 text-center text-white text-xl">
                      {" "}
                      Simple &
                      <br />
                      Easy:
                    </h2>
                    <div className="img">
                      <img src="/images/icon3.png" className="bitcoin-img" />
                    </div>
                    <p className="grid-desc">
                      Bitloc values simplicity and customer freedom. We
                      communicate in straightforward terms, ensuring that our
                      customers can easily understand our services and their
                      terms. Moreover, we offer a unique benefit – the freedom
                      for our customers to cancel their loans at any time
                      without incurring any penalties. We believe in empowering
                      our clients with flexibility and transparency, making
                      their experience with Bitloc as seamless and stress-free
                      as possible.
                    </p>
                  </div>
                  <div className="grid-item flex flex-col justify-start items-center">
                    <h2 className="aboutHeader py-8 text-center text-white text-xl">
                      {" "}
                      Fair &
                      <br />
                      Transparent:
                    </h2>
                    <div className="img">
                      <img src="/images/icon4.png" className="bitcoin-img" />
                    </div>
                    <p className="grid-desc">
                      At Bitloc, we're committed to transparent and fair
                      practices. Unlike some competitors who use inaccurate
                      market pricing for a hidden fee, we prioritize precision
                      in our pricing. Our customers can trust they're getting
                      accurate, up-to-date market pricing for their Bitcoin
                      assets. Honesty and integrity are paramount in crypto, and
                      our dedication to precise pricing underscores our
                      trustworthy lending service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default AboutUs;
