import React, {useEffect, useState} from 'react';
import {apiUrl, customAlert} from "../../helpers/MyHelpers";
import makeRequest from "../../helpers/axiosRequest";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../../store/reducers/authReducer";

export const WalletForm = ({formData, setFlag, setUserData}) => {
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.authReducer)
    const [isChecked, setChecked] = useState(false);

    const [data, setData] = useState({
        group_name: '',
        wallets: [
            {
                address: "",
                percentage: "",
            }
        ]
    });
    useEffect(() => {
        if (formData?.wallets !== undefined) {
            setData({...formData});
        } else {
            setData({
                group_name: '',
                wallets: [
                    {
                        address: "",
                        percentage: "",
                    }
                ]
            });
        }
    }, [formData])
    const handleInputs = (e, i) => {
        let oldData = {...data}
        if (e.target.name === 'group_name') {
            oldData.group_name = e.target.value;
        } else if (e.target.name === 'address[]') {
            oldData.wallets[i].address = e.target.value;
        } else {
            oldData.wallets[i].percentage = e.target.value;
        }
        setData(oldData);
    }
    const walletAddressSubmit = async (e) => {
        e.preventDefault()
        if (!isChecked){
            customAlert({
                "icon": "error",
                "title": "Error",
                "text": "Confirm ownership of Wallet address by click on checkbox"
            })
            return
        }

        let form = e.target;
        let percentage = form.getElementsByTagName('input');
        let total_percentage = 0;
        for (let i = 0; i < percentage.length; i++) {
            if (percentage[i].name === 'percentage[]') {
                total_percentage += parseInt(percentage[i].value);
            }
        }
        if (total_percentage !== 100) {
            customAlert({
                "icon": "error",
                "title": "Percentage Error",
                "text": "Total Percentage Should be 100%"
            })
            return
        }
        try {
            const form_data = new FormData(e.target);
            form_data.append('first_name', user?.first_name)
            form_data.append('email', user?.email)
            const response = await makeRequest('post', `${apiUrl}users/save_wallet/${user?._id}`, form_data)
                .then(response => {
                    if (response?.data?.status == 'Success') {
                        setUserData(response?.data?.user)
                        customAlert({
                            "icon": "success",
                            "title": "Success",
                            "text": "Wallet Info Has Been Updated"
                        })
                        dispatch(setUser(response?.data?.user));
                        e.target.reset()
                        setData({
                            group_name: '',
                            wallets: [
                                {
                                    address: "",
                                    percentage: "",
                                }
                            ]
                        })
                        setFlag(true)
                        setChecked(!isChecked); // Toggle the state between true and false
                    } else if (response?.error) {
                        customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                    } else {
                        customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                    }
                })
        } catch (error) {
            customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
        }
    }
    const deleteWallet = async () => {
        try {
            const formData = new FormData();
            formData.append('_id', user?._id)
            const response = await makeRequest('post', `${apiUrl}users/delete_wallet/${data._id}`, formData)
                .then(response => {
                    if (response?.data?.status == 'Success') {
                        setUserData(response?.data?.user)
                        customAlert({
                            "icon": "success",
                            "title": "Success",
                            "text": "Wallet Has Been Deleted"
                        })
                        setData({
                            group_name: '',
                            wallets: [
                                {
                                    address: "",
                                    percentage: "",
                                }
                            ]
                        })
                        dispatch(setUser(response?.data?.user));
                    } else if (response?.error) {
                        customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                    } else {
                        customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                    }
                })
        } catch (error) {
            console.error(error);
        }
    }
    const removeRow = (me, i) => {
        let oldData = {...data};
        oldData.wallets.splice(i, 1);
        setData(oldData)
    }
    const handlePlus = (e) => {
        let oldData = {...data};
        oldData.wallets.push({
            address: "",
            percentage: "",
        })
        setData(oldData)
    }
    const handleCheckboxChange = () => {
        setChecked(!isChecked); // Toggle the state between true and false
    };

    const loadWalletsForm = () => {
        return (
            <form onSubmit={walletAddressSubmit}>
                {
                    data?._id !== undefined ? <input name="id" type="hidden" value={data._id}/> : null
                }
                <input type="text" required={true} placeholder="Wallet Name"
                       className="mb-5 mr-3 border rounded w-full py-2 px-3 leading-tight"
                       value={data.group_name} name="group_name" onChange={(e) => {
                    handleInputs(e, 0)
                }}/>
                <div>
                    {
                        data.wallets.map((item, i) => {
                            return (
                                <div id="form_fields" key={i}>
                                    <div
                                        className="flex items-center justify-between mb-4 m_flex_direction wallets_inputs">
                                        <input required={true} placeholder="Wallet Address"
                                               className="mr-3 border rounded w-10/12 py-2 px-3 leading-tight"
                                               name="address[]" onChange={(e) => {
                                            handleInputs(e, i)
                                        }} value={item.address} type="text"/>
                                        <input type="number" onChange={(e) => {
                                            handleInputs(e, i)
                                        }} value={item.percentage} min="1" max="100" required={true}
                                               placeholder="Percentage"
                                               className="mr-3 border rounded w-2/12 py-2 px-3 leading-tight"
                                               name="percentage[]"/>
                                        <button className="button btn-rounded m_search_btn" onClick={(e) => {
                                            if (i === 0) {
                                                handlePlus(e)
                                            } else {
                                                removeRow(e, i)
                                            }
                                        }} type="button">{i == 0 ? "+" : "-"}</button>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
                <div className="terms-field  flex items-center justify-start anim cursor-pointer">
                    <input checked={isChecked} onChange={handleCheckboxChange} type="checkbox" className="h-5 w-8 mr-1"/>
                        <span className="text-sm mr-2">Check this box to confirm you are the owner of this address</span>
                </div>
                <div className={'flex justify-between pt-10'}>
                    <button className="button btn-rounded" type="submit">Save</button>
                    {data?._id !== undefined ?
                        <button onClick={() => deleteWallet()} className="button btn-rounded" type={'button'}>
                            Delete
                        </button> : ''}
                </div>
            </form>
    )
    }

    return (
        <div>{loadWalletsForm()}</div>
    );
    };
