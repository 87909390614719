import React, { useEffect } from 'react';

const TradingViewTickerTape = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
        //script.async = true;
        script.innerHTML = JSON.stringify({
            symbols: [
                { description: 'Bitcoin USD', proName: 'COINBASE:BTCUSD' },
                { description: 'Bitcoin CAD', proName: 'KRAKEN:BTCCAD' }
            ],
            showSymbolLogo: true,
            colorTheme: 'light',
            isTransparent: false,
            displayMode: 'adaptive',
            locale: 'en'
        });
        const widgetContainer = document.getElementById('tradingview-widget-container__widget');

        document.getElementById('tradingview-widget-container__widget').appendChild(script);

        if (widgetContainer?.firstChild) {
            widgetContainer?.removeChild(widgetContainer.firstChild);
        }
        widgetContainer?.appendChild(script);

        setTimeout(()=>{
        },1000)
        // Clean up the script on component unmount
        return () => {
            if (widgetContainer?.firstChild) {
                widgetContainer?.removeChild(widgetContainer.firstChild);
            }
        };
    }, []);

    return (
        <div className="tradingview-widget-container">
            <div id="tradingview-widget-container__widget" className="tradingview-widget-container__widget"></div>
        </div>
    );
};

export default TradingViewTickerTape;
