import React, {useEffect, useState} from "react";
import AdminWrapper from "../../../routes/AdminWrapper";
import {apiUrl, customAlert} from "../../../helpers/MyHelpers";
import {useSelector} from "react-redux";
import Swal from "sweetalert2";
import makeRequest from "../../../helpers/axiosRequest";


const PendingLoan = () => {
    const {user} = useSelector(state => state.authReducer)
    let authToken = localStorage.getItem("_token");
    const [myPendingLoans, setPendingMyLoans] = useState([]);
    const [loader, setLoader] = useState(true);
    const getMyLoans = async () => {
        return await fetch(apiUrl + `users/get_my_pending_loans/${user?._id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken
            }
        }).then(response => {
            return response.json()
        }).then(data => {
            setPendingMyLoans(data?.loans)
            setLoader(false)
        })
    }
    useEffect(() => {
        getMyLoans()
    }, [])

    const handleCancelSubmit = async (_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Want to Cancel this Loan!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Cancel it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    const formData = new FormData();
                    formData.append('id', _id);
                    formData.append('pay_type', "Cancel Loan From Pending")
                    const response = await makeRequest('post', `${apiUrl}users/cancel_loan`, formData)
                        .then(response => {
                            if (response?.data?.status === 'Success') {
                                customAlert({
                                    "icon": "success",
                                    "text": "Cancelled Successfully"
                                })
                                window.location.reload();
                            } else if (response?.error) {
                                customAlert({"icon": "error", "title": "Error", "text": response?.error?.data})
                            } else {
                                customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                            }
                        })
                } catch (err) {
                    customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                }
            }
        })

    }
    return (
        <AdminWrapper>
            <div className="dashboard-page flex bg-white">
                <div className="page-block flex flex-col w-full">
                    {myPendingLoans?.map((item, key) => {
                        return (
                            <div key={key}>
                                <div className="page-title text-black pt-8">Loan {key + 1}</div>
                                <div className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-5">
                                    <div className={'flex'}>
                                        <div className={'w-full'}>
                                            <table className="w-full text-left">
                                                <tbody>
                                                <tr className="bg-white border-b dark:border-gray-700">
                                                    <td className="px-6 py-5">Borrow Amount:</td>
                                                    <td className="px-6 py-5 text-end">${Number(item?.borrow_amount).toLocaleString()}</td>
                                                </tr>
                                                <tr className="bg-white border-b  dark:border-gray-700">
                                                    <td className="px-6 py-5">Term Length:</td>
                                                    <td className="px-6 py-5 text-end">{item?.term_length} Months</td>
                                                </tr>
                                                <tr className="bg-white border-b  dark:border-gray-700">
                                                    <td className="px-6 py-5">Loan Type:</td>
                                                    <td className="px-6 py-5 text-end">{item?.loan_type}</td>
                                                </tr>
                                                <tr className="bg-white border-b  dark:border-gray-700">
                                                    <td className="px-6 py-5">Category:</td>
                                                    <td className="px-6 py-5 text-end">{item?.category}</td>
                                                </tr>
                                                <tr className="bg-white border-b  dark:border-gray-700">
                                                    <td className="px-6 py-5">Submitted On:</td>
                                                    <td className="px-6 py-5 text-end">{item?.createdAt.toString().split("T")[0]}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <button onClick={() => handleCancelSubmit(item?._id)}
                                                    className="button btn-rounded mb-5 mt-7" type="submit">
                                                Cancel Application
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {loader ? <img style={{height: "100px"}} className={'m-auto'} src={'/images/loading_gif.gif'}
                                   alt=""/> : ''}
                    {loader === false && myPendingLoans?.length < 1 ?
                        <div className={'noDataFound'}>
                            <h1>No Pending Loans Founds</h1>
                        </div> : ""}
                </div>
            </div>
        </AdminWrapper>
    );
};

export default PendingLoan;
