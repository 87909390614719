import React, {useEffect, useRef, useState} from "react";
import AdminWrapper from "../../../routes/SuperAdminWrapper";
import makeRequest from "../../../helpers/axiosRequest";
import {apiUrl, customAlert, imagesUrl, passRegExp} from "../../../helpers/MyHelpers";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../../../store/reducers/authReducer";
import {canStates, usaStates} from "../../../datasets/States";
import Modal from 'react-modal';

const AdminChangePassword = name => {
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.authReducer)
    const [state, setState] = useState({});


    const changePasswordFormSubmit = async (e) => {
        e.preventDefault()
        try {
            if (state?.new_password !== state?.canfirm_new_password) {
                customAlert({
                    "icon": "error",
                    "title": "Password Confirmation Error",
                    "text": "The passwords you entered do not match. Please make sure the new password and confirmation password are identical."
                })
                return
            }
            const response = await makeRequest('post', `${apiUrl}users/change_password/${user?._id}`, state)
                .then(response => {
                    if (response?.data?.status === 'Success') {
                        customAlert({
                            "icon": "success", "title": "Success", "text": "Password has been changed"
                        })
                    } else {
                        customAlert({"icon": "error", "title": "Error", "text": "Invalid Password"})
                    }
                }).catch(error => {
                    customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
                })
        } catch (error) {
            customAlert({"icon": "error", "title": "Unexpected Error", "text": "Try again later"})
        }
    }
    const inputRef = useRef(null);

    const handleInputs = (e) => {
        // Set State of Data on Input Fields
        setState({...state, [e.target.name]: e.target.value});
    };
    return (<AdminWrapper>
        <div className="dashboard-page flex bg-white">
            <div className="page-block flex flex-col w-full">
                <div className={'flex justify-between py-2 align-middle'}>
                    <div className="page-title text-black">Change Password</div>
                </div>
                <div className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-10">
                    <form className="" id={'personalInfoForm'} onSubmit={changePasswordFormSubmit}>
                        <div className="mb-4">
                            <label className="block font-normal mb-2" htmlFor="first_name">
                                Current Password *
                            </label>
                            <input onChange={handleInputs} required={true}
                                   className="border rounded w-full py-2 px-3 leading-tight"
                                   placeholder="Enter current password..."
                                   value={state?.old_password} name="old_password" type="password"/>
                        </div>
                        <div className="mb-4">
                            <label className="block font-normal mb-2" htmlFor="last_name">
                                New Password *
                            </label>
                            <input
                                pattern={passRegExp}
                                onChange={handleInputs} required={true}
                                className="border rounded w-full py-2 px-3 leading-tight"
                                value={state?.new_password} name="new_password"
                                placeholder="Enter new password..."
                                title="Password must contain 1 uppercase, 1 lower case, special character and minimum length should be 8 character long"
                                type="password"/>
                        </div>
                        <div className="mb-4">
                            <label className="block font-normal mb-2" htmlFor="last_name">
                                Confirm New Password *
                            </label>
                            <input
                                pattern={passRegExp}
                                onChange={handleInputs} required={true}
                                className="border rounded w-full py-2 px-3 leading-tight"
                                value={state?.canfirm_new_password} name="canfirm_new_password"
                                placeholder="Enter confirm password..."
                                title="Password must contain 1 uppercase, 1 lower case, special character and minimum length should be 8 character long"
                                type="password"/>
                        </div>
                        <div className="flex items-center justify-between">
                            <button className="button btn-rounded" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </AdminWrapper>);
};

export default AdminChangePassword;
