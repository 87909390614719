import React from "react";
import AdminWrapper from "../../../routes/SuperAdminWrapper";
import {LoansDataTable} from "../../../components/Datatable/LoanDatatable";

const LoansAdmin = () => {
    return (
        <AdminWrapper>
            <div className="dashboard-page flex bg-white">
                <div className="page-block flex flex-col w-full">
                    <div className="page-title text-black">Loans</div>
                    <LoansDataTable/>
                </div>
            </div>
        </AdminWrapper>
    );
};

export default LoansAdmin;
